import {Card, Col, Row} from 'antd';
import {useMemo, useState, FC, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {bulker, container} from '../../../components/consts/vesselTypes';
import {LoadingModal} from '../../../components/LoadingModal/LoadingModal';
import {MapCardMap} from '../MapCard/MapCardMap';
import {Grid} from './Grid/Grid';
import {FinishedVisitsPerDate, VisitTimePerDate} from './Statistics/BarStats';
import {CurrentVisits} from './Statistics/CurrentVisits';
import {MedianServiceTime} from './Statistics/MedianServiceTime';
import {MedianWaitingTime} from './Statistics/MedianWaitingTime';
import {FinishedVisitsFromBulkersPerVesselSize, FinishedVisitsPerVesselType} from './Statistics/PieStats';
import {ServedVisitsByWeekDay} from './Statistics/ServedVisitsByWeekDay';
import {TiedUpDWT} from './Statistics/TiedUpDWT';
import {TiedUpTeu} from './Statistics/TiedUpTeu';
import {portMetadata} from './utils/portMetadata';
import {PortKey} from './utils/usePortCongestionQuery';
import {
  MergedPortCongestionVisit,
  useCongestionVisitsWithVesselInformationsQuery,
} from './utils/useCongestionVisitsWithVesselInformationsQuery';
import {makePartialMapSwitches} from '../../../components/SeaboMap/utils/makePartialMapSwitches';
import {MapControlButton} from '../../../components/SeaboMap/MapContext/Types';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';

type RouteParams = 'portKey';

export const PortCongestionScreen: FC = () => {
  const params = useParams<RouteParams>();
  const portKey = params.portKey as PortKey;
  const [selectedVisits, setSelectedVisits] = useState<MergedPortCongestionVisit[] | undefined>(undefined);
  const [appliedFilter, setAppliedFilter] = useState('');

  const congestionQuery = useCongestionVisitsWithVesselInformationsQuery(portKey);

  const {title, centerPoint, zoom} = portMetadata[portKey];
  const {queryTimestamp, currentVisits, finishedVisits} = congestionQuery.data ?? {};

  const applyFilter = (type: string, value: string) => {
    setAppliedFilter(`${type}: ${value}`);
  };
  const resetFilter = () => {
    setSelectedVisits(finishedVisits);
    setAppliedFilter('');
  };

  const finishedContainer = useMemo(
    () => finishedVisits?.filter(visit => visit.vesselType === 'CONTAINER') ?? [],
    [finishedVisits]
  );
  const finishedBulker = useMemo(
    () => finishedVisits?.filter(visit => visit.vesselType === 'BULKER') ?? [],
    [finishedVisits]
  );
  const currentContainer = useMemo(
    () => currentVisits?.filter(visit => visit.vesselType === 'CONTAINER') ?? [],
    [currentVisits]
  );

  const currentBulker = useMemo(
    () => currentVisits?.filter(visit => visit.vesselType === 'BULKER') ?? [],
    [currentVisits]
  );

  useEffect(() => {
    if (congestionQuery.isSuccess && congestionQuery.data) {
      setSelectedVisits(congestionQuery.data.currentVisits);
    }
  }, [congestionQuery.data, congestionQuery.isSuccess]);

  if (!congestionQuery.isSuccess) {
    return <LoadingModal isLoading={true} />;
  }

  return (
    <div className={'congestion-reports'}>
      <ScreenHeader
        title={title + ' congestion last 60 days'}
        breadcrumbs={[{title: 'Lab'}, {title: 'Congestion Reports', href: '/lab/congestion'}, {title}]}
        features={
          appliedFilter && (
            <>
              <AppliedFilterLabel>Filtered by {appliedFilter}</AppliedFilterLabel>
              <ScreenHeader.Features.Button onClick={resetFilter} style={{marginLeft: 10}}>
                Reset filter
              </ScreenHeader.Features.Button>
            </>
          )
        }
      />
      <Card>
        <Row gutter={[20, 20]}>
          <Col span={24} xxl={12} style={{minHeight: 376}}>
            <ReportMap
              staticMapBoxLayer={false}
              vessels={currentVisits!}
              centerPoint={centerPoint}
              zoom={zoom}
              mapControlsVisible
              promoLayerEnabled
              mapControlButtonsToHide={[MapControlButton.CARGOES, MapControlButton.VESSELS]}
              initialMapSettings={{
                switches: {
                  ...makePartialMapSwitches({
                    ports: true,
                    highRiskAreas: true,
                    emissionControlAreas: true,
                  }),
                },
              }}
            />
          </Col>
          <Col span={12}>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <CurrentVisits dataTestId={'currentBulker'} title={'Current bulkers'} currentVisits={currentBulker} />
              </Col>
              <Col span={12}>
                <CurrentVisits
                  dataTestId={'currentContainer'}
                  title={'Current container vessels'}
                  currentVisits={currentContainer}
                />
              </Col>
              <Col span={12}>
                <TiedUpDWT currentBulkerVisits={currentBulker} />
              </Col>
              <Col span={12}>
                <TiedUpTeu currentContainerVisits={currentContainer} />
              </Col>
              <Col span={12}>
                <MedianWaitingTime
                  dataTestId={'bulkerMedianWaitingTime'}
                  visits={finishedBulker!}
                  title={'Median waiting time (Bulker)'}
                />
              </Col>
              <Col span={12}>
                <MedianWaitingTime
                  dataTestId={'containerMedianWaitingTime'}
                  visits={finishedContainer!}
                  title={'Median waiting time (Container)'}
                />
              </Col>
              <Col span={12}>
                <MedianServiceTime
                  dataTestId={'bulkerMedianServiceTime'}
                  visits={finishedBulker!}
                  title={'Median service time (Bulker)'}
                />
              </Col>
              <Col span={12}>
                <MedianServiceTime
                  dataTestId={'containerMedianServiceTime'}
                  visits={finishedContainer!}
                  title={'Median service Time (Container)'}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <FinishedVisitsPerDate
              barColor={bulker.fillColor}
              title={'Bulkers by service date'}
              visits={finishedBulker}
              maxDate={queryTimestamp!}
              onSetVisits={setSelectedVisits}
              onSetAppliedFilter={applyFilter}
            />
          </Col>
          <Col span={12}>
            <FinishedVisitsPerDate
              barColor={container.fillColor}
              title={'Container vessels by service date'}
              visits={finishedContainer}
              maxDate={queryTimestamp!}
              onSetVisits={setSelectedVisits}
              onSetAppliedFilter={applyFilter}
            />
          </Col>
          <Col span={12}>
            <VisitTimePerDate
              barColor={bulker.fillColor}
              title={'Waiting time of Bulkers by service date'}
              visits={finishedBulker}
              maxDate={queryTimestamp!}
              onSetVisits={setSelectedVisits}
              onSetAppliedFilter={applyFilter}
            />
          </Col>
          <Col span={12}>
            <VisitTimePerDate
              barColor={container.fillColor}
              title={'Waiting time of container vessels by service date'}
              visits={finishedContainer}
              maxDate={queryTimestamp!}
              onSetVisits={setSelectedVisits}
              onSetAppliedFilter={applyFilter}
            />
          </Col>
          <Col span={12}>
            <FinishedVisitsPerVesselType
              visits={finishedVisits!}
              onSetVisits={setSelectedVisits}
              onSetAppliedFilter={applyFilter}
            />
          </Col>
          <Col span={12}>
            <FinishedVisitsFromBulkersPerVesselSize
              visits={finishedVisits!}
              onSetVisits={setSelectedVisits}
              onSetAppliedFilter={applyFilter}
            />
          </Col>
          <Col span={12}>
            <ServedVisitsByWeekDay
              title={'Served bulkers by weekday'}
              visits={finishedBulker}
              color={bulker.fillColor}
            />
          </Col>
          <Col span={12}>
            <ServedVisitsByWeekDay
              title={'Served container vessels by weekday'}
              visits={finishedContainer}
              color={container.fillColor}
            />
          </Col>
        </Row>
        <Grid vessels={selectedVisits ?? []} />
      </Card>
    </div>
  );
};

const AppliedFilterLabel = styled.div`
  line-height: 1;
  font-weight: bold;
`;

const ReportMap = styled(MapCardMap)`
  border-radius: var(--border-radius-card) !important;
`;
