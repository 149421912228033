import {FC, ReactNode} from 'react';
import styled from 'styled-components';
import {Portal} from '../Portal/Portal';

/**
 * Renders an action bar.
 */
export const ActionBar: FC<{children: ReactNode}> = ({children}) => {
  return (
    <Portal targetNodeId="action-bar-portal">
      <ActionBarStyled className="react-app-outer" data-cy="ActionBar">
        {children}
      </ActionBarStyled>
    </Portal>
  );
};

const ActionBarStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export default ActionBar;
