import {FC, ReactNode} from 'react';
import {Helmet} from 'react-helmet-async';
import styled from 'styled-components';
import {Icon, IconType} from '../../atoms/Icon';

interface ScreenTitleProps {
  icon?: IconType;
  title: string;
  badge?: string;
  addon?: ReactNode;
}

export const ScreenTitle: FC<ScreenTitleProps> = ({icon, title, badge, addon}) => {
  return (
    <>
      <Helmet title={title} />
      <ScreenTitleWrapper>
        {icon && <ScreenTitleIcon type={icon} />}
        <ScreenTitleHeadline>
          {title}
          {!!badge && <TitleBadge>{badge}</TitleBadge>}
          {addon}
        </ScreenTitleHeadline>
      </ScreenTitleWrapper>
    </>
  );
};

export const ScreenTitleWrapper: FC<{
  children: React.ReactNode;
}> = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
`;

export const ScreenTitleIcon = styled(Icon)`
  display: inline-block;
  width: var(--font-size-2xl);
  text-align: center;
  color: var(--color-black) !important;
  font-size: var(--font-size-2xl) !important;
`;

export const ScreenTitleHeadline: FC<{children: React.ReactNode}> = styled.h1`
  font-size: var(--font-size-2xl);
  font-weight: bold;
  line-height: 1;
  color: var(--color-black);
  margin-top: auto;
  margin-bottom: auto;
`;

export const TitleBadge = styled.div`
  position: relative;
  top: -10px;
  display: inline-block;
  margin-left: 5px;
  padding: 2px 3px;
  background: var(--color-blue);
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  border-radius: var(--border-radius);
`;
