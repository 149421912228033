import {FC} from 'react';
import {Asterisk, CtaButton, InfoIcon, InputLabel, Instruction} from '../utils/shared-components';
import Tooltip from '../../../atoms/Tooltip';
import {Form, FormInstance, Input} from 'antd';
import {LockOutlined, MailOutlined} from '@ant-design/icons';
import {useServerSideValidator} from '../../../utils/serverSideValidator';
import {CompanySignupRequest} from '../../../api/symfony/generated';

export const SignupFormStep0: FC<{
  visible: boolean;
  submitError?: Error | null;
  form: FormInstance<CompanySignupRequest>;
  formValuesAtSubmit: CompanySignupRequest | null;
  onContinue: () => void;
}> = ({visible, submitError, formValuesAtSubmit, form, onContinue}) => {
  const serverSidePasswordValidator = useServerSideValidator<CompanySignupRequest>(
    'newPassword',
    submitError,
    formValuesAtSubmit,
    form
  );
  const serverSideEmailValidator = useServerSideValidator<CompanySignupRequest>(
    'email',
    submitError,
    formValuesAtSubmit,
    form
  );

  const handleContinue = async () => {
    await form.validateFields(['email', 'emailRepeat', 'newPassword', 'passwordRepeat']);
    onContinue();
  };

  return (
    <div hidden={!visible}>
      <Instruction>
        Set up your account and try seabo for free <br />
        No credit card required
      </Instruction>
      <InputLabel>
        <span>
          Corporate email<Asterisk>*</Asterisk>
        </span>
        <Tooltip position="top" title="Your email is used to log you in." trigger="mouseenter">
          <InfoIcon>i</InfoIcon>
        </Tooltip>
      </InputLabel>
      <Form.Item
        name="email"
        data-testid="emailFormItem"
        rules={[
          {
            required: true,
            message: 'Please input your email.',
            type: 'email',
          },
          {
            validator: serverSideEmailValidator,
          },
        ]}>
        <Input prefix={<MailOutlined />} autoComplete="email" placeholder="Email" data-testid="email" />
      </Form.Item>
      <InputLabel>
        <span>
          Confirm email<Asterisk>*</Asterisk>
        </span>
        <Tooltip position="top" title="Repeat your email to assure it is spelled correctly." trigger="mouseenter">
          <InfoIcon>i</InfoIcon>
        </Tooltip>
      </InputLabel>
      <Form.Item
        name="emailRepeat"
        rules={[
          {
            required: true,
            message: 'Please input your email.',
          },
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || getFieldValue('email') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two emails must match');
            },
          }),
        ]}>
        <Input prefix={<MailOutlined />} autoComplete="email" placeholder="Email repeat" data-testid="emailRepeat" />
      </Form.Item>
      <InputLabel>
        <span>
          New password<Asterisk>*</Asterisk>
        </span>
        <Tooltip position="top" title="Use a secure password to protect your data." trigger="mouseenter">
          <InfoIcon>i</InfoIcon>
        </Tooltip>
      </InputLabel>
      <Form.Item
        name="newPassword"
        rules={[
          {
            required: true,
            message: 'Please input your Password.',
          },
          {
            min: 8,
            message: 'Password must be minimum 8 characters.',
          },
          {
            validator: (_, value) => {
              if (!value || /[a-z]/.test(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject('Must contain at least one lowercase letter');
              }
            },
          },
          {
            validator: (_, value) => {
              if (!value || /[A-Z]/.test(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject('Must contain at least one uppercase letter');
              }
            },
          },
          {
            validator: (_, value) => {
              if (!value || /[0-9]/.test(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject('Must contain at least one digit (0-9)');
              }
            },
          },
          {
            validator: (_, value) => {
              if (!value || /[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ]/.test(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject('Must contain at least one special character');
              }
            },
          },
          {
            validator: serverSidePasswordValidator,
          },
        ]}>
        <Input
          prefix={<LockOutlined />}
          autoComplete="new-password"
          type="password"
          placeholder="Password"
          data-testid="newPassword"
        />
      </Form.Item>
      <InputLabel>
        <span>
          Confirm new password<Asterisk>*</Asterisk>
        </span>
        <Tooltip position="top" title="Repeat your password to assure it is spelled correctly." trigger="mouseenter">
          <InfoIcon>i</InfoIcon>
        </Tooltip>
      </InputLabel>
      <Form.Item
        name="passwordRepeat"
        rules={[
          {
            required: true,
            message: 'Please input your password.',
          },
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords must match');
            },
          }),
        ]}>
        <Input
          prefix={<LockOutlined />}
          autoComplete="new-password"
          type="password"
          placeholder="Password repeat"
          data-testid="passwordRepeat"
        />
      </Form.Item>
      <Form.Item>
        <CtaButton type="primary" size="large" data-testid="continue" onClick={handleContinue}>
          Continue
        </CtaButton>
      </Form.Item>
    </div>
  );
};
