import {
  VerifyCompanyForm,
  VerifyCompanyFormValues,
} from '../../../components/Modal/VerifyCompanyForm/VerifyCompanyForm';
import {useSelector} from '../../../redux/react-redux';
import {OnboardingStepWrapper} from '../OnboardingStepWrapper';
import {OnboardingStepProps} from '../useUserOnboarding';
import {SettingsCardHeader} from '../../../components/SettingsCard/SettingsCardHeader';
import {SettingsCard} from '../../../components/SettingsCard/SettingsCard';
import {SettingsArea} from '../../../components/SettingsCard/SettingsArea';
import {SettingsCardBody} from '../../../components/SettingsCard/SettingsCardBody';
import {refetchCurrentCompany} from '../../../components/UserContext/CompanyLoader';
import {Form} from 'antd';
import {useVerifyCompanyMutation} from '../../../queries/company/useVerifyCompanyMutation';

export const VerifyCompany = ({onNext, ...restProps}: OnboardingStepProps) => {
  const company = useSelector(state => state.company.data);

  const [form] = Form.useForm<VerifyCompanyFormValues>();

  const verifyCompanyMutation = useVerifyCompanyMutation();
  const verifyCompany = () => {
    verifyCompanyMutation.mutate(
      {id: company.id, verifyCompanyFormValues: form.getFieldsValue()},
      {
        onSuccess: async () => {
          await refetchCurrentCompany();
          onNext?.();
        },
      }
    );
  };

  return (
    <OnboardingStepWrapper
      title="Setup your company"
      subTitle="Please verify your company and set up your market inbox to receive your circulars inside seabo."
      dataTestId="VerifyCompany"
      onNext={verifyCompany}
      {...restProps}>
      <SettingsCard>
        <SettingsCardHeader title={'Verify company data'} />
        <SettingsCardBody>
          <SettingsArea>
            <VerifyCompanyForm form={form} company={company} />
          </SettingsArea>
        </SettingsCardBody>
      </SettingsCard>
    </OnboardingStepWrapper>
  );
};
