import {FC} from 'react';
import {Helmet} from 'react-helmet-async';
import {Route, Routes, useParams} from 'react-router-dom';
import {CompanyDetailsScreen} from './CompanyDetailsScreen/CompanyDetailsScreen';
import {CompanySettingsScreens} from './CompanySettingsScreen/CompanySettingsScreens';
import {VerifyCompanyScreen} from './VerifyCompanyScreen/VerifyCompanyScreen';
import {parseIntTS} from '../../utils/parseNumberTS';
import {useGetCompanyQuery} from '../../queries/company/useCompanyQuery';
import {useIsOwnCompany} from './useIsOwnCompany';

export const CompanyScreens: FC = () => {
  const params = useParams<{id: string}>();
  const companyId = parseIntTS(params.id);

  const companyQuery = useGetCompanyQuery(companyId);
  const isOwnCompany = useIsOwnCompany({companyId});
  const company = companyQuery.data;
  const isVerified = company?.isVerified;

  return (
    <>
      <Helmet title={'Company'} />
      {isOwnCompany ? <OwnCompanyRoutes isVerified={isVerified} /> : <NotOwnCompanyRoutes />}
    </>
  );
};

const OwnCompanyRoutes = ({isVerified}: {isVerified?: boolean}) => {
  if (!isVerified) {
    return (
      <Routes>
        <Route path={`*`} element={<VerifyCompanyScreen />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path={`/*`} element={<CompanyDetailsScreen />} />
      <Route path={`/settings/*`} element={<CompanySettingsScreens />} />
    </Routes>
  );
};

const NotOwnCompanyRoutes = () => {
  return (
    <Routes>
      <Route path={`*`} element={<CompanyDetailsScreen />} />
    </Routes>
  );
};
