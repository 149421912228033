import {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {FeaturePermission} from '../../../api/symfony/generated';
import {FilterProviderApi} from '../../../components/FilterProvider/FilterProviderApi';
import {HeroFilterMapCollapse} from '../../../components/HeroFilterMapCollapse/HeroFilterMapCollapse';
import {ActiveSubPortfolioList} from '../../../components/Portfolio/SubPortfolioList/ActiveSubPortfolioList';
import {PremiumFeatureProtection} from '../../../components/PremiumFeatureProtection/PremiumFeatureProtection';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {VesselMap} from '../../../components/VesselMap/VesselMap';
import {ChangeAllItemsPayload, GridClipboardActions} from '../../../redux/GridClipboard';
import {SubPortfolioId, PortfolioActions, PortfolioVessel, PortfolioType} from '../../../redux/Portfolio';
import {useSelector} from '../../../redux/react-redux';
import {useOpenVesselForm} from '../../CargoVesselForm/utils/useOpenVesselForm';
import {getIfAllSelected} from '../../portfolio/shared/getIfAllSelected';
import {ExportVesselsModal} from './ExportVesselsModal/ExportVesselsModal';
import {FastVesselImportModal} from './FastVesselImportModal/FastVesselImportModal';
import {MyFleetActionButton} from './MyFleetActionButton';
import {MyFleetFilters} from './MyFleetFilters';
import {MyFleetVesselGrid} from './MyFleetGrid';
import {MyFleetGridLayoutSelect} from './MyFleetGridLayoutSelect/MyFleetGridLayoutSelect';
import {
  invalidateGetPortfolioVesselsQuery,
  useGetPortfolioVesselsQuery,
} from './useGetPortfolioVesselsQuery/useGetPortfolioVesselsQuery';
import {VesselsFromSpireModal} from './VesselsFromSpire/VesselsFromSpireModal';
import {useQueryClient} from '@tanstack/react-query';
import {makePartialMapSwitches} from '../../../components/SeaboMap/utils/makePartialMapSwitches';
import {MyFleetTourDefinition} from './MyFleetTourDefinition';
import {PortfolioVesselFilterBranchDefinitions} from '../../../components/FilterProvider/Filters/Portfolio/PortfolioVesselFilterBranchDefinitions';

const TYPE: PortfolioType = 'vessel';

export const MyFleetBody: FC<{
  filterProviderApi: FilterProviderApi<typeof PortfolioVesselFilterBranchDefinitions>;
  filterLoaded: boolean;
  filterFetching: boolean;
}> = ({filterProviderApi, filterLoaded, filterFetching}) => {
  const dispatch = useDispatch();
  const openVesselForm = useOpenVesselForm();

  const [vesselsFromSpireModalVisible, setVesselsFromSpireModalVisible] = useState(false);
  const [exportVesselsModalVisible, setExportVesselsModalVisible] = useState(false);
  const [fastVesselImportModalVisible, setFastVesselImportModalVisible] = useState(false);

  const vesselsInGridClipboard = useSelector(state => state.gridClipboard.portfolio.vessels);
  const {vesselFilterSettings} = useSelector(state => state.portfolioFilters);
  const {activeTabVessel} = useSelector(state => state.portfolio.screenState);
  const {pageNumber, pageSize, sortField, sortOrder} = useSelector(state => state.portfolio.vesselGridState);

  const [isFilterUiOpen, setIsFilterUiOpen] = useState(true);

  const selectSubPortfolio = (id: SubPortfolioId) => dispatch(PortfolioActions.selectSubPortfolio(TYPE, id));
  const setVessels = useCallback(
    ({vessels, vesselsTotal}: {vessels: PortfolioVessel[]; vesselsTotal: number}) =>
      dispatch(PortfolioActions.setVessels({vessels, vesselsTotal})),
    [dispatch]
  );
  const changeSelectedItems = useCallback(
    (payload: ChangeAllItemsPayload) => dispatch(GridClipboardActions.changeAllItems(payload)),
    [dispatch]
  );
  const setPageNumber = (pageNumber: number) =>
    dispatch(PortfolioActions.setGridState({portfolioType: 'vessel', gridState: {pageNumber}}));

  const vesselsQuery = useGetPortfolioVesselsQuery(
    {
      subPortfolioId: activeTabVessel,
      filters: vesselFilterSettings ?? {},
      pageNumber: pageNumber,
      pageSize,
      sortField,
      sortOrder,
    },
    {
      enabled: filterLoaded,
    }
  );

  const vessels: PortfolioVessel[] | null = vesselsQuery.isSuccess ? vesselsQuery.data.data.items : null;
  const vesselsTotal = vesselsQuery.isSuccess ? vesselsQuery.data.data.totalItems : 0;

  const isFetching = filterFetching || vesselsQuery.isFetching;
  const isLoading = !filterLoaded || vesselsQuery.isLoading;

  const queryClient = useQueryClient();
  const reloadGrid = (pageNumber?: number) => {
    if (pageNumber) {
      setPageNumber(pageNumber);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    invalidateGetPortfolioVesselsQuery(queryClient);
  };

  useEffect(() => {
    if (vesselsQuery.isSuccess && vesselsQuery.data) {
      setVessels({
        vessels: vesselsQuery.data.data.items,
        vesselsTotal: vesselsQuery.data.data.totalItems,
      });
      changeSelectedItems({
        section: 'portfolio',
        subsection: 'vessels',
        newValue: getIfAllSelected(vesselsInGridClipboard, vesselsQuery.data.data.items),
      });
    }
  }, [vesselsQuery.data, vesselsQuery.isSuccess, vesselsInGridClipboard, setVessels, changeSelectedItems]);

  return (
    <div className={'portfolio'} data-testid="PortfolioVesselScreen">
      <VesselsFromSpireModal
        visible={vesselsFromSpireModalVisible}
        onCancel={() => setVesselsFromSpireModalVisible(false)}
      />
      <ExportVesselsModal
        scope="filters"
        visible={exportVesselsModalVisible}
        onClose={() => setExportVesselsModalVisible(false)}
      />
      <FastVesselImportModal
        visible={fastVesselImportModalVisible}
        onCancel={() => setFastVesselImportModalVisible(false)}
        onSuccess={() => {
          reloadGrid();
          setFastVesselImportModalVisible(false);
        }}
      />
      <ScreenHeader
        title="My Fleet"
        breadcrumbs={[{title: 'My Fleet'}]}
        tourDefinition={MyFleetTourDefinition}
        actions={
          <>
            <ScreenHeader.Actions.Link
              id="archiveButton"
              ghost
              to={`/my-fleet/archive`}
              data-cy="PortfolioVesselToArchiveBTN">
              Archive
            </ScreenHeader.Actions.Link>
            <PremiumFeatureProtection permission={FeaturePermission.ExportFromMyFleet}>
              <ScreenHeader.Actions.Button
                data-cy="PortfolioVesselOpenExportModalBTN"
                disabled={isLoading || vesselsTotal === 0}
                onClick={() => setExportVesselsModalVisible(true)}>
                Export
              </ScreenHeader.Actions.Button>
            </PremiumFeatureProtection>
            <ScreenHeader.Actions.Button
              onClick={() => setVesselsFromSpireModalVisible(true)}
              data-cy="PortfolioVesselShowNewBTN">
              Show new vessels
            </ScreenHeader.Actions.Button>
            <ScreenHeader.Actions.Button
              id="importVessels"
              onClick={() => setFastVesselImportModalVisible(true)}
              data-cy="PortfolioVesselImportBTN">
              Import Vessels
            </ScreenHeader.Actions.Button>
            <ScreenHeader.Actions.Button
              id="addVessel"
              type="primary"
              data-cy="PortfolioVesselAddBTN"
              onClick={() => openVesselForm({})}>
              Add vessel
            </ScreenHeader.Actions.Button>
          </>
        }
        features={
          <>
            {filterProviderApi.isAnyFilterApplied && (
              <ScreenHeader.Features.Button
                data-cy="PortfolioVesselResetFiltersBTN"
                onClick={() => {
                  filterProviderApi.onResetAllClick();
                }}>
                Reset filters
              </ScreenHeader.Features.Button>
            )}
            <ScreenHeader.Features.Toggle
              id="section-select"
              value={isFilterUiOpen}
              onChange={e => setIsFilterUiOpen(e.target.value)}
              options={[
                {
                  id: 'show-filters',
                  label: `Filters${
                    filterProviderApi.appliedFilterCount ? ` (${filterProviderApi.appliedFilterCount})` : ''
                  }`,
                  value: true,
                  props: {
                    'data-cy': 'PortfolioVesselShowFiltersBTN',
                  },
                },
                {
                  id: 'show-map',
                  label: 'Map',
                  value: false,
                  props: {
                    'data-cy': 'PortfolioVesselShowMapBTN',
                  },
                },
              ]}
            />
          </>
        }
      />
      <HeroFilterMapCollapse
        viewOpen={isFilterUiOpen ? 'filters' : 'map'}
        filters={<MyFleetFilters filterProviderApi={filterProviderApi} />}
        map={
          <VesselMap
            vessels={vessels}
            settingIdentifier={'my_fleet'}
            initialMapSettings={{
              mapStyle: 'SAT',
              switches: {
                ...makePartialMapSwitches({
                  ports: true,
                  tradingAreaNames: false,
                  highRiskAreas: true,
                  emissionControlAreas: true,
                }),
              },
            }}
          />
        }
      />
      <div style={{display: 'grid', gridTemplateColumns: '1fr auto'}}>
        <ActiveSubPortfolioList
          type={TYPE}
          onDelete={() => {
            reloadGrid(1);
          }}
          onSubTabSelect={id => {
            selectSubPortfolio(id);
            setPageNumber(1);
          }}
        />
        <MyFleetGridLayoutSelect vessels={vessels} />
      </div>
      <MyFleetVesselGrid
        isFiltered={filterProviderApi.isAnyFilterApplied}
        isArchive={false}
        fetching={isFetching}
        loading={isLoading}
        vessels={vessels}
        vesselsTotal={vesselsTotal}
        onReloadGrid={(pageNumber?: number) => {
          reloadGrid(pageNumber);
        }}
      />

      <MyFleetActionButton items={vesselsInGridClipboard} archive={false} onReloadGrid={reloadGrid} />
    </div>
  );
};
