import {FC, ReactNode, useEffect} from 'react';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {invalidateCompanyQuery, useGetCompanyQuery} from '../../queries/company/useCompanyQuery';
import {CompanyActions} from '../../redux/Company';
import {useUser} from './useUser';
import {useQueryClient} from '@tanstack/react-query';

export let refetchCurrentCompany: () => Promise<void>;

/**
 * Loads the current user's company and puts it in Redux.
 * Only once this is done, we render the component's children.
 */
export const CompanyLoader: FC<{children: ReactNode}> = ({children}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const user = useUser();
  const companyId = user.payload.company.id;
  const companyQuery = useGetCompanyQuery(companyId);

  refetchCurrentCompany = async () => invalidateCompanyQuery(companyId, queryClient);

  useEffect(() => {
    if (companyQuery.isSuccess && companyQuery.data) {
      dispatch(CompanyActions.setCompany(companyQuery.data));
    }
  }, [companyQuery.isSuccess, companyQuery.data, dispatch]);

  // Render nothing until we have loaded the company and put it in Redux.
  // Careful: We can't use react-query's isLoading state here because it ends before onSuccess has
  // put the company into Redux.
  const companyLoaded = useSelector(state => state.company.data.id);
  if (!companyLoaded) {
    return null;
  }

  return <>{children}</>;
};
