import {Button, DatePicker, Form, Space} from 'antd';
import styled from 'styled-components';
import {DateTimeFormat} from '../../utils/DateTimeFormat';

export const FormSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;

  &:not(:last-of-type) {
    padding: 15px 20px 20px;
    margin-bottom: 30px;
    border-bottom: var(--border-base);
  }
`;

export const FormSectionHeader = styled.div`
  flex: 0 0 33%;
  max-width: 250px;
`;

export const FormSectionBody = styled.div`
  flex: 1 1 66%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FormSectionItem = styled(Form.Item).attrs({
  layout: 'vertical',
})<{$span?: number}>`
  flex: 0 0 ${({$span}) => ($span ? `calc(${Math.floor(($span / 24) * 100)}% - 10px)` : '100%')};
`;

export const InputGroup = styled(Space.Compact)`
  display: flex !important;
  flex-wrap: nowrap !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: stretch;
`;

export const StyledDatePicker = styled(DatePicker).attrs({
  format: DateTimeFormat.Date,
})`
  width: 100%;
`;

export const StyledRangePicker = styled(DatePicker.RangePicker).attrs({
  format: DateTimeFormat.Date,
})`
  width: 100%;
`;

export const ExpandButton = styled(Button)`
  width: 100%;
  text-transform: unset;

  margin-top: 5px;
  :not(:first-child) {
    margin-top: 10px;
  }
`;

export const ExpandSection = styled(FormSectionBody)<{$hidden?: boolean}>`
  flex: 0 0 100%;
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};
`;
