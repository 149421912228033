import {FC} from 'react';
import {FeaturePermission} from '../../api/symfony/generated/models/FeaturePermission';
import {getCompanyState, getUser} from '../../redux/selectors';
import {assert} from '../../utils/assert';
import {CompanySubscriptionNotPermittedScreen} from './CompanySubscriptionNotPermittedScreen';
import {Card} from 'antd';
import {useSelector} from 'react-redux';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {CompanySubscriptionOverviewScreen} from './CompanySubscriptionOverviewScreen';
import {CompanySubscriptionFlowFeatureSetup} from './SubscriptionFlow/CompanySubscriptionFlowFeatureSetup';
import {SubscriptionStepEnum} from './SubscriptionSteps';
import {CompanySubscriptionUpgradeScreen} from './SubscriptionFlow/CompanySubscriptionUpgradeScreen';
import {CompanySubscriptionCancelScreen} from './SubscriptionFlow/CompanySubscriptionCancelScreen';
import {CompanySubscriptionDowngradeScreen} from './SubscriptionFlow/CompanySubscriptionDowngradeScreen';

export const CompanySubscriptionScreens: FC = () => {
  const user = useSelector(getUser);
  assert(user);
  const isPermitted = user.payload.permissions.includes(FeaturePermission.ManageOwnCompany);
  if (!isPermitted) {
    return <CompanySubscriptionNotPermittedScreen />;
  }
  return <CompanySubscriptionPermittedScreens />;
};

const CompanySubscriptionPermittedScreens: FC = () => {
  const navigate = useNavigate();

  const company = useSelector(getCompanyState);
  const isSubscribed = company.data.isPayingCustomer;

  return (
    <div data-testid="Subscription">
      <ScreenHeader
        title="Subscription"
        breadcrumbs={[{title: 'Company', href: `/company/${company.data.id}`}, {title: 'Subscription'}]}
      />
      <div data-testid="CompanySubscriptionPaymentEnabled">
        <Card>
          <Routes>
            <Route path="/" element={<CompanySubscriptionOverviewScreen />} />
            <Route path="/upgrade" element={<CompanySubscriptionUpgradeScreen />} />
            <Route path="/downgrade" element={<CompanySubscriptionDowngradeScreen />} />
            <Route path="/cancel" element={<CompanySubscriptionCancelScreen />} />
            <Route
              path={`/${SubscriptionStepEnum.Feature}`}
              element={
                <CompanySubscriptionFlowFeatureSetup
                  isSubscribed={isSubscribed}
                  onFinished={() => navigate(`/subscription/${SubscriptionStepEnum.Summary}`)}
                />
              }
            />
          </Routes>
        </Card>
      </div>
    </div>
  );
};
