import {FC, ReactNode} from 'react';
import {useAccessTokenManager} from './useAccessTokenManager';
import {FullScreenLoadingAnimation} from '../../atoms/FullScreenLoadingAnimation/FullScreenLoadingAnimation';

export const AccessTokenManager: FC<{
  children: ReactNode;
}> = ({children}) => {
  const {hasAuthorizationCode, hasRefreshToken, hasAccessToken} = useAccessTokenManager();

  // When the OAuth flow has newly started, we first wait until the redirects have finished to show something.
  if (!hasAuthorizationCode && !hasRefreshToken) {
    return null;
  }

  // When the PHP-backend OAuth redirect has finished, we show a loading animation until the access token is present.
  if (!hasAccessToken) {
    return <FullScreenLoadingAnimation />;
  }

  // Access token is present and valid - we render the page content.
  return <>{children}</>;
};
