import {Checkbox, Col, Row} from 'antd';
import {FC, ReactNode} from 'react';
import {Helmet} from 'react-helmet-async';
import styled from 'styled-components';
import ActionsBar from '../../atoms/ActionsBar';
import {Breadcrumb} from '../../atoms/Breadcrumb';
import {BreadcrumbItem} from '../../atoms/Breadcrumb/BreadcrumbItem';
import {ScreenActionButton, ScreenActionLink, ScreenActionMenuButton} from './ScreenActionButton';
import {TourDefinition} from '../OnboardingTour/TourDefinition';
import {TourOverlay} from '../OnboardingTour/TourOverlay';
import {ShowTourButton} from './ShowTourButton';
import {ScreenFeatureButton, ScreenFeatureToggle} from './ScreenFeatureButton';
import {ScreenTitle} from '../ScreenTitle/ScreenTitle';

type ScreenHeaderProps = {
  title?: string;
  helmetTitle?: string;
  breadcrumbs?: BreadcrumbItem[];
  tourDefinition?: TourDefinition;
  actions?: ReactNode;
  features?: ReactNode;
};

const ScreenHeaderLayout: FC<ScreenHeaderProps> = ({
  title,
  helmetTitle,
  breadcrumbs,
  tourDefinition,
  actions,
  features,
}) => {
  return (
    <>
      {(helmetTitle || title) && <Helmet title={helmetTitle || title} />}
      {breadcrumbs && <Breadcrumb items={breadcrumbs} />}
      {(actions || tourDefinition) && <ActionsBar>{actions}</ActionsBar>}
      {tourDefinition && <TourOverlay tourDefinition={tourDefinition} />}
      {(title || features) && (
        <ScreenHeaderContainer>
          <ScreenTitleRow align="bottom" justify="space-between">
            {title && (
              <Col span="auto" style={{display: 'flex', alignItems: 'center'}}>
                <ScreenTitle
                  title={title}
                  addon={tourDefinition && <ShowTourButton tourDefinition={tourDefinition} />}
                />
              </Col>
            )}
            {features && (
              <Col span="auto">
                <Row align="middle" justify="space-between" style={{gap: 10}}>
                  {features}
                </Row>
              </Col>
            )}
          </ScreenTitleRow>
        </ScreenHeaderContainer>
      )}
    </>
  );
};

/**
 * @description This uses ScreenHeader as a component and also as a namespace for the inner components.
 */
export const ScreenHeader = Object.assign(ScreenHeaderLayout, {
  Title: ScreenTitle,
  Actions: {
    Menu: ScreenActionMenuButton,
    Button: ScreenActionButton,
    Link: ScreenActionLink,
  },
  Features: {
    Button: ScreenFeatureButton,
    Toggle: ScreenFeatureToggle,
    Checkbox: Checkbox,
  },
} as const);

const ScreenHeaderContainer = styled.div`
  position: relative;
  padding: 0.1px 0.1px 10px;
  margin: 0 0 10px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: var(--border-radius);
    right: var(--border-radius);
    height: 1px;
    background: var(--border-color);
  }

  .filter {
    margin-bottom: unset;
  }
`;

const ScreenTitleRow = styled(Row)`
  height: 36px;
`;
