import {FC} from 'react';
import {NegotiationScreenContainer} from '../Components/shared';
import {NegotiationOverviewList} from './NegotiationOverviewList';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {QuestionCircleOutlined} from '@ant-design/icons';

export const NegotiationOverviewScreen: FC = () => {
  return (
    <NegotiationScreenContainer data-testid="NegotiationOverview">
      <ScreenHeader
        title="Negotiations"
        breadcrumbs={[{title: 'Negotiations'}]}
        actions={
          <>
            <ScreenHeader.Actions.Link to="/negotiations/templates" data-testid="NegotiationTemplatesLink">
              Templates
            </ScreenHeader.Actions.Link>
            <ScreenHeader.Actions.Link type="primary" to="/negotiations/create" data-testid="NegotiationCreateLink">
              New Negotiation
            </ScreenHeader.Actions.Link>
            <ScreenHeader.Actions.Link to="/negotiations/tutorial" data-testid="NegotiationTutorialLink">
              <QuestionCircleOutlined />
            </ScreenHeader.Actions.Link>
          </>
        }
        features={<></>}
      />
      <NegotiationOverviewList />
    </NegotiationScreenContainer>
  );
};
