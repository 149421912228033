import {useCallback} from 'react';
import {AuthenticationActions} from '../../redux/Authentication';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {useGenerateAccessTokenMutation} from './useGenerateAccessTokenMutation';
import {useGenerateRefreshTokenMutation} from './useGenerateRefreshTokenMutation';
import dayjs from 'dayjs';
import {
  getRefreshToken,
  setRefreshToken,
  setRefreshTokenExpiration,
  getSourceHref,
  setSourceHref,
  removeSourceHref,
  generateAuthorizationCode,
  getAuthorizationCodeFromRedirectUrl,
  isAuthCsrfTokenValid,
  handleAuthError,
  resetAuthState,
  resetAuthStateWithMessage,
} from './utils';

/**
 * Provides the authentication state and functions to generate access and refresh tokens.
 */
export const useAuthentication = () => {
  const dispatch = useDispatch();

  // Authorization code is set in URL, only during the PHP-backend OAuth redirect flow.
  const authorizationCode = getAuthorizationCodeFromRedirectUrl();

  // Refresh token is stored in SessionStorage, so we can use it even if the user is not logged in.
  const refreshToken = getRefreshToken();

  // Access token is stored in Redux, so we can use it only if the user is logged in.
  const accessToken = useSelector(state => state.authentication.accessToken);
  const setAccessToken = useCallback(
    (accessToken: string) => dispatch(AuthenticationActions.setAccessToken(accessToken)),
    [dispatch]
  );

  const generateRefreshTokenMutation = useGenerateRefreshTokenMutation();
  const generateRefreshToken = useCallback(async (authorizationCode: string, sourceHref: string) => {
    try {
      const data = await generateRefreshTokenMutation.mutateAsync({authorizationCode, sourceHref});
      const {refreshToken: newRefreshToken, expiresIn} = data;
      if (!newRefreshToken) {
        resetAuthStateWithMessage('Received incomplete refresh token data.');
        return;
      }
      setRefreshToken(newRefreshToken);
      setRefreshTokenExpiration(dayjs(Date.now() + expiresIn * 1000));
    } catch (error) {
      handleAuthError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateAccessTokenMutation = useGenerateAccessTokenMutation();
  const generateAccessToken = useCallback(async (refreshToken: string) => {
    try {
      const data = await generateAccessTokenMutation.mutateAsync({refreshToken});
      const {accessToken: newAccessToken, refreshToken: newRefreshToken, expiresIn} = data;
      if (!newAccessToken || !newRefreshToken) {
        resetAuthStateWithMessage('Received incomplete access token data.');
        return;
      }
      setAccessToken(newAccessToken);
      setRefreshToken(newRefreshToken);
      setRefreshTokenExpiration(dayjs(Date.now() + expiresIn * 1000));
    } catch (error) {
      handleAuthError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the OAuth flow to retrieve PHP authorization code and node-backend refresh and access tokens.
   * This function needs to run once on every first render of the page in order to handle every step of the flow.
   */
  const handleAuthFlow = useCallback(async () => {
    let sourceHref = getSourceHref();
    let authorizationCode = getAuthorizationCodeFromRedirectUrl();
    let refreshToken = getRefreshToken();

    // Step 1 - Get PHP-backend authorization
    if (!refreshToken && !authorizationCode) {
      // Reset the browser to avoid a redirect loop.
      resetAuthState();
      setSourceHref(window.location.href);
      // Redirect to the OAuth server and back to sourceHref to run the next step.
      generateAuthorizationCode();
      return;
    }

    // We update the authorizationCode with the one from the redirected URL and the sourceHref from SessionStorage
    authorizationCode = getAuthorizationCodeFromRedirectUrl();
    sourceHref = getSourceHref();

    // Step 2 - Get node-backend refresh token
    if (!refreshToken && authorizationCode) {
      // We check if the sourceHref is present in SessionStorage, if not we reset the browser.
      // This can happen if the user opens link from somewhere that includes an authorization code.
      // For example: https://beta.seabo.com/dashboard?code=def5020011811044be9c783b0f...
      if (!sourceHref) {
        resetAuthStateWithMessage('No sourceHref found.');
        return;
      }
      // Check if the CSRF token in the URL is equal to SessionStorage, to prevent CSRF attacks.
      if (!isAuthCsrfTokenValid()) {
        resetAuthStateWithMessage('CSRF token not valid.');
        return;
      }
      // We get the refresh token and store it in SessionStorage
      await generateRefreshToken(authorizationCode, sourceHref);
      // We clean up authorization code from the URL and sourceHref from SessionStorage
      window.history.replaceState(null, '', sourceHref);
      removeSourceHref();
    }

    // We sync the refreshToken with SessionStorage
    refreshToken = getRefreshToken();

    // Step 3 - Get the initial access token
    if (refreshToken) {
      await generateAccessToken(refreshToken!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    authorizationCode,
    refreshToken,
    accessToken,
    generateRefreshToken,
    generateAccessToken,
    handleAuthFlow,
  };
};
