import React, {FC} from 'react';
import styled from 'styled-components';
import {WorkspaceIcon} from './WorkspaceIcon';
import {User, Workspace} from './WorkspaceSelectTypes';
import {UserAvatar} from '../UserAvatar/UserAvatar';
import {EasySharingIcon} from './EasySharingIcon';
import {FeatureToggles} from '../../utils/FeatureToggles';

export const WorkspaceList: FC<{
  workspaces: Workspace[];
  user: User;
  value?: number;
  onSelect?: (workspace: Workspace) => void;
}> = ({workspaces, user, value, onSelect}) => (
  <WorkspaceListContainer data-testid="WorkspaceList">
    {workspaces.map(workspace => (
      <WorkspaceListEntry
        key={workspace.id}
        workspace={workspace}
        user={user}
        selected={value === workspace.id}
        onSelect={onSelect}
      />
    ))}
  </WorkspaceListContainer>
);

const WorkspaceListContainer = styled.div`
  width: 224px;
  background: var(--color-white);
`;

export const WorkspaceListEntry: FC<{
  workspace: Workspace;
  user: User;
  selected?: boolean;
  onSelect?: (workspace: Workspace) => void;
}> = ({workspace, user, selected, onSelect}) => {
  const icon = getIcon(workspace, user);
  return (
    <ListEntryButton
      $selected={selected}
      onClick={() => {
        onSelect?.(workspace);
      }}>
      {icon}
      <Title>
        {workspace.name}
        {FeatureToggles.easySharing && <EasySharingIcon isActive={workspace.easySharing} />}
      </Title>
    </ListEntryButton>
  );
};

const ListEntryButton = styled.button<{$selected?: boolean}>`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 6px;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 8px;
  background-color: var(--color-white) !important;

  font-style: normal;
  font-size: var(--font-size-md);
  line-height: 16px;
  color: var(--color-black);
  cursor: pointer;
  text-transform: none !important;

  &:hover {
    background-color: var(--color-gray-5) !important;
    color: var(--color-black) !important;
  }

  &:active {
    background-color: var(--color-gray-6) !important;
  }

  ${({$selected}) => $selected && `background-color: var(--color-gray-5) !important;`}
`;

const getIcon = (workspace: Workspace, user: User): React.ReactNode => {
  if (workspace.id !== 0) {
    return (
      <IconWrapper>
        <WorkspaceIcon />
      </IconWrapper>
    );
  }
  return <UserAvatar user={user} />;
};

const IconWrapper = styled.div`
  flex: 0 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: var(--color-blue);
  svg {
    fill: var(--color-white);
  }
`;

const Title = styled.div`
  flex: 1 1 100%;
  width: 100%;
  text-align: start;
  font-weight: var(--font-weight-semibold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
