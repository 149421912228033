import dayjs, {Dayjs} from 'dayjs';
import {AUTH_CLIENT_ID, AUTH_SCOPE} from './constants';
import {isApiErrorWithStatus} from '../../api/utils/ApiError';

const AUTH_CSRF_TOKEN_KEY = 'authCsrfToken';
const generateAuthCsrfToken = () => {
  const csrfTokenArray = new Uint8Array(32);
  crypto.getRandomValues(csrfTokenArray);
  const csrfToken = Array.from(csrfTokenArray)
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
  return csrfToken;
};
const getAuthCsrfTokenFromSessionStorage = () => window.sessionStorage.getItem(AUTH_CSRF_TOKEN_KEY);
const getAuthCsrfTokenFromRedirectUrl = () => new URLSearchParams(window.location.search).get('state');
const setAuthCsrfToken = (csrfToken: string | null) => {
  window.sessionStorage.setItem(AUTH_CSRF_TOKEN_KEY, csrfToken!);
};
const removeAuthCsrfToken = () => {
  window.sessionStorage.removeItem(AUTH_CSRF_TOKEN_KEY);
};
export const isAuthCsrfTokenValid = () => {
  const oldToken = getAuthCsrfTokenFromSessionStorage();
  const newToken = getAuthCsrfTokenFromRedirectUrl();
  return newToken === oldToken;
};

/**
 * Generates a new OAuth authorization code by redirecting to the PHP-backend and back to the current URL.
 * @note Always return after calling this function in order to avoid executing code after the redirect.
 * @see https://oauth2.thephpleague.com/authorization-server/auth-code-grant/
 */
export const generateAuthorizationCode = () => {
  const currentUrl = window.location.href;
  const authCsrfToken = generateAuthCsrfToken();
  setAuthCsrfToken(authCsrfToken);
  const url = new URL('/api/authorize', window.location.origin);
  const searchParams = new URLSearchParams({
    /* eslint-disable camelcase */
    response_type: 'code',
    client_id: AUTH_CLIENT_ID,
    scope: AUTH_SCOPE,
    state: authCsrfToken,
    redirect_uri: currentUrl,
    /* eslint-enable camelcase */
  });
  url.search = searchParams.toString();
  window.location.replace(url.toString());
};
export const getAuthorizationCodeFromRedirectUrl = () => new URLSearchParams(window.location.search).get('code');

const AUTH_SOURCE_HREF_KEY = 'authSourceHref';
export const getSourceHref = () => window.sessionStorage.getItem(AUTH_SOURCE_HREF_KEY);
export const setSourceHref = (value: string) => window.sessionStorage.setItem(AUTH_SOURCE_HREF_KEY, value);
export const removeSourceHref = () => window.sessionStorage.removeItem(AUTH_SOURCE_HREF_KEY);

const AUTH_REFRESH_TOKEN_KEY = 'authRefreshToken';
export const getRefreshToken = () => window.sessionStorage.getItem(AUTH_REFRESH_TOKEN_KEY);
export const setRefreshToken = (value: string) => window.sessionStorage.setItem(AUTH_REFRESH_TOKEN_KEY, value);
export const removeRefreshToken = () => window.sessionStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);

const AUTH_REFRESH_TOKEN_EXPIRATION_KEY = 'authRefreshTokenExpiration';
export const getRefreshTokenExpiration = () => {
  const expirationDateString = window.sessionStorage.getItem(AUTH_REFRESH_TOKEN_EXPIRATION_KEY);
  return expirationDateString ? dayjs(expirationDateString) : null;
};
export const setRefreshTokenExpiration = (expirationDate: Dayjs) =>
  window.sessionStorage.setItem(AUTH_REFRESH_TOKEN_EXPIRATION_KEY, expirationDate.toISOString());
const removeRefreshTokenExpiration = () => window.sessionStorage.removeItem(AUTH_REFRESH_TOKEN_EXPIRATION_KEY);

/**
 * Resets the browser by removing the sourceHref, csrf, refresh and current access token from SessionStorage.
 */
export const resetAuthState = () => {
  removeSourceHref();
  removeAuthCsrfToken();
  removeRefreshToken();
  removeRefreshTokenExpiration();
};

/**
 * Logs given warning to the console, resets the browser's token storage and redirects to the login page.
 * @note Always return after calling this function in order to avoid executing code after the redirect.
 */
export const resetAuthStateWithMessage = (message: string) => {
  // eslint-disable-next-line no-console
  console.warn(`[AUTH] ${message}`);
  resetAuthState();
  window.location.href = '/login';
};

/**
 * Handles API errors that can occur during the token retrieval and resets the browser if necessary.
 */
export const handleAuthError = (error: unknown) => {
  if (isApiErrorWithStatus(error, 400)) {
    resetAuthStateWithMessage(`Authorization code expired: ${error.message}`);
    return;
  }
  if (isApiErrorWithStatus(error, 401)) {
    resetAuthStateWithMessage(`Refresh token expired: ${error.message}`);
    return;
  }
  // If other error, we throw to the error boundary
  throw error;
};
