import {FC, useState} from 'react';
import styled, {css} from 'styled-components';
import {useSelector} from '../../redux/react-redux';
import {SmartFavIcon} from './FavIcon/SmartFavIcon';
import {FullPageFooter} from './FullPageFooter';
import {FullPageHeader} from './FullPageHeader/FullPageHeader';
import {Sidebar} from './Sidebar/Sidebar';
import {getHasProPlan, getSidebarMode} from '../../redux/selectors';
import {SidebarMode} from '../../redux/Sidebar';

export const windowHeaderHeight = 72;

export const FullLayout: FC<{children: React.ReactNode}> = ({children}) => {
  const hasProPlan = useSelector(getHasProPlan);
  const [promoBarVisible, setPromoBarVisible] = useState(!hasProPlan);
  const sidebarMode = useSelector(getSidebarMode);
  return (
    <>
      <SmartFavIcon />
      <FullPageLayout data-testid="FullPageLayout" $promoBarVisible={promoBarVisible} $sidebarMode={sidebarMode}>
        <Sidebar />
        <FullPageHeader promoBarVisible={promoBarVisible} onPromoBarDismissed={() => setPromoBarVisible(false)} />
        <ContentArea>
          <section id="main" role="main">
            <div id="content" className="content">
              <main>{children}</main>
            </div>
          </section>
          <FullPageFooter />
        </ContentArea>
      </FullPageLayout>
    </>
  );
};

const FullPageLayout = styled.div<{$promoBarVisible: boolean; $sidebarMode: SidebarMode}>`
  // Header styles
  --header-height: ${windowHeaderHeight}px;
  --promo-height: 24px;

  --top-offset: ${({$promoBarVisible}) =>
    $promoBarVisible ? 'calc(var(--header-height) + var(--promo-height))' : 'var(--header-height)'};

  // Sidebar styles
  --sidebar-width-wide: 240px;
  --sidebar-width-narrow: 80px;
  --sidebar-width-hidden: 0px;

  // App layout
  --padding-horizontal: 32px;
  --padding-vertical: 24px;

  ${({$sidebarMode}) => {
    if ($sidebarMode === 'wide') {
      return css`
        --sidebar-width: var(--sidebar-width-wide);
      `;
    }
    if ($sidebarMode === 'narrow') {
      return css`
        --sidebar-width: var(--sidebar-width-narrow);
      `;
    }
    return css`
      --sidebar-width: var(--sidebar-width-hidden);
    `;
  }}

  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentArea = styled.div`
  flex: 1 0 auto;
  padding: calc(var(--top-offset) + var(--padding-vertical)) var(--padding-horizontal) 0;
  padding-left: calc(var(--sidebar-width) + var(--padding-horizontal));
  transition: padding 0.1s;
`;
