import {FC} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {FeaturePermission} from '../../../api/symfony/generated';
import {Breadcrumb} from '../../../atoms/Breadcrumb';
import {Button} from '../../../atoms/Button/Button';
import {UserCard} from '../../../components/UserCard/UserCard';
import CompanyUser from '../../../model/company/CompanyUser';
import CompanyActions from '../../../redux/ApiService/CompanyService/CompanyService';
import {TODO} from '../../../utils/TODO';
import {useIsPermitted} from '../../../utils/useIsPermitted';
import CompanyHeader from './CompanyHeader';
import {CompanyOnBoarding} from './CompanyOnBoarding/CompanyOnBoarding';
import {CompanySubscriptionStatus} from './CompanySubscriptionStatus';
import ContactDetails from './ContactDetails';
import Description from './Description';
import {useIsOwnCompany} from '../useIsOwnCompany';
import {useUser} from '../../../components/UserContext/useUser';
import {isLocalDevEnvironment} from '../../../utils/environment';
import {useGetCompanyQuery} from '../../../queries/company/useCompanyQuery';

type RouteParams = 'id';

export const CompanyDetailsScreen: FC = () => {
  const navigate = useNavigate();
  const params = useParams<RouteParams>();
  const user = useUser();
  const currentUser = user.payload;
  const manageOwnCompanyPermitted = useIsPermitted(FeaturePermission.ManageOwnCompany);
  const companyId = parseInt(params.id!);

  const isOwnCompany = useIsOwnCompany({companyId});
  const getCompanyQuery = useGetCompanyQuery(companyId);

  const dispatch = useDispatch();
  const followOrUnfollow = (id: number, body: TODO) => dispatch(CompanyActions.followOrUnfollow(id, body));

  if (!getCompanyQuery.data) {
    return null;
  }

  const company = getCompanyQuery.data;

  const makeFollow = () => {
    followOrUnfollow(company.id, {
      isFollowing: !company.isFollowing,
    });
  };

  const {companyLogo, isOnboarded, user: users} = company;

  let logoToDisplay: string | null = companyLogo?.url ?? null;

  if (isLocalDevEnvironment() && logoToDisplay && !logoToDisplay.includes('localhost')) {
    // Patch the server URL in order to enable local development
    // https://beta.seabo.com/download/5bf05438-bd51-411a-a2bc-d65822d1ff39
    // will become
    // http://beta.seabo.com.localhost:3000/download/5bf05438-bd51-411a-a2bc-d65822d1ff39
    logoToDisplay = logoToDisplay.replace('https://', 'http://');
    logoToDisplay = logoToDisplay.replace('.seabo.com', '.seabo.com.localhost:3000');
  }

  return (
    <>
      <Breadcrumb items={[{title: 'Company'}]} />
      <StyledCompanyDetails>
        {(currentUser.company === null || !!(currentUser.company && currentUser.company.id !== company.id)) && (
          <Button
            id={'company-follow'}
            bold
            upper
            label={company.isFollowing ? 'Unfollow' : 'Follow'}
            primary
            onClick={() => makeFollow()}
          />
        )}
        {isOwnCompany && manageOwnCompanyPermitted && (
          <Button
            id={'company-edit'}
            bold
            upper
            label={'Edit company'}
            primary
            onClick={() => navigate(`/company/${company.id}/settings`)}
          />
        )}
        {!isOnboarded && isOwnCompany && manageOwnCompanyPermitted && (
          <CompanyOnBoarding users={users} company={company} />
        )}
        {isOwnCompany && <CompanySubscriptionStatus />}
        <CompanyHeader
          name={company.name}
          business={company.business}
          city={company.address.city}
          country={company.address.country}
          vessels={company.vesselsManaged}
          users={users}
          createdAt={company.createdAt}
          logoUrl={logoToDisplay}
        />
        <DescriptionLayout>
          <Description
            description={company.description}
            employees={company.employees}
            foundYear={company.foundYear}
            companyType={company.companyType}
          />
          <ContactDetails address={company.address} contact={company.contact} taxId={company.taxId} />
        </DescriptionLayout>
        <UserCardsContainer>
          {users.map(user => {
            return <UserCard key={user.userID} user={new CompanyUser(user)} companyName={company.name} />;
          })}
        </UserCardsContainer>
      </StyledCompanyDetails>
    </>
  );
};

const UserCardsContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`;

const DescriptionLayout = styled.div`
  display: grid;
  //grid-template-columns: 3fr 2fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 16px;
  margin-bottom: 20px;
`;

const StyledCompanyDetails = styled.div`
  position: relative;
  min-width: 790px;

  #button-company-follow,
  #button-company-edit {
    right: 0;
    position: absolute;
    top: -60px;
  }
`;
