import {FC, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import ActionMenuButton, {ActionMenuButtonProps} from '../../atoms/ActionMenuButton/ActionMenuButton';
import {Button, ButtonProps} from 'antd';
import styled, {css} from 'styled-components';

export type ActionButtonProps = {
  type?: ButtonProps['type'];
  ghost?: ButtonProps['ghost'];
  shape?: ButtonProps['shape'];
  id?: string;
  disabled?: boolean;
  href?: string;
  'data-cy'?: string;
  'data-testid'?: string;
  onClick?: () => void;
  children: ReactNode;
};

export const ScreenActionButton: FC<ActionButtonProps> = props => {
  return <ActionButton {...props} />;
};

type ScreenActionLinkProps = Omit<ActionButtonProps, 'onClick'> & {
  to: string;
};

export const ScreenActionLink: FC<ScreenActionLinkProps> = props => {
  const {id, type, shape, ghost, disabled, to, children, 'data-cy': dataCy, 'data-testid': dataTestId} = props;
  return (
    <Link to={to} data-cy={dataCy} data-testid={dataTestId}>
      <ActionButton id={id} type={type} shape={shape} ghost={ghost} disabled={disabled}>
        {children}
      </ActionButton>
    </Link>
  );
};

export const ScreenActionMenuButton: FC<ActionMenuButtonProps> = props => {
  return <ActionMenuButton {...props} className="ant-btn ant-btn-sm ant-btn-default" />;
};

const ActionButton: FC<ActionButtonProps> = ({
  id,
  type,
  shape,
  ghost,
  disabled,
  onClick,
  children,
  'data-cy': dataCy,
  'data-testid': dataTestId,
}) => {
  return (
    <ButtonStyled
      id={id}
      type={type ?? 'default'}
      shape={shape ?? 'default'}
      ghost={ghost}
      data-cy={dataCy}
      data-testid={dataTestId}
      disabled={disabled}
      onClick={onClick}>
      {children}
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button)<{type?: ButtonProps['type']; shape?: ButtonProps['shape']; ghost?: boolean}>`
  font-weight: var(--font-weight-semibold);
  text-transform: unset;

  ${({shape}) => {
    if (shape !== 'circle' && shape !== 'round') {
      return css`
        border-radius: var(--border-radius-button) !important;
      `;
    }
  }}

  ${({type, ghost}) => {
    if (type !== 'primary' && !ghost) {
      return css`
        color: var(--color-black);
        border: var(--border-width) solid var(--color-gray-4) !important;
        background-color: var(--color-white) !important;
        &:hover {
          background-color: var(--color-gray-5) !important;
          color: var(--color-gray-1) !important;
        }
        &:active {
          background-color: var(--color-gray-6) !important;
          color: var(--color-gray-1) !important;
        }
      `;
    }
    if (ghost) {
      return css`
        color: var(--color-gray-1);
        background-color: transparent !important;
        border: none !important;
        &:hover {
          background-color: transparent !important;
          border: none !important;
        }
        &:active {
          background-color: transparent !important;
          border: none !important;
        }
      `;
    }
  }}
`;
