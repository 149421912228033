import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {authenticationApi} from '../../api/symfony/symfonyApi';
import {AccessToken, GenerateAccessTokenGrantTypeEnum} from '../../api/symfony/generated';
import {AUTH_CLIENT_ID, AUTH_SCOPE} from './constants';
import {ApiError} from '../../api/utils/ApiError';

type MutationParams = {
  authorizationCode: string;
  sourceHref: string;
};

export const useGenerateRefreshTokenMutation = (options?: UseMutationOptions<AccessToken, ApiError, MutationParams>) =>
  useMutation({
    mutationFn: generateRefreshToken,
    ...options,
  });

const generateRefreshToken = async ({authorizationCode, sourceHref}: MutationParams): Promise<AccessToken> => {
  const response = await authenticationApi.generateAccessToken({
    grantType: GenerateAccessTokenGrantTypeEnum.AuthorizationCode,
    code: authorizationCode,
    clientId: AUTH_CLIENT_ID,
    scope: AUTH_SCOPE,
    redirectUri: sourceHref,
  });
  return response;
};
