import {VoyagePoint} from '../VoyageInformation/VoyageTypes';
import {isPointInSECA} from './isPointInSECA';
import {geoJsonLatLngToGeolibCoordinates} from './geoJsonLatLngToGeolibCoordinates';
import {Coordinates} from '../../../../utils/Coordinates';
import {TODO} from '../../../../utils/TODO';
import {geocodingApi} from '../../../../api/node-backend/nodeBackendApis';

type Vessel = TODO;

export const loadNextOpenLocation = async (params: {vessel: Vessel}): Promise<VoyagePoint | undefined> => {
  const locations =
    (params.vessel.stations ?? []).find((station: TODO) => station.type === 'nextopen')?.locations ?? [];

  if (locations.length > 0 && locations[0].coordinates) {
    const point: VoyagePoint = {
      isPort: false,
      isInSECA: isPointInSECA({
        point: geoJsonLatLngToGeolibCoordinates(locations[0].coordinates as Coordinates),
      }),
      countryCode: locations[0].country,
      coordinates: locations[0].coordinates,
      name: locations[0].name,
      portCosts: 0,
      otherDuration: [],
      load: 0,
      types: ['nextOpen'],
      dis: 0,
    };
    return point;
  } else if (locations.length > 0) {
    // This occurs when the vessel comes from an email and the location is not provided

    const geocodingResponse = await geocodingApi.geocode({
      searchString: locations[0].name,
    });
    if (!geocodingResponse.result) {
      return undefined;
    }

    return {
      isPort: false,
      isInSECA: isPointInSECA({
        point: {
          lat: geocodingResponse.result.latitude,
          lng: geocodingResponse.result.longitude,
        },
      }),
      coordinates: [geocodingResponse.result.longitude, geocodingResponse.result.latitude],
      name: locations[0].name,
      portCosts: 0,
      otherDuration: [],
      load: 0,
      dis: 0,
      types: ['nextOpen'],
    };
  } else {
    // TODO Later get from AIS-api when no nextopen position

    return undefined;
  }
};
