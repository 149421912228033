import {FC} from 'react';
import styled from 'styled-components';
import {Breadcrumb, BreadcrumbProps} from 'antd';
import {BreadcrumbItem} from './BreadcrumbItem';
import {Link} from 'react-router-dom';
import {type ItemType} from 'antd/es/breadcrumb/Breadcrumb';
import {assert} from '../../utils/assert';

const SeaboBreadcrumb: BreadcrumbItem = {title: 'seabo', href: '/dashboard'};

/**
 * Displays the breadcrumbs at the top of the screen.
 */
export const BreadcrumbsBar: FC<{items: BreadcrumbItem[]}> = ({items}) => {
  const itemsToRender = [SeaboBreadcrumb, ...items];

  return (
    <BreadcrumbStyled separator={null} data-testid="BreadcrumbsBar" items={itemsToRender} itemRender={itemRender} />
  );
};

const itemRender: BreadcrumbProps['itemRender'] = (item, _params, _items, _paths) => {
  assert(isBreadcrumbItem(item), 'Can  render BreadcrumbItem only');
  return <BreadcrumbItemBox item={item} />;
};

const isBreadcrumbItem = (itemType: ItemType): itemType is BreadcrumbItem => 'title' in itemType;

const BreadcrumbItemBox: FC<{item: BreadcrumbItem}> = ({item}) => {
  const {title, href} = item;

  if (href) {
    return (
      <Link className={'breadcrumb-item'} to={href}>
        <span className={'router-link'}>{title}</span>
      </Link>
    );
  }

  return (
    <span className={'breadcrumb-item'}>
      <span>{title}</span>
    </span>
  );
};

const BreadcrumbStyled = styled(Breadcrumb)`
  height: 24px;
  margin: 0;
  padding: 0;

  ol {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    list-style-type: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      transition: background-color 0.1s linear;

      .breadcrumb-item {
        display: flex;
        align-items: center;
        color: var(--color-black);
        font-weight: var(--font-weight-semibold);
        line-height: 1;
        text-decoration: none;
        border-radius: var(--border-radius-button);
        transition: background-color 0.1s linear;
        z-index: 2;

        &:not(a) {
          cursor: default;
        }
        .route-link {
          display: block;
        }
      }

      &::before {
        content: '>';
        margin: 0 8px;
        color: var(--color-black);
      }

      &:hover > a.breadcrumb-item {
        background: var(--color-gray-5);
      }

      &:first-child {
        &::after,
        &::before {
          display: none;
        }
      }
    }
  }
`;
