import {Button, MenuProps} from 'antd';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {FeaturePermission} from '../../../api/symfony/generated';
import {authenticationApi} from '../../../api/symfony/symfonyApi';
import {showNofificationAfterPageReload} from '../../../components/FlashNotification/FlashNotification';
import {BackendScreenPermissions} from '../../../screens/backend/BackendScreenPermissions';
import {useIsPermitted} from '../../../utils/useIsPermitted';
import {useUser} from '../../../components/UserContext/useUser';
import {removeRefreshToken} from '../../../components/AccessTokenManager/utils';

export const useUserMenuItems = () => {
  const currentUser = useUser();
  const manageOwnCompanyPermitted = useIsPermitted(FeaturePermission.ManageOwnCompany);
  const backendPermitted =
    !!currentUser && currentUser.payload.permissions.some(permission => BackendScreenPermissions.includes(permission));

  const logout = async () => {
    await authenticationApi.logout();
    removeRefreshToken();
    showNofificationAfterPageReload({
      message: 'You logged out of seabo',
      type: 'info',
    });
    window.location.href = '/login';
  };

  const menuItems: MenuProps['items'] = [
    {
      className: 'group-header',
      key: 'user-header',
      label: (
        <Link data-testid="userProfilLink" to={`/user/${currentUser.id}/`}>
          {currentUser.fullName}
        </Link>
      ),
    },
    {
      key: 'user',
      type: 'group',
      className: 'empty-group-header',
      label: '',
      children: [
        {
          key: 'user-account',
          label: <Link to={`/user-account/account`}>Account</Link>,
        },
        {
          key: 'user-profile',
          label: <Link to={`/user-account/profile`}>Profile</Link>,
        },
        {
          key: 'user-profile-picture',
          label: <Link to={`/user-account/profile-picture`}>Profile picture</Link>,
        },
        {
          key: 'user-general',
          label: <Link to={`/user-account/timezone`}>Time zone</Link>,
        },
        {
          key: 'user-segments',
          label: <Link to={`/user-account/segments`}>Market segments</Link>,
        },
      ],
    },
  ];
  const company = currentUser.payload.company;
  if (company) {
    menuItems.push({
      key: 'company-header',
      className: 'group-header',
      label: (
        <Link to={`/company/${company.id}`} data-testid="companyArea">
          {company.name}
        </Link>
      ),
    });
    menuItems.push({
      key: 'company',
      type: 'group',
      className: 'empty-group-header',
      label: '',
      children: [
        {
          key: 'company-profile',
          label: <Link to={`/company/${company.id}`}>Company profile</Link>,
        },
        ...(manageOwnCompanyPermitted
          ? [
              {
                key: 'company-settings',
                label: <Link to={`/company/${company.id}/settings`}>Administration</Link>,
              },
              {
                key: 'company-subscription',
                label: <Link to={`/subscription`}>Subscription</Link>,
              },
            ]
          : []),
      ],
    });
  }
  if (backendPermitted) {
    menuItems.push({
      key: 'backend',
      label: <Link to={`/backend`}>Backend</Link>,
    });
  }
  menuItems.push({
    key: 'logout',
    className: 'menu-item-logout',
    label: <LogoutButton onClick={logout}>Logout</LogoutButton>,
  });

  return menuItems;
};

const LogoutButton = styled(Button)`
  width: 100%;
  padding: 8px 12px 9px;
  background: var(--color-white) !important;
  color: var(--color-red-dark) !important;
  text-align: left;
  border: none;
  border-radius: 0;

  &:hover {
    background: var(--color-gray-5) !important;
  }
`;
