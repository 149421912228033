import React, {FC} from 'react';
import styled, {keyframes} from 'styled-components';
import classNames from 'classnames';
import Icon from '../../../Icon';

export const BellIcon: FC<{ringingBellAnimation?: boolean}> = ({ringingBellAnimation = false}) => (
  <StyledIcon
    type="notifications"
    color="white-fixed"
    className={classNames({'ringing-bell-animation': ringingBellAnimation})}
  />
);

const ring = keyframes`
  0% {
    transform: rotate(-15deg);
  }

  2% {
    transform: rotate(15deg);
  }

  4% {
    transform: rotate(-18deg);
  }

  6% {
    transform: rotate(18deg);
  }

  8% {
    transform: rotate(-22deg);
  }

  10% {
    transform: rotate(22deg);
  }

  12% {
    transform: rotate(-18deg);
  }

  14% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-12deg);
  }

  18% {
    transform: rotate(12deg);
  }

  20% {
    transform: rotate(0deg);
  }
`;

const StyledIcon = styled(Icon)`
  display: block;
  width: 32px;
  text-align: center;
  font-weight: normal !important;
  font-size: 1.5rem !important;
  line-height: 19px !important;
  flex-shrink: 0;

  &.ringing-bell-animation {
    animation: ${ring} 4s infinite;
  }
`;
