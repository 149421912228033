import React, {useEffect, useMemo, useState, FC} from 'react';
import {useDispatch, useSelector} from '../../redux/react-redux';
import LoadingOverlay from '../../atoms/Loading/LoadingOverlay';
import ProgressTabBar from '../../components/ProgressTabBar';
import {actions as vesselApi} from '../../redux/ApiService/vesselService';
import {PortfolioVessel} from '../../redux/Portfolio';
import {Container, FormButtonGroup, FormManual, StyledForm, Wrapper} from './CargoVesselForm.styled';
import {prepareForApi} from './prepareForApi';
import {resetVesselErrors} from './resetVesselErrors';
import Comment from './SharedSections/Comment';
import IntakeSection from './utils/IntakeSection/IntakeSection';
import Documents from './SharedSections/Documents';
import {useStepper} from './stepper';
import './style.scss';
import {useScrollToFirstError} from './useScrollToFirstError';
import {getSteps, sectionMapping, StepLabels} from './VesselFormSteps';
import Backend from './VesselSections/Backend';
import ConsumptionSection from './VesselSections/Consumption/ConsumptionSection';
import Dimension from './VesselSections/Dimension';
import NextDryDock from './VesselSections/NextDryDock';
import Features from './VesselSections/Features';
import General from './VesselSections/General/General';
import Images from './VesselSections/Images';
import NextOpen from './VesselSections/NextOpen';
import Ownership from './VesselSections/Ownership';
import Review from './VesselSections/Review';
import Summary from './VesselSections/Summary';
import VesselSearch from './VesselSections/VesselSearch';
import {Button, Card} from 'antd';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {BreadcrumbItem} from '../../atoms/Breadcrumb/BreadcrumbItem';
import {TODO} from '../../utils/TODO';

type Vessel = TODO;

interface Props {
  formApi: TODO;
  isBackend: boolean;
  isEdit: boolean;
  manual?: boolean;
  vessel: Vessel;
  formValidationErrors: TODO;
  onIMOSelect: (vesselTemplate: PortfolioVessel) => void;
}

export const VesselFormBody: FC<Props> = props => {
  const {formApi, vessel, isBackend, isEdit, formValidationErrors, onIMOSelect} = props;
  const {onSubmit, validateSections, ...formProps} = formApi;

  const submitting: boolean = useSelector(
    state => state.api.vessels.validate.loading || state.api.vessels.patch.loading || state.api.vessels.post.loading
  );
  const uploading: boolean = useSelector(
    state =>
      state.api.attachmentService.uploadVesselImages.loading ||
      state.api.attachmentService.uploadVesselDocuments.loading
  );

  const dispatch = useDispatch();
  const validate = (body: TODO) => dispatch(vesselApi.validate({body}));

  const {currentStep, completedSteps, gotoStep, findStepWithFirstError} = useStepper(StepLabels);
  const [manual, setManual] = useState(props.manual ?? false);

  const formTitle = isEdit ? 'Edit' : 'Add vessel';

  const vesselType = formProps.form.general?.vesselType;

  const isOtherType = vesselType === 'tanker' || vesselType === 'other';
  const steps = useMemo(() => getSteps({isBackend, isOtherType, vesselType}), [isBackend, isOtherType, vesselType]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    if (formValidationErrors.length > 0) {
      const stepWithFirstError = findStepWithFirstError(formValidationErrors, sectionMapping, steps);
      if (stepWithFirstError !== currentStep) {
        gotoStep(stepWithFirstError);
      }
    }
  }, [formValidationErrors, findStepWithFirstError, currentStep, gotoStep, steps]);

  const scrollToFirstError = useScrollToFirstError();

  const validateAndGoToStep = async (formProps: TODO, i: TODO) => {
    try {
      await resetVesselErrors(dispatch);
      const form = formProps.runToApiTransformer(formProps.form);

      await validate({...prepareForApi(form, steps[currentStep].sections), name: form.general.name});
      gotoStep(i);
    } catch (e) {
      scrollToFirstError();
    }
  };

  const tabs = steps.map((step, i) => ({
    label: step.label,
    active: currentStep === i,
    completed: completedSteps[i],
    onClick: () => {
      if (validateSections(steps[currentStep].sections)) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        validateAndGoToStep(formProps, i);
      }
    },
    disable: !(manual || isEdit),
  }));

  const breadcrumbs: BreadcrumbItem[] = [
    {title: isBackend ? 'Backend' : 'My Fleet', href: isBackend ? '/backend/vessels' : '/my-fleet'},
  ];
  if (isEdit) {
    breadcrumbs.push({title: 'Vessel', href: isBackend ? `/backend/vessels/${vessel?.id}` : `/vessel/${vessel?.id}`});
  }
  breadcrumbs.push({title: formTitle});

  return (
    <Container className="cargo-vessel-form">
      <ScreenHeader title={`${formTitle}${vessel?.name ? ' vessel ' + vessel.name : ''}`} breadcrumbs={breadcrumbs} />
      <Wrapper>
        {submitting && <LoadingOverlay />}
        <ProgressTabBar tabs={tabs} />
        <StyledForm
          autoComplete={'off'}
          onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
            if (e.key === 'Enter' && (e.target as HTMLElement).tagName !== 'TEXTAREA') {
              e.preventDefault();
            }
          }}
          onSubmit={e => {
            e.preventDefault();
            if (validateSections(steps[currentStep].sections)) {
              onSubmit();
            } else {
              scrollToFirstError();
            }
          }}>
          <fieldset disabled={submitting || uploading}>
            {(currentStep > 0 || isEdit) && <Summary {...formProps} />}

            <Card>
              {!isEdit && currentStep === 0 && (
                <VesselSearch
                  {...formProps}
                  onSelect={({id: _id, ...vesselTemplate}: TODO) => {
                    onIMOSelect(vesselTemplate);
                    setManual(true);
                  }}
                  hasBorderBottom={currentStep === 0 && (manual || isEdit)}
                />
              )}

              <General
                {...formProps}
                show={currentStep === 0 && (manual || isEdit)}
                isEdit={isEdit}
                vessel={vessel}
                isBackend={isBackend}
              />
              <Backend {...formProps} show={currentStep === 0 && (manual || isEdit) && isBackend} />
              <Dimension {...formProps} show={currentStep === 0 && (manual || isEdit)} />

              {!isOtherType && (
                <>
                  <IntakeSection {...formProps} show={currentStep === 0 && (manual || isEdit)} />
                  <Features {...formProps} show={currentStep === 0 && (manual || isEdit)} />
                  <ConsumptionSection {...formProps} show={currentStep === 0 && (manual || isEdit)} />
                </>
              )}

              <Ownership {...formProps} show={currentStep === 0 && (manual || isEdit)} />
              <NextDryDock {...formProps} show={currentStep === 0 && (manual || isEdit)} />
              <NextOpen {...formProps} show={currentStep === 0 && (manual || isEdit) && !isBackend} />
              <Comment {...formProps} show={currentStep === 0 && (manual || isEdit)} />
              <Images {...formProps} show={currentStep === 0 && (manual || isEdit)} isEdit={isEdit} maxSize={5000000} />
              <Documents
                {...formProps}
                show={currentStep === 0 && (manual || isEdit)}
                uploadType={'vessel'}
                isEdit={isEdit}
                maxSize={5000000}
              />

              {currentStep === 1 && <Review {...formProps} isBackend={isBackend} />}

              {(currentStep > 0 || manual || isEdit) && (
                <FormButtonGroup>
                  {currentStep > 0 && (
                    <Button
                      disabled={submitting || uploading}
                      type="default"
                      onClick={() => {
                        if (validateSections(steps[currentStep].sections)) {
                          // eslint-disable-next-line @typescript-eslint/no-floating-promises
                          validateAndGoToStep(formProps, currentStep - 1);
                        } else {
                          scrollToFirstError();
                        }
                      }}>
                      Back
                    </Button>
                  )}
                  {currentStep < 1 && (
                    <Button
                      id="button-next-step"
                      type="default"
                      onClick={() => {
                        if (validateSections(steps[currentStep].sections)) {
                          // eslint-disable-next-line @typescript-eslint/no-floating-promises
                          validateAndGoToStep(formProps, currentStep + 1);
                        } else {
                          scrollToFirstError();
                        }
                      }}
                      disabled={submitting || uploading}>
                      Proceed to next step
                    </Button>
                  )}
                  <Button
                    data-testId={'submitButton'}
                    id="submit-form"
                    type="primary"
                    htmlType="submit"
                    disabled={submitting || uploading}>
                    Save and exit
                  </Button>
                </FormButtonGroup>
              )}
            </Card>

            {!manual && !isEdit && (
              <FormManual className={'cargo-vessel-form__manual'}>
                Didn't find what you were looking for?
                <br />
                <span onClick={() => setManual(true)}>Fill form fields manually</span>
              </FormManual>
            )}
          </fieldset>
        </StyledForm>
      </Wrapper>
    </Container>
  );
};
