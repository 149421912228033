import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {Company as ApiCompany, CompanyEdit} from '../../api/symfony/generated';
import {ApiError} from '../../api/utils/ApiError';
import {VerifyCompanyFormValues} from '../../components/Modal/VerifyCompanyForm/VerifyCompanyForm';
import {companyApi} from '../../api/symfony/symfonyApi';

export const useVerifyCompanyMutation = (
  options?: UseMutationOptions<ApiCompany, ApiError, {id: number; verifyCompanyFormValues: VerifyCompanyFormValues}>
) =>
  useMutation({
    mutationFn: async ({id, verifyCompanyFormValues}) => {
      const companyEdit: CompanyEdit = {
        ...verifyCompanyFormValues,
        isVerified: true,
      };
      const response = await companyApi.updateCompany({id, companyEdit});
      return response;
    },
    ...options,
  });
