import styled from 'styled-components';
import {LocationPicker} from '../../../../components/LocationPicker/LocationPicker';
import {FC} from 'react';
import {Form, Input} from 'antd';
import {Location} from '../../../../components/SearchPicker/Type';
import Flag from '../../../../atoms/Flags';
import {InputGroup} from '../../../../atoms/FormElements/FormElements';

type LocationPickerOrInputProps = {
  locationLabel?: string;
  locationCountryCode?: string;
  formNameFactory: (label: string) => string[];
  onSelectLocation: (location: Location | null) => void;
};
/**
 * Note: LocationPicker itself can not be controlled by AntD Form.
 * CreateNegotiationRequest takes a label and countryCode
 * This component is used to display either a LocationPicker or a controlled InputGroup
 * depending on whether a location has been selected or not.
 */
export const LocationPickerOrControlledInput: FC<LocationPickerOrInputProps> = ({
  locationLabel,
  locationCountryCode,
  formNameFactory,
  onSelectLocation,
}) => {
  return (
    <>
      <Hide $hidden={!locationLabel}>
        <InputGroup>
          <Form.Item name={formNameFactory('Label')} noStyle>
            <Input
              value={locationLabel}
              onClick={() => onSelectLocation(null)}
              addonBefore={<Flag countryCode={locationCountryCode} />}
              style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}
            />
          </Form.Item>
          <Form.Item name={formNameFactory('CountryCode')} noStyle>
            <Input value={locationCountryCode} onClick={() => onSelectLocation(null)} style={{width: 50}} />
          </Form.Item>
        </InputGroup>
      </Hide>
      <Hide $hidden={!!locationLabel}>
        <LocationPicker inputSize="middle" onSelect={onSelectLocation} />
      </Hide>
    </>
  );
};

const Hide = styled.div<{$hidden?: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'block')};
`;
