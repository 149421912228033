import {Form, FormInstance, Input, Typography} from 'antd';
import {preventEnterKeySubmit} from '../../../utils/preventEnterKeySubmit';
import {Company} from '../../../redux/ApiService/CompanyService/Company';
import {
  FormSection,
  FormSectionHeader,
  FormSectionBody,
  FormSectionItem,
} from '../../../atoms/FormElements/FormElements';

const {Title} = Typography;

export type VerifyCompanyFormValues = {
  name: string;
  business: string;
  address: {
    name: string;
    street: string;
    streetNumber: string;
    street2: string;
    zip: string;
    city: string;
    country: string;
  };
  contact: {
    website: string;
    email: string;
    phone: string;
    fax: string;
  };
};

export const VerifyCompanyForm = ({form, company}: {form: FormInstance<VerifyCompanyFormValues>; company: Company}) => {
  return (
    <Form<VerifyCompanyFormValues>
      form={form}
      layout="vertical"
      initialValues={company}
      onKeyDown={preventEnterKeySubmit}
      onKeyUp={preventEnterKeySubmit}
      data-testid="VerifyCompanyForm">
      <FormSection>
        <FormSectionHeader>
          <Title level={4}>Main information</Title>
        </FormSectionHeader>
        <FormSectionBody>
          <FormSectionItem name={['name']} label="Company name">
            <Input placeholder="Company name" />
          </FormSectionItem>
          <FormSectionItem name={['business']} label="Business description">
            <Input.TextArea placeholder="Business description" rows={4} />
          </FormSectionItem>
        </FormSectionBody>
      </FormSection>
      <FormSection>
        <FormSectionHeader>
          <Title level={4}>Postal address</Title>
        </FormSectionHeader>
        <FormSectionBody>
          <FormSectionItem name={['address', 'name']} label="Address name">
            <Input placeholder="Address name" />
          </FormSectionItem>
          <FormSectionItem $span={16} name={['address', 'street']} label="Street">
            <Input placeholder="Street" />
          </FormSectionItem>
          <FormSectionItem $span={8} name={['address', 'streetNumber']} label="Street number">
            <Input placeholder="Street number" />
          </FormSectionItem>
          <FormSectionItem name={['address', 'street2']} label="Street 2">
            <Input placeholder="Street 2" />
          </FormSectionItem>
          <FormSectionItem $span={6} name={['address', 'zip']} label="Postal code">
            <Input placeholder="Postal code" />
          </FormSectionItem>
          <FormSectionItem $span={10} name={['address', 'city']} label="City">
            <Input placeholder="City" />
          </FormSectionItem>
          <FormSectionItem $span={8} name={['address', 'country']} label="Country">
            <Input placeholder="Country" />
          </FormSectionItem>
        </FormSectionBody>
      </FormSection>
      <FormSection>
        <FormSectionHeader>
          <Title level={4}>Contact</Title>
        </FormSectionHeader>
        <FormSectionBody>
          <FormSectionItem $span={12} name={['contact', 'website']} label="Website">
            <Input placeholder="Website" type="url" />
          </FormSectionItem>
          <FormSectionItem $span={12} name={['contact', 'email']} label="Email">
            <Input placeholder="Email" type="email" />
          </FormSectionItem>
          <FormSectionItem $span={12} name={['contact', 'phone']} label="Phone">
            <Input placeholder="Phone" type="phone" />
          </FormSectionItem>
          <FormSectionItem $span={12} name={['contact', 'fax']} label="Fax">
            <Input placeholder="Fax" type="phone" />
          </FormSectionItem>
        </FormSectionBody>
      </FormSection>
    </Form>
  );
};
