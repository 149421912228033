import React, {FC, ReactNode, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from '../../../../redux/react-redux';
import CompanyActions, {
  inactiveWorkspaceSelector,
  workspaceSelector,
} from '../../../../redux/ApiService/CompanyService/CompanyService';
import {Button} from '../../../../atoms/Button/Button';
import {ModalActions} from '../../../../redux/Modal';
import {CompanyWorkspace as CompanyWorkspaceType} from '../../../../api/symfony/generated/models/CompanyWorkspace';
import {SupportAgentsBar} from '../../../../components/SupportAgentsBar/SupportAgentsBar';
import {useMutation} from '@tanstack/react-query';
import {workspaceApi} from '../../../../api/symfony/symfonyApi';
import {useCompanyMembersQuery} from '../../../../queries/company/useCompanyMembersQuery';
import {SmartInboxInstructions} from './SmartInboxInstructions';
import {WorkspaceSettingsListDisabled} from './WorkspaceSettingsListDisabled';
import {WorkspaceSettingsListActive} from './WorkspaceSettingsListActive';
import {InstructionsSeparator, Loading} from './shared';
import {Company} from '../../../../redux/ApiService/CompanyService/Company';
import {EasySharingInstructions} from './EasySharingInstructions';
import {scrollIntoViewWithHeaderOffset} from '../../../../utils/scrollIntoViewWithOffset';
import {FeatureToggles} from '../../../../utils/FeatureToggles';
import CreateWorkspace from '../../../../components/Modal/CreateWorkspace';
import {AddCompanyMembersToWorkspaceModal} from '../../../../components/Modal/AddCompanyMembersToWorkspaceModal/AddCompanyMembersToWorkspaceModal';
import {SettingsCardHeader} from '../../../../components/SettingsCard/SettingsCardHeader';
import {SettingsCard} from '../../../../components/SettingsCard/SettingsCard';
import {SettingsCardBody} from '../../../../components/SettingsCard/SettingsCardBody';

interface CompanySettingsWorkspaceProps {
  company: Company;
}

export const CompanySettingsWorkspace: FC<CompanySettingsWorkspaceProps> = ({company}) => {
  const companyId = company.id;
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const workspaces: CompanyWorkspaceType[] = useSelector(state => workspaceSelector(state));
  const inactiveWorkspaces: CompanyWorkspaceType[] = useSelector(state => inactiveWorkspaceSelector(state));
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  const getMembers = (id: number) => dispatch(CompanyActions.getMembers(id));
  const getWorkspaceList = (id: number) => dispatch(CompanyActions.getWorkspaceList(id));
  const getCompany = (id: number) => dispatch(CompanyActions.getCompany(id));

  const easySharingInstructionsRef = useRef<HTMLDivElement>(null);
  const [easySharingInstructionsOpen, setEasySharingInstructionsOpen] = useState<boolean>(false);
  const handleEasySharingInfoClick = () => {
    setEasySharingInstructionsOpen(true);
    scrollIntoViewWithHeaderOffset(easySharingInstructionsRef.current!);
  };

  const supportRef = useRef<HTMLDivElement>(null);
  const handleSmartInboxSupportLinkClick = () => {
    scrollIntoViewWithHeaderOffset(supportRef.current!);
  };

  const membersQuery = useCompanyMembersQuery();
  const members = membersQuery.data!;

  const editWorkspace = (id: number, workspaceID: number, body: CompanyWorkspaceType) =>
    dispatch(CompanyActions.editWorkspace(id, workspaceID, body));

  const setDefaultWorkspaceMutation = useMutation({
    mutationFn: ({workspaceId}: {workspaceId: number}) => {
      return workspaceApi.setWorkspaceAsDefaultWorkspace({
        id: workspaceId,
      });
    },
    onSuccess: async () => {
      await getWorkspaceList(companyId);
    },
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getCompany(companyId);

    getMembers(companyId);
    getWorkspaceList(companyId).then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const handleWorkspaceEdit = (id: number) => {
    const editWorkspaceData = workspaces.find(workspace => {
      return workspace.id === id;
    });
    showModal(<CreateWorkspace method="edit" editWorkspaceData={editWorkspaceData!} close={() => {}} />);
  };

  const addMembers = (id: number) => {
    const editWorkspaceData = workspaces.find(workspace => {
      return workspace.id === id;
    });
    showModal(
      <AddCompanyMembersToWorkspaceModal editWorkspaceData={editWorkspaceData!} members={members} close={() => {}} />
    );
  };

  const handleWorkspaceInactive = (id: number) => {
    const foundWorkspace = workspaces.find((workspace: CompanyWorkspaceType) => workspace.id === id);
    const foundInactiveWorkspace = inactiveWorkspaces.find((workspace: CompanyWorkspaceType) => workspace.id === id);

    const editWorkspaceData = foundWorkspace ?? foundInactiveWorkspace;

    editWorkspace(companyId, id, {...editWorkspaceData!, isDisabled: !editWorkspaceData!.isDisabled}).then(() =>
      getWorkspaceList(companyId)
    );
  };

  if (!membersQuery.isSuccess) {
    return <Loading isLoading={true} />;
  }

  return (
    <>
      <SettingsCard>
        <SettingsCardHeader title={'Workspaces'}>
          {({onClick: _}) => (
            <Button
              label={'add workspace'}
              bold
              primary
              upper
              onClick={() => {
                showModal(<CreateWorkspace method="add" />);
              }}
            />
          )}
        </SettingsCardHeader>
        <div>
          {FeatureToggles.easySharing && (
            <EasySharingInstructions
              open={easySharingInstructionsOpen}
              containerRef={easySharingInstructionsRef}
              onClose={() => {
                setEasySharingInstructionsOpen(false);
              }}
            />
          )}
          <SmartInboxInstructions
            open={!FeatureToggles.easySharing}
            onSupportLinkClick={handleSmartInboxSupportLinkClick}
          />
          <InstructionsSeparator />
        </div>
        <Loading isLoading={loading || setDefaultWorkspaceMutation.isPending}>
          <SettingsCardBody>
            <WorkspaceSettingsListActive
              onChangeWorkspace={() => {
                getWorkspaceList(companyId);
              }}
              onDefaultWorkspaceSelected={workspaceId => {
                setDefaultWorkspaceMutation.mutate({
                  workspaceId,
                });
              }}
              workspaces={workspaces}
              handleWorkspaceEdit={handleWorkspaceEdit}
              addMembers={addMembers}
              handleWorkspaceInactive={handleWorkspaceInactive}
              inactiveWorkspaces={inactiveWorkspaces}
              showModal={showModal}
              onEasySharingInfoClick={handleEasySharingInfoClick}
            />
          </SettingsCardBody>
        </Loading>
      </SettingsCard>
      {inactiveWorkspaces.length > 0 && (
        <>
          <div style={{height: 20}}></div>
          <SettingsCard>
            <SettingsCardHeader title={'Inactive workspaces'} />
            <SettingsCardBody>
              <WorkspaceSettingsListDisabled
                loading={loading}
                inactiveWorkspaces={inactiveWorkspaces}
                handleWorkspaceEdit={handleWorkspaceEdit}
                addMembers={addMembers}
                handleWorkspaceInactive={handleWorkspaceInactive}
              />
              <SupportAgentsBar isSmall scrollRef={supportRef} animate={true} />
            </SettingsCardBody>
          </SettingsCard>
        </>
      )}
    </>
  );
};
