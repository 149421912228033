/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  GeocodingAreaType,
  GeocodingAreaTypeFromJSON,
  GeocodingAreaTypeFromJSONTyped,
  GeocodingAreaTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface GeocodingResult
 */
export interface GeocodingResult {
  /**
   *
   * @type {GeocodingAreaType}
   * @memberof GeocodingResult
   */
  type: GeocodingAreaType;
  /**
   *
   * @type {string}
   * @memberof GeocodingResult
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof GeocodingResult
   */
  latitude: number;
  /**
   *
   * @type {number}
   * @memberof GeocodingResult
   */
  longitude: number;
  /**
   *
   * @type {string}
   * @memberof GeocodingResult
   */
  code: string;
  /**
   *
   * @type {number}
   * @memberof GeocodingResult
   */
  id: number | null;
}

export function GeocodingResultFromJSON(json: any): GeocodingResult {
  return GeocodingResultFromJSONTyped(json, false);
}

export function GeocodingResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeocodingResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: GeocodingAreaTypeFromJSON(json['type']),
    name: json['name'],
    latitude: json['latitude'],
    longitude: json['longitude'],
    code: json['code'],
    id: json['id'],
  };
}

export function GeocodingResultToJSON(value?: GeocodingResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: GeocodingAreaTypeToJSON(value.type),
    name: value.name,
    latitude: value.latitude,
    longitude: value.longitude,
    code: value.code,
    id: value.id,
  };
}
