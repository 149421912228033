import {Card, Col, Form, Row} from 'antd';
import {useState} from 'react';
import styled from 'styled-components';
import {LoadingIndicator, LoadingText} from '../../atoms/Loading';
import DistanceCalculatorMap from '../../components/DistanceCalculatorMap';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {TODO} from '../../utils/TODO';
import {CalculationResults} from './CalculatorMap/CalculationResults';
import {DistanceCalculatorFormValues} from './CalculatorSettings/DistanceCalculatorFormValues';
import {CalculationError} from './CalculatorMap/CalculationError';
import {useRouteCalculationMutation} from './useRouteCalculationMutation';
import {DistanceCalculationTourDefinition} from './DistanceCalculationTourDefinition';
import {convertTimeToTimezone} from './utils';
import {CalculatorSettings} from './CalculatorSettings/CalculatorSettings';

export const DistanceCalculationScreen = () => {
  const [form] = Form.useForm<DistanceCalculatorFormValues>();
  const [routes, setRoutes] = useState<TODO[]>([]);

  const stations = Form.useWatch('stations', form) ?? [];

  const routeCalculationMutation = useRouteCalculationMutation({
    onSuccess: result => {
      setRoutes(result.routes);
    },
  });

  const isCalculating = routeCalculationMutation.isPending;
  const errors = routeCalculationMutation.data?.hasError ?? [];
  const errorMessage = routeCalculationMutation.data?.errorMessage;

  const seca = routeCalculationMutation.data?.seca ?? null;
  const piracy = routeCalculationMutation.data?.piracy ?? null;
  const duration = routeCalculationMutation.data?.duration ?? null;
  const distance = routeCalculationMutation.data?.distance ?? null;
  const resultSpeed = routeCalculationMutation.data?.speed ?? null;
  const resultETD = convertTimeToTimezone(routeCalculationMutation.data?.ETD, (stations[0] as TODO)?.timezone || 'UTC');
  const resultETA = convertTimeToTimezone(
    routeCalculationMutation.data?.ETA,
    (stations[stations.length - 1] as TODO)?.timezone || 'UTC'
  );

  // filter fake ids (< 0) from stations for map
  const mapStations = stations.map(station => {
    if ((station?.id || -1) < 0) {
      return null;
    }
    return station;
  });

  return (
    <>
      <ScreenHeader
        title="Distance calculator"
        breadcrumbs={[{title: 'Tools'}, {title: 'Distance calculator'}]}
        tourDefinition={DistanceCalculationTourDefinition}
      />
      <ContentContainer data-testid="DistanceCalculatorScreen">
        <Row>
          <Col span={10} style={{minWidth: 600, maxWidth: 800}}>
            <CalculatorSettings
              form={form}
              routes={routes}
              resultETD={resultETD}
              resultETA={resultETA}
              resultSpeed={resultSpeed}
              isCalculating={isCalculating}
              onFinish={routeCalculationMutation.mutate}
              onReset={routeCalculationMutation.reset}
            />
          </Col>
          <Col flex={1}>
            {isCalculating && (
              <LoadingContainer>
                <LoadingIndicator color={'var(--color-white)'} />
                <LoadingText text={'Calculating'} medium />
              </LoadingContainer>
            )}
            <DistanceCalculatorMap stations={mapStations} routes={routes}>
              {errors.length > 0 ? (
                <CalculationError stations={mapStations} errors={errors} errorMessage={errorMessage} />
              ) : (
                <CalculationResults
                  seca={seca}
                  piracy={piracy}
                  eta={resultETA}
                  etd={resultETD}
                  speed={resultSpeed}
                  distance={distance}
                  duration={duration}
                />
              )}
            </DistanceCalculatorMap>
          </Col>
        </Row>
      </ContentContainer>
    </>
  );
};

const ContentContainer = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.2);
`;
