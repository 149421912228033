import {FC} from 'react';
import {SidebarControls} from './SidebarControls';
import {getSidebarMode} from '../../../redux/selectors';
import {useSelector} from '../../../redux/react-redux';
import {WorkspaceSelect} from '../../WorkspaceSelect/WorkspaceSelect';
import {useWorkspacesQuery} from '../../../queries/useWorkspacesQuery';
import {CompanyWorkspace} from '../../../api/symfony/generated';
import {UserMenu} from './UserMenu';
import styled from 'styled-components';

export const SidebarLowerAreaContent: FC = () => {
  const sidebarMode = useSelector(getSidebarMode);
  const companyWorkspacesQuery = useWorkspacesQuery();
  const companyWorkspaces: CompanyWorkspace[] = companyWorkspacesQuery.isSuccess ? companyWorkspacesQuery.data : [];
  const hasCompanyWorkspaces = companyWorkspaces.length > 0;
  const workspaceSelectSize = sidebarMode === 'wide' ? 'wide' : 'narrow';

  return (
    <>
      {hasCompanyWorkspaces && (
        <Container>
          <WorkspaceSelect size={workspaceSelectSize} />
          <UserMenu size={workspaceSelectSize} />
        </Container>
      )}
      <SidebarControls mode={sidebarMode} />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;
  padding: 8px 0 16px;
`;
