import {FC} from 'react';
import {NotificationMenuDumb} from './NotificationMenuDumb';
import {useNotificationsQuery} from '../../../../queries/useNotificationsQuery';
import {Notification} from '../../../../api/symfony/generated/models/Notification';
import {useGoToUrl} from '../../../../utils/useChangeBrowserUrl';
import {
  useAcknowledgeNotificationMutation,
  useAcknowledgeAllNotificationsMutation,
} from './useAcknowledgeNotificationsMutation';
import {useRingingBellAnimation} from './useRingingBellAnimation';
import {getNotificationClickTarget} from './getNotificationClickTarget';
import {useReloadPageSoft} from '../../../../utils/useReloadPage';
import {notificationShownInDropdown} from './NotificationDropdownContent';

/**
 * The notifications button displayed in the header.
 * This is the "smart" component that handle events and talks to the API.
 * The "dumb" component visualizes and generates events.
 */
export const NotificationMenu: FC = () => {
  const goToUrl = useGoToUrl();
  const reloadPageSoft = useReloadPageSoft();
  const notificationsQuery = useNotificationsQuery({
    pageNumber: 1,

    pageSize: notificationShownInDropdown + 1, // 11 because we want to show 10 notifications but we need to know if there are more
    acknowledged: false,
  });
  const notifications = notificationsQuery.isSuccess ? notificationsQuery.data.items : [];
  const markAsReadMutation = useAcknowledgeNotificationMutation();
  const markAllAsReadMutation = useAcknowledgeAllNotificationsMutation();
  const ringingBellAnimation: boolean = useRingingBellAnimation(notifications);

  const onClick = (notification: Notification) => {
    const link = getNotificationClickTarget(notification);
    if (link) {
      const isSameUrl = link.startsWith('/') && window.location.pathname === link;
      if (isSameUrl) {
        reloadPageSoft();
      } else {
        goToUrl(link);
      }
    }
  };

  const onMarkAsRead = (notification: Notification) => {
    markAsReadMutation.mutate(notification.id);
  };

  const onMarkAllAsRead = () => {
    markAllAsReadMutation.mutate();
  };

  const onSettings = () => {
    goToUrl('/scf/notification-settings/');
  };

  const onSeeAll = () => {
    goToUrl('/notifications');
  };

  return (
    <NotificationMenuDumb
      unreadCount={notificationsQuery.data?.unreadItems ?? 0}
      loading={notificationsQuery.isLoading}
      ringingBellAnimation={ringingBellAnimation}
      notifications={notifications}
      onClick={onClick}
      onMarkAsRead={onMarkAsRead}
      onMarkAllAsRead={onMarkAllAsRead}
      onSettings={onSettings}
      onSeeAll={onSeeAll}
    />
  );
};
