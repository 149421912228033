import {decodeJwt} from 'jose';
import dayjs, {Dayjs} from 'dayjs';
import {getRefreshTokenExpiration} from './utils';

// The expiration date of the access token can be overridden for testing purposes.
let overriddenExpirationDateForTesting: Dayjs | undefined = undefined;

const getIsTokenExpired = (expirationDate?: Dayjs | null): boolean => {
  if (!expirationDate) {
    return false;
  }
  return (overriddenExpirationDateForTesting ?? expirationDate).isBefore(dayjs());
};

/**
 * Returns true if the token is expired.
 * NOTE: Could be overridden manually via `expireAccessTokenForTesting()`.
 */
export const getIsRefreshTokenExpired = (): boolean => {
  const expirationDate = getRefreshTokenExpiration();
  return getIsTokenExpired(expirationDate);
};

/**
 * Returns true if the token is expired.
 * NOTE: Could be overridden manually via `expireAccessTokenForTesting()`.
 */
export const getIsAccessTokenExpired = (tokenString?: string | null): boolean => {
  if (!tokenString) {
    return false;
  }
  const token = decodeJwt(tokenString);
  const expirationDate = dayjs(token.exp! * 1000);
  return getIsTokenExpired(expirationDate);
};
/**
 * Overrides the expiration date of the access token for testing purposes.
 * Can be used in manual testing to simulate an expired access token.
 * - Take your computer offline
 * - In the JS console call `expireAccessTokenForTesting()` to expire the access token.
 * - Take your computer online
 * - The app should reload.
 */
const expireAccessTokenForTesting = () => {
  overriddenExpirationDateForTesting = dayjs().subtract(1, 'hours');
  // eslint-disable-next-line no-console
  console.log('🔥 Overrode access token expiration date for testing:', overriddenExpirationDateForTesting.toString());
};

window.expireAccessTokenForTesting = expireAccessTokenForTesting;
