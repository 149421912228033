import {useSelector} from '../../../redux/react-redux';
import {useSyncSidebarModeToLocalStorage} from './sidebarModePersistence';
import {getSidebarMode} from '../../../redux/selectors';
import {SidebarLowerAreaContent} from './SidebarLowerAreaContent';
import {RevealSidebarButton} from './SidebarControls';
import {HeaderLogo} from '../HeaderLogo';
import styled, {createGlobalStyle} from 'styled-components';
import {SearchBar} from '../../../components/SearchBar/SearchBar';
import {SidebarMenu} from './SidebarMenu';

export const Sidebar = () => {
  useSyncSidebarModeToLocalStorage();
  const sidebarMode = useSelector(getSidebarMode);

  if (sidebarMode === 'hidden') {
    return <RevealSidebarButton />;
  }

  return (
    <>
      <Container data-tour-id="Sidebar" data-testid="Sidebar" data-sidebar-mode={sidebarMode}>
        <Header>
          <HeaderLogo />
        </Header>
        <SearchArea>
          <SearchBar />
        </SearchArea>
        <UpperArea>
          <SidebarMenu mode={sidebarMode} />
        </UpperArea>
        <LowerArea>
          <SidebarLowerAreaContent />
        </LowerArea>
      </Container>
      <DropdownInHeaderStyle />
    </>
  );
};

const DropdownInHeaderStyle = createGlobalStyle`
  .ant-dropdown, .ant-select-dropdown {
    &.fixed {
      position: fixed;
    }
  }
`;

const Container = styled.nav`
  --sidebar-transition-duration: 0.1s;

  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: var(--sidebar-width); // Set in FullLayout.tsx
  background-color: var(--color-sidebar-background);
  transition: width var(--sidebar-transition-duration);
  z-index: 100;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: var(--header-height);
  padding: 0 16px;
  margin-bottom: 8px;
`;

const SearchArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 16px;
`;

const UpperArea = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  margin: 0 0 auto;
  padding: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: #fefefe6c transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: transparent;
    &:hover {
      all: inherit;
    }
  }
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    &:hover {
      all: inherit;
    }
  }
  &::-webkit-scrollbar-thumb:vertical {
    background-color: #fefefe6c;
    border-radius: 10px;
    box-shadow: inset 0 0 6px #fefefe6c;
  }
  &::-webkit-scrollbar {
    width: 7px;
    background: transparent;
    &:hover {
      all: inherit;
    }
  }
`;

const LowerArea = styled.div`
  position: relative;
  flex: 0 0 auto;
  height: auto;
  width: 100%;
  margin: 0 8px;
  padding: 8px;
  margin: auto 0 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 8px;
    right: 8px;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
  }
`;
