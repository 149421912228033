import {MenuItem} from '../../atoms/Menu/MenuItem';
import {CargoDetailHeader} from './CargoDetailHeader/CargoDetailHeader';
import {CargoDetailBody} from './CargoDetailBody/CargoDetailBody';
import ShareHistory from '../../components/ShareHistory';
import {useOpenCargoForm} from '../CargoVesselForm/utils/useOpenCargoForm';
import {BreadcrumbItem} from '../../atoms/Breadcrumb/BreadcrumbItem';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {TBNString} from '../../components/Placeholder/Placeholder';

type Props = {
  cargo?: $TSFixMe;
  currentUser?: $TSFixMe;
  generalInfoColumns?: $TSFixMe;
  commoditySection?: $TSFixMe;
  quantitySection?: $TSFixMe;
  commissionSection?: $TSFixMe;
  vesselFeatures?: $TSFixMe;
  breadcrumbs: BreadcrumbItem[];
  handleProjectClick?: $TSFixMe;
  handleCloneClick?: $TSFixMe;
  handleEditClick?: $TSFixMe;
  handleDeleteClick?: $TSFixMe;
  handleCreateFixtureClick?: $TSFixMe;
};

export const MyCargoesCargo = ({
  cargo,
  generalInfoColumns,
  commoditySection,
  quantitySection,
  commissionSection,
  vesselFeatures,
  breadcrumbs,
  handleProjectClick,
  handleCloneClick,
  handleCreateFixtureClick,
  handleDeleteClick,
}: Props) => {
  const openCargoForm = useOpenCargoForm();

  return (
    <>
      <ScreenHeader
        title={cargo.chartererName ? cargo.chartererName : TBNString}
        breadcrumbs={breadcrumbs}
        actions={
          <ScreenHeader.Actions.Menu
            position={'right'}
            items={[
              {
                key: 'edit-cargo',
                isValid: !cargo.isArchived,
                label: (
                  <MenuItem
                    onClick={() => {
                      openCargoForm({id: cargo.id});
                    }}
                    label={'Edit cargo'}
                    withDivider
                    dataCy={'EditCargo'}
                  />
                ),
              },
              {
                key: 'delete',
                isValid: !cargo.isArchived,
                label: <MenuItem onClick={() => handleDeleteClick()} label={'Delete cargo'} dataCy={'DeleteCargo'} />,
              },
              {
                key: 'clone-cargo',
                isValid: !cargo.isArchived,
                label: (
                  <MenuItem onClick={() => handleCloneClick('portfolio')} label={'Clone cargo'} dataCy={'CloneCargo'} />
                ),
              },
              {
                key: 'add-to-project',
                isValid: !cargo.isArchived,
                label: (
                  <MenuItem
                    onClick={() => handleProjectClick('portfolio')}
                    label={'Add to project'}
                    withDivider
                    dataCy={'AddToProject'}
                  />
                ),
              },
              {
                key: 'create-fixture',
                isValid: !cargo.isArchived,
                label: (
                  <MenuItem
                    onClick={() => handleCreateFixtureClick()}
                    label={'Create fixture'}
                    withDivider
                    dataCy={'CreateFixture'}
                  />
                ),
              },
            ]}
          />
        }
      />
      <CargoDetailHeader cargo={cargo} />
      {cargo.relatedOffers.length > 0 && (
        <ShareHistory type={'cargo'} history={cargo.relatedOffers} style={{marginTop: '30px'}} />
      )}
      <CargoDetailBody
        type={cargo.contractType}
        cargo={cargo}
        generalInfoColumns={generalInfoColumns}
        commoditySection={commoditySection}
        quantitySection={quantitySection}
        commissionSection={commissionSection}
        vesselFeatures={vesselFeatures}
      />
    </>
  );
};
