import {FC} from 'react';
import {BreadcrumbItem} from '../../atoms/Breadcrumb/BreadcrumbItem';
import OfferDetails from '../../components/OfferDetails';
import {TBNString} from '../../components/Placeholder/Placeholder';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {CargoDetailBody} from './CargoDetailBody/CargoDetailBody';
import {CargoDetailHeader} from './CargoDetailHeader/CargoDetailHeader';
import {useUser} from '../../components/UserContext/useUser';

type Props = {
  cargo?: $TSFixMe;
  generalInfoColumns?: $TSFixMe;
  commoditySection?: $TSFixMe;
  quantitySection?: $TSFixMe;
  commissionSection?: $TSFixMe;
  vesselFeatures?: $TSFixMe;
  breadcrumbs: BreadcrumbItem[];
  handleProjectClick?: $TSFixMe;
  handleShareClick?: $TSFixMe;
  handleCloneClick?: $TSFixMe;
  handleCreateFixtureClick?: $TSFixMe;
};

export const MarketCargo: FC<Props> = ({
  cargo,
  generalInfoColumns,
  commoditySection,
  quantitySection,
  commissionSection,
  vesselFeatures,
  breadcrumbs,
  handleProjectClick,
  handleCloneClick,
  handleCreateFixtureClick,
}) => {
  const currentUser = useUser();

  return (
    <>
      <ScreenHeader
        title={cargo.chartererName ? cargo.chartererName : TBNString}
        breadcrumbs={breadcrumbs}
        actions={
          <>
            <ScreenHeader.Actions.Button onClick={() => handleCloneClick('portfolio')} data-cy="CloneCargo">
              Clone Cargo
            </ScreenHeader.Actions.Button>
            <ScreenHeader.Actions.Button onClick={() => handleProjectClick('portfolio')} data-cy="AddToProject">
              Add to project
            </ScreenHeader.Actions.Button>
            <ScreenHeader.Actions.Button onClick={() => handleCreateFixtureClick()} data-cy="CreateFixture">
              Create fixture
            </ScreenHeader.Actions.Button>
          </>
        }
      />
      {cargo.offer && (
        <OfferDetails offer={cargo.offer} isMe={cargo.offer.user && cargo.offer.user.id === currentUser?.id} />
      )}
      <CargoDetailHeader cargo={cargo} />
      <CargoDetailBody
        type={cargo.contractType}
        cargo={cargo}
        generalInfoColumns={generalInfoColumns}
        commoditySection={commoditySection}
        quantitySection={quantitySection}
        commissionSection={commissionSection}
        vesselFeatures={vesselFeatures}
      />
    </>
  );
};
