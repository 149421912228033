import {App} from './App';
import {initSentry} from './components/Sentry/SentrySetup';
import {setupFetch} from './utils/setupFetch';
import {createRoot} from 'react-dom/client';
import {initPiwik} from './components/PiwikManager/PiwikSetup';
import 'antd/dist/reset.css';
import './index.scss';

setupFetch();
initSentry();
initPiwik();

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('DOM element #root not found');
}
const root = createRoot(rootElement);
root.render(<App />);
