import {FC} from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {useSelector} from '../../../redux/react-redux';
import {getSidebarMode} from '../../../redux/selectors';
import {SidebarMode} from '../../../redux/Sidebar';

export const PromoBanner: FC = () => {
  const sidebarMode = useSelector(getSidebarMode);
  return (
    <PromoLink to={`/subscription`} $sidebarMode={sidebarMode}>
      <PromoClaim>Get full access to seabo</PromoClaim>
      <PromoCTASmall>Upgrade</PromoCTASmall>
      <PromoCTA>Upgrade to Pro</PromoCTA>
    </PromoLink>
  );
};

const PromoCTA = styled.p`
  width: 100%;
  margin: 0;
  padding: 2px;
  background: var(--color-upgrade);
  border: 0;
  color: var(--color-white);
  font-weight: bold;
  text-transform: uppercase;
`;

const PromoCTASmall = styled(PromoCTA)`
  display: none;
  padding: 2px;
  line-height: 1.125;
`;

const PromoClaim = styled.p`
  margin: 0;
  padding: 2px;
  background: var(--color-white);
  color: var(--color-azure);
`;

const PromoLink = styled(Link)<{$sidebarMode: SidebarMode}>`
  display: block;
  height: 48px;
  margin: 10px;
  width: calc(100% - 20px);
  background: var(--color-upgrade);
  border: 2px solid var(--color-upgrade);
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-button);
  font-size: var(--font-size-sm);
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;

  transition:
    opacity 0.1s,
    height 0.1s;

  &:hover {
    opacity: 0.8;
  }

  ${({$sidebarMode}) =>
    $sidebarMode === 'narrow' &&
    css`
      height: 19px;
      width: 60px;
      margin: 10px auto;
      font-size: var(--font-size-xs);

      ${PromoClaim}, ${PromoCTA} {
        display: none;
      }
      ${PromoCTASmall} {
        display: block;
      }
    `}
`;
