import styled from 'styled-components';
import {useSelector} from '../../../redux/react-redux';
import {useLocation} from 'react-router-dom';
import {refetchCurrentCompany} from '../../../components/UserContext/CompanyLoader';
import {getCompanyState} from '../../../redux/selectors';
import {Button, Card, Form} from 'antd';
import {
  VerifyCompanyForm,
  VerifyCompanyFormValues,
} from '../../../components/Modal/VerifyCompanyForm/VerifyCompanyForm';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {useVerifyCompanyMutation} from '../../../queries/company/useVerifyCompanyMutation';

const regexForCompanySettings = /^\/company\/\d+\/settings/;

export const VerifyCompanyScreen = () => {
  const location = useLocation();
  const screenName = regexForCompanySettings.test(location.pathname) ? 'company administration' : 'company profile';

  const companyState = useSelector(getCompanyState);
  const {data: company, error} = companyState;

  const [form] = Form.useForm<VerifyCompanyFormValues>();

  const verifyCompanyMutation = useVerifyCompanyMutation();
  const verifyCompany = () => {
    verifyCompanyMutation.mutate(
      {id: company.id, verifyCompanyFormValues: form.getFieldsValue()},
      {onSuccess: refetchCurrentCompany}
    );
  };

  const isError = error || verifyCompanyMutation.isError;

  return (
    <>
      <ScreenHeader breadcrumbs={[{title: 'Company'}]} />
      <StyledVerifyCompany>
        <VerifyCompanyHeadline>
          <Header>Please check your company information</Header>
          <SubHeader>We have prefilled your information. Please check and complete it now.</SubHeader>
        </VerifyCompanyHeadline>
        {company && <VerifyCompanyForm form={form} company={company} />}
        {isError && (
          <StyledError>
            Something went wrong, please reload the site or <a href="https://www.seabo.com/contact">contact us</a>
          </StyledError>
        )}
        <ButtonsContainer>
          <Button type="primary" onClick={verifyCompany} disabled={verifyCompanyMutation.isPending}>
            Verify and proceed to {screenName}
          </Button>
        </ButtonsContainer>
      </StyledVerifyCompany>
    </>
  );
};

const StyledVerifyCompany = styled(Card)`
  position: relative;
  min-width: 1024px;
  max-width: 1200px;
  margin: 0 auto;

  .ant-card-body {
    padding: 30px 40px 40px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
`;

const VerifyCompanyHeadline = styled.div`
  margin-bottom: 40px;
`;

const Header = styled.div`
  font-size: var(--font-size-2xl);
  font-weight: bold;
`;

const SubHeader = styled.div`
  font-size: var(--font-size-lg);
  color: var(--color-gray-2);
`;

const StyledError = styled.div`
  color: var(--color-red);
`;
