import styled, {css} from 'styled-components';
import {CSSProperties, FC} from 'react';
import {useSelector} from '../../../redux/react-redux';
import {getSidebarMode} from '../../../redux/selectors';
import {Tooltip} from 'antd';
import {LabelBadge} from '../../LabelBadge';
import {SidebarMode} from '../../../redux/Sidebar';

export const Label: FC<{
  icon?: JSX.Element;
  badge?: string;
  style?: CSSProperties;
  badgeStyle?: CSSProperties;
  text: string;
  isBetaFeature: boolean;
  dataTourId?: string;
  enableUpgradeBadge: boolean;
  onClick?: () => void;
}> = ({icon, style, badgeStyle, isBetaFeature, enableUpgradeBadge, badge, text, dataTourId, onClick}) => {
  const sidebarMode = useSelector(getSidebarMode);
  if (sidebarMode === 'narrow') {
    if (!icon) {
      return null;
    }
    return (
      <Tooltip title={text} placement="right">
        <LabelStyled $sidebarMode={sidebarMode} style={style} data-tour-id={dataTourId} onClick={onClick}>
          <IconWrapper>{icon}</IconWrapper>
        </LabelStyled>
      </Tooltip>
    );
  }

  return (
    <LabelStyled $sidebarMode={sidebarMode} style={style} onClick={onClick}>
      {icon ? (
        <>
          <IconWrapper>{icon}</IconWrapper>
          <Spacer width={'12px'} />
        </>
      ) : (
        // This Spacer aligns a sub menu item with a sub menu's title.
        <Spacer width="21px" />
      )}
      <LabelText>{text}</LabelText>
      <BetaLabel isBetaFeature={isBetaFeature} enableUpgradeBadge={enableUpgradeBadge} />
      {!!badge && enableUpgradeBadge && <LabelBadge style={badgeStyle}>{badge}</LabelBadge>}
    </LabelStyled>
  );
};

/**
 * This component is used to display a BETA badge on a label.
 * It is only displayed when the feature is in beta and the upgrade badge is not enabled, because of the space constraints.
 */
const BetaLabel = ({enableUpgradeBadge, isBetaFeature}: {isBetaFeature: boolean; enableUpgradeBadge: boolean}) => {
  if (enableUpgradeBadge) {
    return null;
  }
  if (!isBetaFeature) {
    return null;
  }
  return <LabelBadge style={{}}>BETA</LabelBadge>;
};

const LabelText = styled.div`
  display: inline-block;
  height: 24px;
  line-height: 24px;
  font-size: var(--font-size-md);
  color: var(--color-gray-5-fixed);
`;

const LabelStyled = styled.div<{$sidebarMode: SidebarMode}>`
  display: flex;
  height: 40px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${({$sidebarMode}) =>
    $sidebarMode === 'narrow' &&
    css`
      margin-left: -5px;

      .icon {
        font-size: var(--font-size-xl) !important;
        transition: font-size 0.1s;
      }
    `}
`;
const IconWrapper = styled.div`
  text-align: center;
  width: 16px;
  color: var(--color-gray-5-fixed);
`;

const Spacer: FC<{width?: string; height?: string}> = ({width = '1px', height = '1px'}) => (
  <SpacerStyled style={{width, height}} />
);

const SpacerStyled = styled.span`
  display: inline-block;
`;
