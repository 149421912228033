import {FC} from 'react';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {FeaturePermission} from '../../../api/symfony/generated';
import {Breadcrumb} from '../../../atoms/Breadcrumb';
import LoadingComponent from '../../../components/DataGrid/LoadingComponent';
import {useGetCompanyQuery} from '../../../queries/company/useCompanyQuery';
import {useIsPermitted} from '../../../utils/useIsPermitted';
import {CompanySettingsActivity} from './CompanySettingsActivity/CompanySettingsActivity';
import {CompanySettingsContact} from './CompanySettingsContact/CompanySettingsContact';
import {CompanySettingsGeneral} from './CompanySettingsGeneral/CompanySettingsGeneral';
import {CompanySettingsUsers} from './CompanySettingsUsers/CompanySettingsUsers';
import {CompanySettingsWorkspace} from './CompanySettingsWorkspace/CompanySettingsWorkspace';
import CompanySettingsTags from './CompanySettingsTags/CompanySettingsTags';
import {Company} from '../../../redux/ApiService/CompanyService/Company';
import CompanySettingsApi from './CompanySettingsApi/CompanySettingsApi';
import {SidebarMenu, SidebarMenuItem, SidebarStyled} from '../../../atoms/SidebarMenu/SidebarMenu';
import {parseIntTS} from '../../../utils/parseNumberTS';

export const CompanySettingsScreens: FC = () => {
  const params = useParams<{id: string}>();
  const id = parseIntTS(params.id);

  const companyQuery = useGetCompanyQuery(id, {
    enabled: !!id,
  });
  const manageOwnCompanyPermitted = useIsPermitted(FeaturePermission.ManageOwnCompany);

  if (!manageOwnCompanyPermitted) {
    return <div>Not permitted</div>;
  }

  if (companyQuery.isLoading) {
    return <LoadingComponent style={{textAlign: 'center'}} />;
  }

  if (!companyQuery.isSuccess) {
    return <div>Error!</div>;
  }

  const company = companyQuery.data;

  return (
    <>
      <Breadcrumb items={[{title: 'Company', href: `/company/${id}`}, {title: 'Administration'}]} />
      <StyledCompanySettings data-testid="CompanySettings">
        <div className="row">
          <div className="scol-2" data-testid="SidebarMenu">
            <SidebarStyled>
              <SidebarMenu>
                <SidebarMenuItem to="general">General</SidebarMenuItem>
                <SidebarMenuItem to="contact">Contact</SidebarMenuItem>
                <SidebarMenuItem to="users">Users</SidebarMenuItem>
                <SidebarMenuItem to="workspace">Workspaces</SidebarMenuItem>
                <SidebarMenuItem to="tags">Tags</SidebarMenuItem>
                <SidebarMenuItem to="activity">Activity</SidebarMenuItem>
                <SidebarMenuItem to="api">API</SidebarMenuItem>
              </SidebarMenu>
            </SidebarStyled>
          </div>
          <FixedWidthLayout>
            <FormArea company={company} />
          </FixedWidthLayout>
        </div>
      </StyledCompanySettings>
    </>
  );
};

const FormArea: FC<{company: Company}> = ({company}) => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="general" replace />} />
      <Route path="/general" element={<CompanySettingsGeneral company={company} />} />
      <Route path="/contact" element={<CompanySettingsContact company={company} />} />
      <Route path="/users" element={<CompanySettingsUsers company={company} />} />
      <Route path="/workspace" element={<CompanySettingsWorkspace company={company} />} />
      <Route path="/activity" element={<CompanySettingsActivity company={company} />} />
      <Route path="/tags" element={<CompanySettingsTags company={company} />} />
      <Route path="/api" element={<CompanySettingsApi company={company} />} />
      <Route element={<div>Not found</div>} />
    </Routes>
  );
};

const FixedWidthLayout = styled.div`
  width: 800px;
  margin: 0 auto;
`;

const StyledCompanySettings = styled.div`
  padding-bottom: 40px;
`;
