import {useState, FC, useEffect} from 'react';
import {PortLocation} from '../../LocationOutput/PortLocation';
import AutoCompleteHeader from '../../SearchBar/AutoCompleteHeader';
import styled, {css} from 'styled-components';
import {AutoComplete, Input} from 'antd';
import Icon from '../../../atoms/Icon';
import {useSearchQuery} from '../../SearchPicker/useSearchQuery';
import LoadingAnimation from '../../../atoms/Loading';
import {AisVesselDisplay} from '../../../atoms/DropdownDisplay/AisVesselDisplay';
import {SearchContainer} from '../../../api/symfony/generated';
import {useDebounce} from '../../../utils/useDebounce';
import {TODO} from '../../../utils/TODO';

const StyledAutoCompleteHeader = styled(AutoCompleteHeader)`
  margin-left: 12px;
  margin-right: 12px;
  font-size: var(--font-size-md);
  border-bottom: 1px solid var(--color-gray-2);
`;

const _getOptions = (a: TODO[], section: string) => {
  return a.map(({index, entry}) => {
    if (index === 'location_port') {
      return {
        label: <PortLocation location={entry} renderAlias />,
        value: `${entry.name} (${entry.countryObject.name}, ${entry.code})`,
        key: section + index + entry.id,

        entry,
      };
    } else if (index === 'ais_vessels') {
      const value = entry.imo ? `${entry.name} (IMO: ${entry.imo})` : entry.name;
      return {
        label: <AisVesselDisplay ais={entry} />,
        value: value,
        key: section + index + entry.imo,
        entry: {
          ...entry,
          latitude: parseFloat(entry.latitude),
          longitude: parseFloat(entry.longitude),
          type: 'ais-vessel',
        },
      };
    } else {
      return null;
    }
  });
};

const prepareData = (response: SearchContainer): Options => {
  const data: TODO = [];
  const results: TODO[] = Object.keys(response.data.items).reduce<TODO[]>((acc, index) => {
    if (response.data.items[index].items.length > 0) {
      acc.push(
        response.data.items[index]?.items.map((item: TODO) => {
          const {highlight, _source, source} = item;
          return {index, entry: {highlight, ..._source, ...source}};
        })
      );
    }
    return acc;
  }, []);

  results.forEach(a => {
    data.push({
      value: a[0].index,
      label: <StyledAutoCompleteHeader indexName={a[0].index} />,
      key: a[0].index,
      options: _getOptions(a, a[0].index as string),
    });
  });

  return data as Options;
};

export type MapSearchEntry = {imo: number; name: string} | {id: number; name: string};

type Options = {
  label: React.ReactElement;
  value: string;
  key: string;

  options: {
    value: string;
    key: string;
    label: React.ReactElement;
    entry: MapSearchEntry;
  }[];
}[];

type MapSearchProps = {
  visible: boolean;
  onSelect: (item: MapSearchEntry | null) => void;
};

export const MapSearch: FC<MapSearchProps> = ({visible, onSelect}) => {
  const [activeContainer, setActiveContainer] = useState<boolean>(false);
  const [autoCompleteIsFocused, setAutoCompleteIsFocused] = useState<boolean>(false);
  const [options, setOptions] = useState<Options>([]);

  const [searchTag, setSearchTag] = useState<string>('');

  const debouncedSearchTag = useDebounce(setSearchTag, [setSearchTag], 500);

  const searchQuery = useSearchQuery({
    searchTag,
    indexNames: 'ais_vessels,location_port',
    options: {
      enabled: searchTag.length > 2,
    },
  });

  useEffect(() => {
    if (searchQuery.isSuccess && searchQuery.data) {
      const preparedData = prepareData(searchQuery.data);
      setOptions(preparedData);
    }
  }, [searchQuery.isSuccess, searchQuery.data, setOptions]);

  const isLoading = searchQuery.isLoading;

  const isActiveContainer = activeContainer || autoCompleteIsFocused;
  return (
    <MapSearchContainer
      $active={isActiveContainer}
      onMouseEnter={() => setActiveContainer(true)}
      onMouseLeave={() => setActiveContainer(false)}
      $visible={visible}>
      <AutoComplete
        dropdownStyle={{minWidth: 320}}
        notFoundContent={
          searchQuery.isSuccess ? (
            <div
              style={{
                padding: '8px 30px',
              }}>
              No options
            </div>
          ) : undefined
        }
        options={options}
        onFocus={() => setAutoCompleteIsFocused(true)}
        onBlur={() => setAutoCompleteIsFocused(false)}
        style={{width: '100%'}}
        onSelect={(_: string, item: TODO) => {
          onSelect(item.entry);
        }}
        onKeyDown={() => onSelect(null)}
        onClear={() => onSelect(null)}
        onSearch={searchTag => debouncedSearchTag(searchTag)}>
        <Input
          allowClear={isActiveContainer && !isLoading}
          suffix={
            <>
              {isLoading && <LoadingAnimation width={40} height={17} />}
              <Icon type={'search'} />
            </>
          }
          placeholder="Search for ports or AIS vessels"
        />
      </AutoComplete>
    </MapSearchContainer>
  );
};

const MapSearchContainer = styled.div<{
  $active: boolean;
  $visible?: boolean;
}>`
  position: absolute;
  left: 12px;
  top: 12px;
  z-index: 30;
  opacity: 0.6;
  width: 108px;

  @container seaboMap (max-height: 100px) {
    display: none;
  }

  transition: width 0.3s;

  ${({$active}) =>
    $active &&
    css`
      width: 320px;
      opacity: 1;
    `}

  @media only screen and (max-width: 1500px) {
    ${({$visible}) =>
      !$visible &&
      css`
        display: none;
      `}
  }

  .ant-input {
    height: 28px;
    box-shadow: none;
    background: none;
    color: var(--color-black-fixed);
  }

  .ant-input-affix-wrapper {
    border-radius: var(--border-radius-button);
    box-shadow: var(--box-shadow-inset-input);
  }
`;
