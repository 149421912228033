import {Col, Form, InputNumber, Row} from 'antd';
import {FC, useEffect, useState} from 'react';
import styled from 'styled-components';
import {ScreenHeader} from '../../ScreenHeader/ScreenHeader';
import {StyledCard} from '../Voyagecharter/components/StyledCard';
import {WidgetTitle} from '../Voyagecharter/components/WidgetTitle';
import {OtherExpenses, OtherExpensesInput} from './OtherExpenses';
import {PageHeaderBox} from './PageHeaderBox';
import {
  timeCharterCalculation,
  TimeCharterCalculationProp,
  TimeCharterCalculationResult,
} from './timeCharterCalculation';
import {FormItem, VesselInformation, VesselInput} from './VesselInformation';
import {VoyageInformation, VoyageInput} from './VoyageInformation';

export const TimeCharterScreen: FC = () => {
  const [showBody, setShowBody] = useState(true);
  const [inputState, setInputState] = useState<TimeCharterCalculationProp>({
    vessel: {},
    voyage: {
      legs: [],
    },
    otherExpenses: {
      items: [],
    },
  });
  const [outputState, setOutputState] = useState<TimeCharterCalculationResult>({
    voyage: {
      legs: [],
    },
    durationInDays: 0,
    netFreight: 0,
    bunkerCosts: 0,
    totalOtherExpenses: 0,
  });

  useEffect(() => {
    setOutputState(timeCharterCalculation(inputState));
  }, [inputState]);

  return (
    <div>
      <ScreenHeader title="Time calculator" breadcrumbs={[{title: 'Tools'}, {title: 'Time calculator'}]} />
      <PageHeaderBox inputState={inputState} outputState={outputState} />
      <Row gutter={[16, 16]} style={{marginBottom: 16}}>
        <Col flex={1} span={8}>
          <TimeCharterSettingsCard
            $showBody={showBody}
            style={{width: '100%', paddingTop: 10}}
            title={
              <WidgetTitle
                icon="vessel-complex"
                title="Vessel information"
                showBody={showBody}
                onToggleBody={() => setShowBody(!showBody)}
              />
            }>
            <VesselInformation
              inputState={inputState}
              outputState={outputState}
              onChange={(obj: VesselInput) => {
                setInputState({...inputState, vessel: obj});
              }}
            />
          </TimeCharterSettingsCard>
        </Col>
        <Col flex={1} span={8}>
          <TimeCharterSettingsCard
            $showBody={showBody}
            style={{width: '100%', paddingTop: 10}}
            title={
              <WidgetTitle
                icon="weather-humidity"
                title="Bunker prices"
                showBody={showBody}
                onToggleBody={() => setShowBody(!showBody)}
              />
            }>
            <Form>
              <FormItem label={'IFO price'}>
                <InputNumber
                  value={inputState.ifoPrice}
                  onChange={v => setInputState({...inputState, ifoPrice: v || undefined})}
                  min={0}
                  addonAfter={'$ / mts'}
                />
              </FormItem>
              <FormItem label={'MDO price'}>
                <InputNumber
                  value={inputState.mdoPrice}
                  onChange={v => setInputState({...inputState, mdoPrice: v || undefined})}
                  min={0}
                  addonAfter={'$ / mts'}
                />
              </FormItem>
            </Form>
          </TimeCharterSettingsCard>
        </Col>
        <Col flex={1} span={8}>
          <TimeCharterSettingsCard
            $showBody={showBody}
            style={{width: '100%', paddingTop: 10}}
            title={
              <WidgetTitle
                icon="donut"
                title="Other expenses"
                showBody={showBody}
                onToggleBody={() => setShowBody(!showBody)}
              />
            }>
            <OtherExpenses
              inputState={inputState}
              outputState={outputState}
              onChange={(obj: OtherExpensesInput) => {
                setInputState({...inputState, otherExpenses: obj});
              }}
            />
          </TimeCharterSettingsCard>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col flex={1} span={24}>
          <VoyageInformation
            inputState={inputState}
            outputState={outputState}
            onChange={(obj: VoyageInput) => {
              setInputState({...inputState, voyage: obj});
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

const TimeCharterSettingsCard = styled(StyledCard)<{$showBody?: boolean}>`
  .ant-card-head-wrapper {
    min-height: unset !important;
  }
`;
