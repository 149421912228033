import {ExperimentFilled} from '@ant-design/icons';
import {FeaturePermission} from '../../../api/symfony/generated';
import {MenuDef, MenuSet} from './MenuDef';
import {SidebarIcon} from './SidebarMenuComponents';
import {ItemType, MenuItemType, SubMenuType} from 'antd/es/menu/interface';
import {FeatureToggles} from '../../../utils/FeatureToggles';
import {TODO} from '../../../utils/TODO';
import {makeMenuItem} from './makeMenuItem';
import {Label} from './Label';

export const upperMenuDef: MenuDef = [
  {
    type: 'item',
    icon: 'dashboard',
    key: 'dashboard',
    label: 'Dashboard',
    url: '/dashboard',
    testId: 'DashboardMenuItem',
    iconTestId: 'DashboardIcon',
  },
  {
    type: 'item',
    icon: 'markets',
    key: 'market',
    label: 'Market',
    url: '/market',
    testId: 'MarketMenuItem',
    tourId: 'MarketMenuItem',
  },
  {
    type: 'subMenu',
    key: 'databases',
    icon: 'database',
    label: 'Databases',
    items: [
      {
        type: 'item',
        key: 'vesselDatabase',
        label: 'Vessels',
        url: '/vessel-database',
        testId: 'VesselDatabaseMenuItem',
      },
      {
        type: 'item',
        key: 'ports',
        label: 'Ports',
        url: '/ports',
        testId: 'PortsMenuItem',
        requiredPermission: FeaturePermission.AccessPorts,
      },
    ],
  },
  {
    type: 'item',
    icon: 'vessel-complex',
    key: 'myFleet',
    label: 'My Fleet',
    url: '/my-fleet',
    tourId: 'MyFleetMenuItem',
    requiredPermission: FeaturePermission.AccessMyFleet,
  },
  {
    type: 'item',
    icon: 'box-open',
    key: 'myCargoes',
    label: 'My Cargoes',
    url: '/my-cargoes',
    tourId: 'MyCargoesMenuItem',
    requiredPermission: FeaturePermission.AccessMyCargoes,
  },
  {
    type: 'item',
    icon: 'work-tray',
    key: 'projects',
    label: 'Projects',
    url: '/projects',
    tourId: 'ProjectsMenuItem',
    requiredPermission: FeaturePermission.AccessProjects,
  },
  {
    type: 'item',
    icon: 'negotiations',
    key: 'negotiations',
    label: 'Negotiations',
    url: '/negotiations',
    tourId: 'Negotiations',
    hidden: !FeatureToggles.negotiations,
    requiredPermission: FeaturePermission.ListNegotiations,
  },
  {type: 'item', key: 'fixtures', label: 'Fixtures', url: '/fixtures', icon: 'news'},
  {
    type: 'subMenu',
    key: 'tools',
    icon: 'tools',
    label: 'Tools',
    items: [
      {
        type: 'item',
        key: 'distance',
        label: 'Distance Calculator',
        url: '/distance-calculator',
        requiredPermission: FeaturePermission.AccessDistanceCalculation,
      },
      {
        type: 'item',
        key: 'charter-calculation-voyage',
        label: 'Voyage Calculator',
        url: '/chartercalculator/voyagecharter',
        requiredPermission: FeaturePermission.AccessVoyageCalculation,
      },
    ],
  },
  {
    type: 'subMenu',
    key: 'analytics',
    icon: 'stock',
    label: 'Analytics',
    items: [
      {
        type: 'item',
        key: 'spotifind',
        label: 'Spotifind',
        url: '/spotifind',
        requiredPermission: FeaturePermission.AccessSpotifind,
      },
      {
        type: 'item',
        requiredPermission: FeaturePermission.AccessCircularStats,
        key: 'circular-stats',
        label: 'Circular Stats',
        url: '/circular-stats',
      },
      {
        type: 'item',
        key: 'potential-competitors',
        label: 'Competitors',
        requiredPermission: FeaturePermission.AccessCompetitorAnalysis,
        url: '/analytics/potential-competitors',
      },
    ],
  },
  {
    type: 'subMenu',
    key: 'lab',
    label: 'Lab',
    icon: <ExperimentFilled />,
    isBetaFeature: true,
    badge: 'Beta',
    items: [
      {
        type: 'item',
        key: 'congestion',
        label: 'Congestion',
        url: '/lab/congestion',
        requiredPermission: FeaturePermission.AccessCongestionAnalysis,
      },
      {
        type: 'item',
        key: 'tied-up-vessels',
        label: 'Tied Up Vessels',
        url: '/lab/tied-up-vessels',
        requiredPermission: FeaturePermission.AccessTiedUpVessels,
      },
    ],
  },
];

export const lowerMenuDef: MenuDef = [
  {type: 'item', key: 'help-center', icon: 'help', label: 'Help and Support', url: '/help-center'},
];

export const menuSet: MenuSet = [upperMenuDef, lowerMenuDef];

export const getMenuItems = (
  onSubMenuClick: TODO,
  userPermissions: FeaturePermission[],
  enablePremiumBadge: boolean
): {
  upperMenuItems: ItemType[];
  lowerMenuItems: ItemType[];
} => ({
  upperMenuItems: getMenuItemsFromMenuDef(upperMenuDef, onSubMenuClick, userPermissions, enablePremiumBadge),
  lowerMenuItems: getMenuItemsFromMenuDef(lowerMenuDef, onSubMenuClick, userPermissions, enablePremiumBadge),
});

export const getMenuItemsFromMenuDef = (
  menuDef: MenuDef,
  onSubMenuClick: TODO,
  userPermissions: FeaturePermission[],
  enableUpgradeBadge: boolean
): ItemType[] => {
  const items: ItemType[] = [];
  menuDef.forEach(item => {
    const icon =
      typeof item.icon === 'string' ? <SidebarIcon name={item.icon} dataTestId={item.iconTestId} /> : item.icon;
    if (item.type === 'item') {
      if (item.hidden) {
        return;
      }

      const hasPermission = !item.requiredPermission || userPermissions.includes(item.requiredPermission);

      if (!hasPermission && item.hideIfNotPermitted) {
        return;
      }

      const menuItem: MenuItemType = makeMenuItem(item, hasPermission, icon, enableUpgradeBadge);
      items.push(menuItem);
    } else if (item.type === 'subMenu') {
      const subMenuItem: SubMenuType = {
        key: item.key,
        style: {
          marginLeft: -8,
          width: 'calc(100% + 16px)',
        },
        label: (
          <Label
            enableUpgradeBadge={enableUpgradeBadge}
            text={item.label}
            isBetaFeature={item.isBetaFeature ?? false}
            badge={item.badge}
            icon={icon}
            data-tour-id={item.tourId}
            data-test-id={item.testId}
            onClick={() => onSubMenuClick(item)}
          />
        ),
        children: getMenuItemsFromMenuDef(item.items, onSubMenuClick, userPermissions, enableUpgradeBadge),
      };
      items.push(subMenuItem);
    }
  });
  return items;
};
