import {FC} from 'react';
import styled from 'styled-components';
import {PromoBar} from './PromoBar';

export const FullPageHeader: FC<{
  promoBarVisible: boolean;
  onPromoBarDismissed: () => void;
}> = ({promoBarVisible, onPromoBarDismissed}) => {
  return (
    <FullPageHeaderStyled id="full-page-header">
      <PromoBar promoBarVisible={promoBarVisible} onPromoBarDismissed={onPromoBarDismissed} />
      <FullPageHeaderLayout>
        <div id="breadcrumbs-portal" />
        <ElasticSpacer />
        <div id="action-bar-portal" />
      </FullPageHeaderLayout>
    </FullPageHeaderStyled>
  );
};

const FullPageHeaderStyled = styled.div`
  position: fixed;
  top: 0;
  left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width));
  height: var(--top-offset);
  border-bottom: 1px solid var(--color-gray-4);
  background-color: #fdfdfd;
  z-index: 200;
`;

const FullPageHeaderLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100vw - var(--sidebar-width) - 15px);
  height: var(--header-height);
  padding: 0 32px;
`;

const ElasticSpacer = styled.div`
  height: 100%;
  flex-grow: 1;
`;
