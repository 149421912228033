import {VesselDetailHeader} from './VesselDetailHeader';
import OfferDetails from '../../components/OfferDetails';
import VesselDetailBody from './VesselDetailBody';
import {MarketVesselDetailsScreenActionMenu} from './MarketVesselDetailsScreenActionMenu';
import {useSelector} from '../../redux/react-redux';
import {getUser} from '../../redux/selectors';
import {assert} from '../../utils/assert';
import {BreadcrumbItem} from '../../atoms/Breadcrumb/BreadcrumbItem';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import * as GetesselDetailsResponse from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {VesselServiceRequestInformation} from '../../redux/ApiService/vesselService';

type Props = {
  vessel: GetesselDetailsResponse.MarketVessel;
  statsVessel?: $TSFixMe;
  vesselStatisticRequestInformation: VesselServiceRequestInformation<unknown>;
  breadcrumbs: BreadcrumbItem[];
  onAddToProjectClick: () => void;
  onCreateFixtureClick: () => void;
  onAddToMyFleetClick: () => void;
  onVesselChanged: () => void;
};

const MarketVesselDetails = ({
  vessel,
  statsVessel,
  vesselStatisticRequestInformation,
  breadcrumbs,
  onAddToProjectClick,
  onCreateFixtureClick,
  onAddToMyFleetClick,
  onVesselChanged,
}: Props) => {
  const user = useSelector(getUser);
  assert(user);
  return (
    <>
      <ScreenHeader
        title={vessel.name ?? undefined}
        breadcrumbs={breadcrumbs}
        actions={
          <MarketVesselDetailsScreenActionMenu
            onAddToMyFleetClick={onAddToMyFleetClick}
            onAddToProjectClick={onAddToProjectClick}
            onCreateFixtureClick={onCreateFixtureClick}
          />
        }
      />
      {vessel.offer && <OfferDetails offer={vessel.offer} isMe={vessel.offer.user?.id === user.id} />}
      <div className="vessel-content__header">
        <VesselDetailHeader vessel={vessel} />
      </div>
      <VesselDetailBody
        vessel={vessel}
        statsVessel={statsVessel}
        vesselStatisticRequestInformation={vesselStatisticRequestInformation}
        onVesselChanged={onVesselChanged}
      />
    </>
  );
};

export default MarketVesselDetails;
