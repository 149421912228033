import {PortLocation} from '../LocationOutput/PortLocation';
import {vesselDisplay} from '../../atoms/DropdownDisplay/vesselDisplay';
import {AisVesselDisplay} from '../../atoms/DropdownDisplay/AisVesselDisplay';
import {CargoDisplay} from '../../atoms/DropdownDisplay/CargoDisplay';
import {ReactNode} from 'react';
import AutoCompleteHeader from './AutoCompleteHeader';
import {NavigateFunction} from 'react-router';
import {fetchGetAppApiMapGetvesselaisdetails} from '../../queries/useGetAppApiMapGetvesselaisdetailsQuery';
import {Link} from 'react-router-dom';

export type SearchQueryOptionPrepared = {
  key: string;
  label: ReactNode;
  labelAddon?: ReactNode;
  value?: string;
  indexName?: string;
  isOverViewPageLink?: boolean;
  platformLink?: string;
  onClick?: () => void;
  source?: $TSFixMe;
  options?: SearchQueryOptionPrepared[];
};

export const prepareData = (newData: $TSFixMe, navigate: NavigateFunction): SearchQueryOptionPrepared[] => {
  const data: SearchQueryOptionPrepared[] = Object.keys(newData.data.items).map(indexName => ({
    key: indexName,
    label: <AutoCompleteHeader indexName={indexName} />,
    options: !newData.data.items[indexName].items?.length
      ? [
          {
            label: <span style={{color: 'var(--color-gray-3)'}}>No results</span>,
          },
        ]
      : newData.data.items[indexName].items.map((entry: $TSFixMe): SearchQueryOptionPrepared => {
          switch (entry._source?.aliasName || entry._index) {
            case 'seabo_ports':
              return {
                key: entry._source.code,
                label: <PortLocation location={{highlight: entry.highlight, ...entry._source}} renderAlias />,
                value: entry._source.name,
                platformLink: entry._source.code ? '/ports/' + entry._source.code : '#',
                source: entry._source,
              };
            case 'seabo_vessels':
              return {
                key: entry._source.id,
                label: vesselDisplay(entry._source, entry.highlight),
                value: entry._source.name,
                platformLink: '/vessel/' + entry._source.id || '#',
                source: entry._source,
              };
            case 'seabo_cargoes':
              return {
                key: entry._source.id,
                label: <CargoDisplay cargo={entry._source} />,
                value: entry._source.chartererName,
                platformLink: '/cargo/' + entry._source.id || '#',
                source: entry._source,
              };
            case 'seabo_projects':
              return {
                key: entry._source.readAbleProjectId,
                label: entry._source.name,
                value: entry._source.readAbleProjectId,
                labelAddon: entry._source.readAbleProjectId,
                platformLink: '/projects/' + entry._source.id || '#',
                source: entry._source,
              };
            case 'seabo_contacts':
              return {
                key: entry._source.platformUserId,
                label: entry._source.fullName,
                labelAddon: entry._source.email,
                value: entry._source.fullName,
                platformLink: entry._source.platformUserId
                  ? '/user/' + entry._source.platformUserId
                  : '/contacts#contacts',
                source: entry._source,
              };
            case 'seabo_users':
              return {
                key: entry._source.id,
                label: entry._source.fullName,
                labelAddon: entry._source.fullName,
                value: entry._source.email,
                platformLink: entry._source.id ? '/user/' + entry._source.id : '/contacts',
                source: entry._source,
              };
            case 'seabo_negotiations':
              return {
                key: entry._source.shortId,
                label: entry._source.shortId,
                value: entry._source.title,
                platformLink: '/negotiations/' + entry._source.shortId || '#',
                source: entry._source,
              };
            case 'seabo_ais_vessels':
              return {
                key: entry._source.imo,
                label: <AisVesselDisplay ais={entry._source} />,
                value: entry._source.name,
                onClick: () => {
                  fetchGetAppApiMapGetvesselaisdetails({imo: entry._source.imo})
                    .then(res => {
                      const link = `/vessel/${res.vesselId}`;
                      navigate(link);
                    })
                    .catch(() => {});
                },
                source: entry._source,
              };
            default: {
              return {
                key: entry._source.name,
                label: entry._source.name,
                source: entry._source,
              };
            }
          }
        }),
  }));
  return [
    {
      key: 'overviewPage',
      label: <AutoCompleteHeader indexName={'overviewPage'} />,
      isOverViewPageLink: true,
      platformLink: '/search/',
    },
    ...data,
  ];
};

export const renderOption = (searchQuery: string, data: SearchQueryOptionPrepared) => {
  if (data.platformLink) {
    let path = data.platformLink;
    if (path === '/search/') {
      path = `/search/${searchQuery}`;
    }
    return (
      <Link to={path}>
        {data.label}
        {data.labelAddon}
      </Link>
    );
  }
  return (
    <div onClick={data.onClick}>
      {data.label}
      {data.labelAddon}
    </div>
  );
};
