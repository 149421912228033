import {useState} from 'react';
import {SearchQueryOptionPrepared, prepareData, renderOption} from './utils';
import {actions as autocompleteActions} from '../../redux/ApiService/autocomplete';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {useNavigate} from 'react-router-dom';
import {AutoComplete, Input} from 'antd';
import {useQuery} from '@tanstack/react-query';
import Icon from '../../atoms/Icon';
import styled, {createGlobalStyle, css} from 'styled-components';
import {SidebarActions, SidebarMode} from '../../redux/Sidebar';
import {getSidebarMode} from '../../redux/selectors';
import AutoCompleteHeader from './AutoCompleteHeader';

export const SearchBar = ({width = '100%'}: {width?: string | number}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sidebarMode = useSelector(getSidebarMode);
  const [searchQuery, setSearchQuery] = useState('');

  const optionsQuery = useQuery({
    queryKey: ['autocompleteActions.search', searchQuery],
    queryFn: async () => {
      const response = await dispatch(
        autocompleteActions.search({
          queryParams: {
            query: searchQuery,
            indexNames: [
              'vessels_market',
              'vessels_portfolio',
              'cargoes_market',
              'cargoes_portfolio',
              'projects',
              'ais_vessels',
              'ports',
              'contacts',
              'users',
            ],
            pageSize: 6,
          },
        })
      );
      return prepareData(response, navigate);
    },
    enabled: searchQuery.length > 2,
  });

  const options =
    optionsQuery.data ??
    (searchQuery.length > 2
      ? [{key: 'loading', label: <AutoCompleteHeader indexName={'loading'} />}]
      : [{key: 'empty-query', label: <AutoCompleteHeader indexName={'empty-query'} />}]);

  const onSearchClick = () => {
    dispatch(SidebarActions.setSidebarMode('wide'));
  };

  return (
    <div id="react-search" data-testid="SearchBar" style={{width}}>
      <SearchBarAutoCompleteStyle />
      <AutoComplete<string, SearchQueryOptionPrepared>
        popupClassName="search-bar-dropdown fixed"
        style={{width}}
        listHeight={600}
        dropdownStyle={{width: searchQuery.length > 2 ? 350 : 208}}
        options={options}
        optionRender={({data}) => renderOption(searchQuery, data as SearchQueryOptionPrepared)}
        onChange={setSearchQuery}
        onClick={onSearchClick}>
        <SearchBarInput
          placeholder="Search everywhere..."
          addonBefore={<Icon type="search" />}
          $size={sidebarMode}
          data-testid="SearchBarInput"
        />
      </AutoComplete>
    </div>
  );
};

const SearchBarInput = styled(Input)<{$size: SidebarMode}>`
  width: 100%;
  box-shadow: none !important;
  border-radius: var(--border-radius-button) !important;

  .ant-input.ant-input-outlined,
  .ant-input-group-addon {
    background-color: rgba(255, 255, 255, 0.08) !important;
    border: 1px solid rgba(255, 255, 255, 0.24) !important;
  }
  .ant-input-group-addon {
    border-radius: var(--border-radius-button);
  }

  .ant-input.ant-input-outlined {
    border-left: none !important;
    padding-left: 0 !important;
    color: var(--color-white);

    &::placeholder {
      color: var(--color-gray-3) !important;
    }
  }

  .ant-input-group-addon {
    border-right: none !important;
    padding: 1px 6px 0 16px;
    .icon {
      color: var(--color-gray-3);
    }
  }

  ${({$size}) => {
    if ($size === 'narrow') {
      return css`
        .ant-input-group-addon {
          padding: 1px 4px 0 17px;
        }
      `;
    }
    return css`
      .ant-input-group-addon {
        padding: 1px 4px 0 12px;
      }
    `;
  }}
`;

const SearchBarAutoCompleteStyle = createGlobalStyle`
  .ant-select-dropdown.search-bar-dropdown {
    .ant-select-item {
      cursor: default;

      &.ant-select-item-group:nth-of-type(2) {
        margin-top: -12px;
      }
      &.ant-select-item-option:last-of-type:not(:first-of-type) {
        padding-bottom: 16px;
      }
      &.ant-select-item-option-grouped {
        padding-inline-start: 12px;
      }
      &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background: transparent !important;
      }
    }
  }
`;
