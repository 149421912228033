import {FC, useMemo} from 'react';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {LoadingBanner, NegotiationScreenContainer} from '../Components/shared';
import {NegotiationCreateBody} from './NegotiationCreateBody';
import {useSearchParams} from 'react-router-dom';
import {defaultFormValues, getPresetTitle, getValidNumberOrUndefined} from './utils/utils';
import {useGetCargoQuery} from '../../../queries/useGetCargoQuery';
import {useGetVesselQuery} from '../../../queries/useGetVesselQuery';
import {CreateNegotiationFormValues} from './utils/types';
import {getFormValuesFromApiVessel, getFormValuesFromApiCargo} from './Components/utils';
import {useLocalStorage} from '../../../utils/useLocalStorage';
import dayjs, {Dayjs} from 'dayjs';
import {z} from 'zod';
import {PortfolioCargo} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';

export const NegotiationCreateScreen: FC = () => {
  const [params] = useSearchParams();
  const vesselId = getValidNumberOrUndefined(params.get('vesselId'));
  const cargoId = getValidNumberOrUndefined(params.get('cargoId'));

  const vesselQuery = useGetVesselQuery({vesselId, options: {enabled: !!vesselId}});
  const cargoQuery = useGetCargoQuery<PortfolioCargo>({cargoId, options: {enabled: !!cargoId}});

  // We store the form values in local storage so that if the user navigates away from the page
  // and comes back, the form gets pre-filled with these values below
  const [localStorageValues, setLocalStorageValues] = useLocalStorage<Partial<CreateNegotiationFormValues>>(
    'createNegotiationFormValues',
    {},
    z.any()
  );

  const parsedValues = useMemo(() => {
    const parsedValues = {...localStorageValues};
    if (localStorageValues) {
      if (parsedValues.vessel) {
        parsedValues.vessel = {
          ...parsedValues.vessel,
          dateOpen: parsedValues.vessel.dateOpen ? dayjs(parsedValues.vessel.dateOpen) : undefined,
        };
      }
      if (parsedValues.cargo) {
        parsedValues.cargo = {
          ...parsedValues.cargo,
          laycan: parsedValues.cargo.laycan
            ? (parsedValues.cargo.laycan.map(date => (date ? dayjs(date) : undefined)) as [
                Dayjs | undefined,
                Dayjs | undefined,
              ])
            : undefined,
        };
      }
    }
    return parsedValues;
  }, [localStorageValues]);

  const presetValues = useMemo(() => {
    const presetValues: CreateNegotiationFormValues = {...defaultFormValues, ...parsedValues};
    if (vesselId && vesselQuery.data) {
      presetValues.vessel = getFormValuesFromApiVessel(vesselQuery.data);
    }
    if (cargoId && cargoQuery.data) {
      presetValues.cargo = getFormValuesFromApiCargo(cargoQuery.data);
    }
    presetValues.title = getPresetTitle(presetValues);
    return presetValues;
  }, [vesselId, cargoId, vesselQuery.data, cargoQuery.data, parsedValues]);

  // We provide a callback to the form so that we can store the form values in local storage
  const onFormChange = (newValues: Partial<CreateNegotiationFormValues> | null) => {
    if (!newValues) {
      setLocalStorageValues({});
      return;
    }
    setLocalStorageValues({...parsedValues, ...newValues});
  };

  if ((vesselId && !vesselQuery.isSuccess) || (cargoId && !cargoQuery.isSuccess)) {
    return (
      <NegotiationScreenContainer data-testid="NegotiationCreateScreen">
        <ScreenHeader
          title="Create negotiation"
          breadcrumbs={[{title: 'Negotiations', href: '/negotiations'}, {title: 'New negotiation'}]}
        />
        <LoadingBanner data-testid="NegotiationCreateScreenLoading" />
      </NegotiationScreenContainer>
    );
  }

  return (
    <NegotiationScreenContainer data-testid="NegotiationCreateScreen">
      <ScreenHeader
        title="Create negotiation"
        breadcrumbs={[{title: 'Negotiations', href: '/negotiations'}, {title: 'New negotiation'}]}
      />
      <NegotiationCreateBody presetValues={presetValues} onFormChange={onFormChange} />
    </NegotiationScreenContainer>
  );
};
