import {Button, ButtonProps} from 'antd';
import styled, {css} from 'styled-components';
import Icon from '../../Icon';
import {ReactNode} from 'react';

export type SidebarButtonLabelSize = 'wide' | 'narrow';

export const SidebarButton = ({
  icon,
  text,
  size = 'wide',
  showArrow = false,
  ...buttonProps
}: {icon: ReactNode; text: ReactNode; size?: SidebarButtonLabelSize; showArrow?: boolean} & Omit<
  ButtonProps,
  'size'
>) => {
  return (
    <SidebarButtonStyled $size={size} block {...buttonProps}>
      <SidebarButtonLabel $size={size}>
        {icon}
        <Text>{text}</Text>
        {showArrow && <ArrowIcon />}
      </SidebarButtonLabel>
    </SidebarButtonStyled>
  );
};

const SidebarButtonStyled = styled(Button)<{$size?: SidebarButtonLabelSize}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 8px !important;
  background: transparent !important;
  color: var(--color-white-fixed) !important;
  font-weight: unset !important;
  text-transform: unset;
  border: none !important;
  box-shadow: var(--box-shadow-inset);
  transition: padding var(--sidebar-transition-duration);

  &:hover {
    color: var(--color-white-fixed) !important;
  }

  .icon,
  svg {
    fill: var(--color-white-fixed);
  }

  ${({$size = 'wide'}) => {
    if ($size === 'narrow') {
      return css`
        padding: 4px 16px !important;
      `;
    }
  }}
`;

export const SidebarButtonLabel = styled.div<{$size?: SidebarButtonLabelSize}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;

  ${({$size = 'wide'}) => {
    if ($size === 'narrow') {
      return css`
        ${ArrowIconWrapper}, ${Text} {
          display: none !important;
        }
      `;
    }
  }}
`;

const Text = styled.span`
  display: flex;
  align-items: center;
  overflow: hidden;
  text-align: left;
`;

const ArrowIcon = () => (
  <ArrowIconWrapper>
    <Icon type="angle-down" size="small" />
  </ArrowIconWrapper>
);

const ArrowIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin-left: auto;
`;
