import {TODO} from '../../../../utils/TODO';
import CompanyActions from '../../../../redux/ApiService/CompanyService/CompanyService';
import {actions as companyImageReducer} from '../../../../redux/ApiService/companyImageService';
import {useDispatch} from '../../../../redux/react-redux';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {Company} from '../../../../redux/ApiService/CompanyService/Company';
import {ApiError} from '../../../../api/utils/ApiError';

export interface UpdateCompanyVariables {
  company: Company;
  hasLogo: boolean;
  logoFile: File | undefined;
}

export const useUpdateCompanySettingsMutation = (
  options: UseMutationOptions<unknown, ApiError, UpdateCompanyVariables>
) => {
  const dispatch = useDispatch();

  const uploadAvatar = (data: FormData) => dispatch(companyImageReducer.companyUploadLogo({body: data}));

  const updateCompany = (id: number, data: TODO) => dispatch(CompanyActions.updateCompany(id, data));

  const saveCompany = async ({company, logoFile, hasLogo}: UpdateCompanyVariables) => {
    const {
      companyLogo,
      business,
      foundYear,
      companyType,
      description,
      employees,
      companyMailDomain,
      vesselsManaged,
      contact,
      taxId,
      address,
    } = company;

    let newCompanyLogo = hasLogo ? companyLogo?.uuid : null;
    if (logoFile) {
      const formData = new FormData();
      formData.append('fileName', logoFile);
      const response = await uploadAvatar(formData);
      newCompanyLogo = response.data.items[0].uuid;
    }
    await updateCompany(company.id, {
      business,
      foundYear,
      companyType,
      description,
      employees,
      companyMailDomain,
      contact,
      taxId,
      address,
      vesselsManaged: vesselsManaged,
      companyLogo: newCompanyLogo,
    });
  };

  return useMutation({
    mutationFn: saveCompany,
    ...options,
  });
};
