import {Dropdown} from 'antd';
import {createGlobalStyle} from 'styled-components';
import {UserAvatar} from '../../UserAvatar/UserAvatar';
import {useUserMenuItems} from './useUserMenuItems';
import {useUser} from '../../../components/UserContext/useUser';
import {SidebarButton, SidebarButtonLabelSize} from './SidebarButton';

export const UserMenu = ({size}: {size?: SidebarButtonLabelSize}) => {
  const currentUser = useUser();
  const items = useUserMenuItems();

  return (
    <>
      <UserMenuDropdownStyle />
      <Dropdown
        arrow={false}
        placement="top"
        trigger={['click']}
        overlayClassName={'header-user-menu fixed'}
        overlayStyle={{marginBottom: 8}}
        menu={{items}}>
        <div data-testid="UserMenu">
          <SidebarButton
            icon={<UserAvatar size={32} user={currentUser.payload} />}
            text={currentUser.fullName}
            size={size}
            showArrow
            data-testid="UserMenuOpenButton"
            data-tour-id="UserMenu"
          />
        </div>
      </Dropdown>
    </>
  );
};

const UserMenuDropdownStyle = createGlobalStyle`
  .ant-dropdown.header-user-menu {
    width: 224px !important;

    .ant-dropdown-menu-item-group-title, .ant-dropdown-menu-item {
      font-weight: bold;
      padding: 8px 12px;

      &.menu-item-logout {
        padding: 0;
      }
    }

    .ant-dropdown-menu-item:not(:last-of-type) {
      border-bottom: var(--border-base);
    }

    .ant-dropdown-menu-item-group {
      padding-bottom: 8px !important;
      border-bottom: var(--border-base);

      .ant-dropdown-menu-item {
        padding: 2px 8px;
        font-weight: normal;
        border-bottom: none;
        border-radius: var(--border-radius);
      }
    }

    .empty-group-header > div {
      display: none;
    }
    .group-header {
      border-bottom: none !important;
    }
  }
`;
