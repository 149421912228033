import {Card, Col, Form, Row} from 'antd';
import {Dayjs} from 'dayjs';
import {useState} from 'react';
import styled from 'styled-components';
import {LoadingModal} from '../../../components/LoadingModal/LoadingModal';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {LoggedFilters} from '../../../hooks/useGetCompetitors/filterVesselsAndAddDistance/steps/StepResult';
import {useGetCompetitors} from '../../../hooks/useGetCompetitors/useGetCompetitors';
import {CompetitorsMap} from './CompetitorsMap';
import {EnabledFilters, FilterOptionDropdown, defaultFilters} from './FilterOptionDropdown';
import {Grid} from './Grid/Grid';
import {PotentialCompetitorsForm} from './PotentialCompetitorsForm/PotentialCompetitorsForm';
import {Schematic} from './ResultChart/Schematic';
import {PotentialCompetitorsFormData, ResultVessel} from './types';

export const PotentialCompetitorsScreen = ({currentTime}: {currentTime?: Dayjs}) => {
  const [progress, setProgress] = useState(0);
  const [result, setResult] = useState<ResultVessel[] | undefined>();
  const [hoveredVessel, setHoveredVessel] = useState<ResultVessel | null>();
  const [showMapDetails, setShowMapDetails] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<EnabledFilters>(defaultFilters);
  const [filterLogs, setFilterLogs] = useState<LoggedFilters | undefined>();
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false);
  const [form] = Form.useForm<PotentialCompetitorsFormData>();

  const {getCompetitors, isReady, cancelRunningCalculation} = useGetCompetitors({
    currentTime,
    onChangeIsLoading: value => {
      if (!value) {
        // wait for 500ms before set false
        setTimeout(() => setIsFilterLoading(value), 1000);
      } else {
        setIsFilterLoading(value);
      }
      if (value) {
        setTimeout(() => setShowMapDetails(true), 500);
      }
    },
    filterOptions,
    onProgress: setProgress,
    onChangeResult: setResult,
    onChangeLogs: setFilterLogs,
  });

  return (
    <>
      <LoadingModal
        isLoading={isFilterLoading}
        progress={progress}
        onCancel={() => {
          void cancelRunningCalculation();
          setIsFilterLoading(false);
        }}
      />
      <ScreenHeader
        title="Potential competitors"
        breadcrumbs={[{title: 'Analytics'}, {title: 'Potential Competitors'}]}
      />
      <Row gutter={[20, 20]}>
        <Col span={8} xxl={6}>
          <StyledCard
            title={`Competitors ${result ? `(${result?.length} vessels)` : ''}`}
            extra={<FilterOptionDropdown value={filterOptions} onChange={value => setFilterOptions(value)} />}>
            <PotentialCompetitorsForm
              canSubmit={isReady}
              currentTime={currentTime}
              filterOptions={filterOptions}
              form={form}
              onFinish={formValues => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                getCompetitors(formValues);
              }}
            />
          </StyledCard>
        </Col>
        <Col span={16} xxl={18}>
          <StyledCard style={{height: '100%'}} bodyStyle={{height: '100%'}} title={'Vessels accepted by the filter'}>
            <Schematic resultsAfterFilter={filterLogs} />
          </StyledCard>
        </Col>
        <Col span={24}>
          <CompetitorsMap
            hoveredVessel={hoveredVessel ?? undefined}
            showMapDetails={showMapDetails}
            result={result}
            port={form.getFieldValue('port')}
          />
        </Col>
        {filterLogs !== undefined && (
          <Col span={24}>
            <Grid
              onHoverVessel={vessel => {
                setHoveredVessel(vessel);
              }}
              vessels={result ?? []}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

const StyledCard = styled(Card)`
  height: 100%;
`;
