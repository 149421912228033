import {FC, CSSProperties} from 'react';
import {Workspace} from './WorkspaceSelectTypes';
import {WorkspaceSelectDumb, WorkspaceSelectSize} from './WorkspaceSelectDumb';
import {assert} from '../../utils/assert';
import {useWorkspacesQuery} from '../../queries/useWorkspacesQuery';
import User from '../../model/User';
import {useSwitchToWorkspace} from './useSwitchToWorkspace';
import {useUser} from '../../components/UserContext/useUser';

export const WorkspaceSelect: FC<{
  size?: WorkspaceSelectSize;
  style?: CSSProperties;
}> = ({size = 'wide', style}) => {
  const user: User = useUser();
  assert(user, 'Missing user');

  const switchToWorkspace = useSwitchToWorkspace();

  const companyWorkspacesQuery = useWorkspacesQuery();
  const companyWorkspaces: Workspace[] = companyWorkspacesQuery.isSuccess
    ? companyWorkspacesQuery.data
    : getTemporaryCompanyWorkspaces(user);

  const currentlyUsedWorkspaceId = user.workspace?.id;

  return (
    <WorkspaceSelectDumb
      size={size}
      companyWorkspaces={companyWorkspaces}
      user={user}
      value={currentlyUsedWorkspaceId}
      style={style}
      dataTestId="WorkspaceSelect"
      onSelect={switchToWorkspace}
    />
  );
};

// Returns a temporary workspace while loading
// so that we see no flashing once loading completes.
const getTemporaryCompanyWorkspaces = (user: User): Workspace[] => {
  if (!user.workspace || user.workspace.id === 0) {
    return [];
  }
  return [
    {
      id: user.workspace.id,
      name: user.workspace.name ?? '',
      description: '',
      email: '',
      easySharing: false,
    },
  ];
};
