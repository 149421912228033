/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {GeocodingResponse, GeocodingResponseFromJSON, GeocodingResponseToJSON} from '../models';

export interface GeocodeRequest {
  searchString: string;
}

/**
 *
 */
export class GeocodingApi extends runtime.BaseAPI {
  /**
   */
  async geocodeRaw(requestParameters: GeocodeRequest): Promise<runtime.ApiResponse<GeocodingResponse>> {
    if (requestParameters.searchString === null || requestParameters.searchString === undefined) {
      throw new runtime.RequiredError(
        'searchString',
        'Required parameter requestParameters.searchString was null or undefined when calling geocode.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/geocoding/{searchString}`.replace(
        `{${'searchString'}}`,
        encodeURIComponent(String(requestParameters.searchString))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => GeocodingResponseFromJSON(jsonValue));
  }

  /**
   */
  async geocode(requestParameters: GeocodeRequest): Promise<GeocodingResponse> {
    const response = await this.geocodeRaw(requestParameters);
    return await response.value();
  }
}
