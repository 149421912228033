import useInterval from 'beautiful-react-hooks/useInterval';
import {useStrictModeUnsafeEffect} from '../../utils/useStrictModeUnsafeEffect';
import useOnlineState from 'beautiful-react-hooks/useOnlineState';
import {useEffect} from 'react';
import {resetAuthStateWithMessage} from './utils';
import {useAuthentication} from './useAuthentication';
import {getIsAccessTokenExpired, getIsRefreshTokenExpired} from './getIsTokenExpired';
import {getRefreshToken} from './utils';

// The access token is valid for 10 minutes. We refresh it every 8 minutes. This gives us a 2 minute buffer.
const REFRESH_INTERVAL = 8 * 60 * 1000;

export type AccessTokenManagerState = {
  hasAuthorizationCode: boolean;
  hasRefreshToken: boolean;
  hasAccessToken: boolean;
};

/**
 * This hook manages the OAuth flow to retrieve and refresh an access token.
 * It resets the browser's SessionStorage and window location if an error occurs, which restarts the authentication process.
 * @returns {AccessTokenManagerState} Returns whether authorizationCode, refreshToken and accessToken are present and valid.
 */
export const useAccessTokenManager = (): AccessTokenManagerState => {
  const isOnline = useOnlineState();
  const {authorizationCode, refreshToken, accessToken, generateAccessToken, handleAuthFlow} = useAuthentication();

  // This runs the auth flow once when the component mounts.
  // Since it is critical that the auth flow runs only once, we use the `useStrictModeUnsafeEffect` hook.
  // Inside, we authenticate with the PHP-backend via OAuth redirect and get the node-backend tokens with the PHP auth code.
  useStrictModeUnsafeEffect(() => {
    void handleAuthFlow();
  });

  // After receiving the initial node-backend refresh token, we refresh the access token every 8 minutes.
  useInterval(() => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      return;
    }
    void generateAccessToken(refreshToken);
  }, REFRESH_INTERVAL);

  // If one of the tokens is expired when the computer goes online, we reset the browser.
  useEffect(() => {
    if (!isOnline) {
      return;
    }
    if (getIsRefreshTokenExpired()) {
      resetAuthStateWithMessage('Refresh token expired.');
      return;
    }
    if (getIsAccessTokenExpired(accessToken)) {
      resetAuthStateWithMessage('Access token expired.');
      return;
    }
  }, [isOnline, accessToken]);

  return {
    hasAuthorizationCode: !!authorizationCode,
    hasRefreshToken: !!refreshToken,
    hasAccessToken: !!accessToken,
  };
};
