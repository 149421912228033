import {OnboardingStepWrapper} from '../OnboardingStepWrapper';
import {OnboardingStepProps} from '../useUserOnboarding';
import {SettingsArea} from '../../../components/SettingsCard/SettingsArea';
import {SettingsCard} from '../../../components/SettingsCard/SettingsCard';
import {SettingsCardBody} from '../../../components/SettingsCard/SettingsCardBody';
import {SettingsCardHeader} from '../../../components/SettingsCard/SettingsCardHeader';
import {useSelector} from '../../../redux/react-redux';
import {useUpdateCompanyMutation} from '../../../queries/company/useUpdateCompanyMutation';
import {getCompanyState} from '../../../redux/selectors';
import {refetchCurrentCompany} from '../../../components/UserContext/CompanyLoader';
import {useState} from 'react';
import {ContractDuration} from '../../CompanySubscriptionScreen/components/ContractDurationPicker';
import {TrialBanner} from '../../CompanySubscriptionScreen/components/TrialBanner/TrialBanner';
import {PlanUvpComparison} from '../../CompanySubscriptionScreen/components/PlanUvpComparison/PlanUvpComparison';

export const UpgradePlan = ({onNext, ...restProps}: OnboardingStepProps) => {
  const [contractDuration, setContractDuration] = useState<ContractDuration>('monthly');

  const company = useSelector(getCompanyState).data;
  const updateCompanyMutation = useUpdateCompanyMutation();

  const handleNext = () => {
    updateCompanyMutation.mutate(
      {id: company.id, companyEdit: {isOnboarded: true}},
      {
        onSuccess: async () => {
          await refetchCurrentCompany();
          onNext?.();
        },
      }
    );
  };

  return (
    <OnboardingStepWrapper
      title="Try all seabo features for free"
      subTitle="You can now try our Pro plan 30 days for free."
      dataTestId="UpgradePlan"
      onNext={handleNext}
      {...restProps}>
      <SettingsCard>
        <SettingsCardHeader title={'Upgrade plan'} />
        <SettingsCardBody>
          <SettingsArea style={{paddingBottom: 40}}>
            <PlanUvpComparison
              showActionButtons={true}
              contractDuration={contractDuration}
              onChangeContractDuration={setContractDuration}
            />
            <TrialBanner />
          </SettingsArea>
        </SettingsCardBody>
      </SettingsCard>
    </OnboardingStepWrapper>
  );
};
