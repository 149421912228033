import React, {FC} from 'react';
import {Button, Tooltip} from 'antd';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {SidebarMode, SidebarActions} from '../../../redux/Sidebar';
import Icon, {IconType} from '../../Icon';

export const SidebarControls: FC<{mode: SidebarMode}> = ({mode}) => {
  return (
    <SidebarControlsStyled>
      {mode === 'narrow' && (
        <LayoutNarrow>
          <HiddenButton />
          <WideButton />
        </LayoutNarrow>
      )}
      {mode === 'wide' && (
        <LayoutWide>
          <NarrowButton />
        </LayoutWide>
      )}
    </SidebarControlsStyled>
  );
};

const HiddenButton = () => <ModeButton mode="hidden" tooltip="Hide" icon="angle-left" dataTestId="HiddenButton" />;
const NarrowButton = () => <ModeButton mode="narrow" tooltip="Minify" icon="angle-left" dataTestId="NarrowButton" />;
const WideButton = () => <ModeButton mode="wide" tooltip="Expand" icon="angle-right" dataTestId="WideButton" />;

const ModeButton: FC<{mode: SidebarMode; tooltip: string; icon: IconType; dataTestId: string}> = ({
  mode,
  tooltip,
  icon,
  dataTestId,
}) => {
  const dispatch = useDispatch();

  return (
    <Tooltip title={tooltip} placement="topLeft">
      <IconButton
        onClick={() => {
          dispatch(SidebarActions.setSidebarMode(mode));
        }}
        data-testid={dataTestId}>
        <Icon type={icon} />
      </IconButton>
    </Tooltip>
  );
};

export const RevealSidebarButton = () => {
  return (
    <FloatingArea>
      <ModeButton mode="narrow" tooltip="Show sidebar" icon="angle-right" dataTestId="RevealSidebarButton" />
    </FloatingArea>
  );
};

const FloatingArea = styled.nav`
  position: fixed;
  bottom: 12px;
  left: 5px;
  z-index: 500;
`;

const SidebarControlsStyled = styled.div`
  position: absolute;
  bottom: 4px;
  left: 4px;
  right: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const LayoutNarrow = styled(Layout)`
  justify-content: space-between;
`;

const LayoutWide = styled(Layout)`
  justify-content: flex-start;
`;

const IconButton = styled(Button)`
  border: none;
  background-color: transparent;
  padding: 0;
  width: 20px;
  height: 20px;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  .anticon {
    font-size: var(--font-size-xl);
    color: #e0e0e0;

    &:hover {
      fill: var(--color-white);
    }
  }
`;
