import {useMemo, useState, FC} from 'react';
import {SpotifindFilters} from './SpotifindFilters';
import SpotifindGrid from '../../components/Tools/SpotifindGrid/SpotifindGrid';
import {SpotifindVessel, useGetSpotifindVesselsQuery} from './useGetSpotifindVesselsQuery';
import {SpotifindMap} from './SpotifindMap';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';
import {FilterProviderState} from '../../components/FilterProvider/FilterProviderState';
import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import {NoSpotifindFilterBox} from './NoSpotifindFilterBox';
import {getVesselsInBoundingBox} from './getVesselsInBoundingBox';
import {GridAndMapLayout} from './Layouts/GridAndMapLayout';
import {useDispatch} from 'react-redux';
import {GridAndMapLayoutType, SpotifindActions} from '../../redux/Spotifind';
import {useSelector} from '../../redux/react-redux';
import {LayoutSelect} from './LayoutSelect';
import {SyncMapWithGridCheckbox} from './SyncMapWithGridCheckbox';
import {
  SpotifindDatabaseFilter,
  SpotifindFilterBranchDefinitions,
} from '../../components/FilterProvider/Filters/Spotifind/SpotifindFilterBranchDefinitions';
import {LoadingModal} from '../../components/LoadingModal/LoadingModal';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';

type Props = {
  isLoading: boolean;
  filtersLoaded: boolean;
  filterProviderApi: FilterProviderApi<typeof SpotifindFilterBranchDefinitions>;
  filterState: {
    filter: SpotifindDatabaseFilter | null;
    filterId: number | null;
    filterProviderState: FilterProviderState<typeof SpotifindFilterBranchDefinitions> | null;
    filterChanged: boolean;
  };
};

export const SpotifindBody = ({isLoading, filtersLoaded, filterProviderApi, filterState}: Props) => {
  const isAnyFilterApplied = filterProviderApi.isAnyFilterApplied;

  const spotifindVesselsQuery = useGetSpotifindVesselsQuery({
    options: {
      enabled: isAnyFilterApplied,
    },
    filter: filterState.filter,
  });
  const vessels = spotifindVesselsQuery.data ?? [];

  const gridVisible = filterProviderApi.isAnyFilterApplied;
  const noSpotifindFilterBoxVisible = filtersLoaded && !filterProviderApi.isAnyFilterApplied;
  return (
    <div className={'spotifind-screen'}>
      <LoadingModal isLoading={spotifindVesselsQuery.isFetching} />
      <ScreenHeader
        title="Spotifind"
        breadcrumbs={[{title: 'Analytics'}, {title: 'Spotifind'}]}
        features={
          <>
            {filterProviderApi.isAnyFilterApplied && (
              <ScreenHeader.Features.Button
                data-cy="SpotifindResetFiltersBTN"
                onClick={filterProviderApi.onResetAllClick}>
                Reset filters
              </ScreenHeader.Features.Button>
            )}
            {isTwoColumnLayoutPossible() && <LayoutSelect />}
            <SyncMapWithGridCheckbox />
          </>
        }
      />
      <SpotifindFilters filterProviderApi={filterProviderApi} />
      {gridVisible && <GridAndMap isLoading={isLoading || spotifindVesselsQuery.isLoading} vessels={vessels} />}
      {noSpotifindFilterBoxVisible && <NoSpotifindFilterBox filterProviderApi={filterProviderApi} />}
    </div>
  );
};

const GridAndMap: FC<{isLoading: boolean; vessels: SpotifindVessel[]}> = ({isLoading, vessels}) => {
  const [selectedVessel, setSelectedVessel] = useState<SpotifindVessel | undefined>(undefined);
  const {syncMapWithGrid, userSelectedLayout, mapBoundingBox} = useSelector(state => state.spotifind);
  const dispatch = useDispatch();

  // This makes sure that we rerender when the window gets resized.
  useWindowResize();

  const appliedLayout: GridAndMapLayoutType = isTwoColumnLayoutPossible() ? userSelectedLayout : 'oneColumn';

  const vesselsInGrid: SpotifindVessel[] = useMemo(
    () => getVesselsInBoundingBox({vessels, boundingBox: syncMapWithGrid ? mapBoundingBox : null}),
    [mapBoundingBox, vessels, syncMapWithGrid]
  );

  return (
    <GridAndMapLayout
      layout={appliedLayout}
      map={
        <SpotifindMap
          key={appliedLayout}
          vessels={vessels}
          selectedVessel={selectedVessel}
          onChangeViewBounds={boundingBox => dispatch(SpotifindActions.setMapBoundingBox(boundingBox))}
        />
      }
      grid={
        <SpotifindGrid
          selectedVessel={selectedVessel}
          loading={isLoading}
          vessels={vesselsInGrid}
          onClickRow={vessel => {
            if (selectedVessel?.imo === vessel.imo) {
              // Deselect
              setSelectedVessel(undefined);
              return;
            }
            setSelectedVessel(vessel);
          }}
        />
      }
    />
  );
};

const isTwoColumnLayoutPossible = () => window.innerWidth > 1600;
