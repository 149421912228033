import {FC, useEffect, useState} from 'react';
import {Form, FormInstance, Input, Select, Typography} from 'antd';
import {Cargo as SearchPickerCargo, Location} from '../../../../components/SearchPicker/Type';
import {
  FormSection,
  FormSectionHeader,
  FormSectionBody,
  FormSectionItem,
  ExpandButton,
  InputGroup,
  ExpandSection,
  StyledRangePicker,
} from '../../../../atoms/FormElements/FormElements';
import {CargoPicker} from '../../../../components/CargoPicker/CargoPicker';
import {getFormValuesFromApiCargo, getFormValueFromDate, getFormValuesFromApiLocation} from './utils';
import {commodityCategories} from '../../../../utils/commodityCategories';
import {CreateNegotiationFormCargo, CreateNegotiationFormValues} from '../utils/types';
import {LocationPickerOrControlledInput} from './LocationPickerOrControlledInput';
import {Dayjs} from 'dayjs';
import HorizontalLine from '../../../../atoms/HorizontalLine';
import {InputNumber} from '../../../../antd/components/InputNumber';

const {Title} = Typography;

type NegotiationFormCargoSectionProps = {
  form: FormInstance<CreateNegotiationFormValues>;
  onFormChange: (values: Partial<CreateNegotiationFormValues> | null) => void;
};

export const NegotiationFormCargoSection: FC<NegotiationFormCargoSectionProps> = ({form, onFormChange}) => {
  const cargoFormValues = Form.useWatch('cargo', form);
  const updateCargoFormValues = (cargo: CreateNegotiationFormCargo) => {
    form.setFieldsValue({cargo});
    onFormChange({cargo});
  };

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (cargoFormValues?.id) {
      setIsExpanded(true);
    }
  }, [cargoFormValues?.id]);

  const onSelectCargo = (cargo?: SearchPickerCargo) => {
    setIsExpanded(true);
    if (!cargo) {
      return;
    }
    // SearchPicker returns incompatible types, so we need to transform it
    const formCargo = getFormValuesFromApiCargo(cargo);
    updateCargoFormValues(formCargo);
  };

  const onSelectLocation = (location: Location | null, type: 'loading' | 'discharge') => {
    const {label, countryCode} = getFormValuesFromApiLocation(location);
    if (type === 'loading') {
      updateCargoFormValues({
        loadingLabel: label,
        loadingCountryCode: countryCode,
      });
    } else {
      updateCargoFormValues({
        dischargeLabel: label,
        dischargeCountryCode: countryCode,
      });
    }
  };

  const onSelectLaycan = (laycan: [from: Dayjs | null, to: Dayjs | null] | null) => {
    if (!laycan) {
      return;
    }
    updateCargoFormValues({
      laycan: [getFormValueFromDate(laycan[0]), getFormValueFromDate(laycan[1])],
    });
  };

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <Title level={4}>Contract type</Title>
        </FormSectionHeader>
        <FormSectionBody>
          <FormSectionItem name={['cargo', 'contractType']} label="Contract type">
            <Select
              options={[
                {label: 'Voyage charter', value: 'vc'},
                {label: 'Time charter trip', value: 'tct'},
                {label: 'Period charter', value: 'pc'},
                {label: 'Bareboat charter', value: 'bc'},
              ]}
            />
          </FormSectionItem>
        </FormSectionBody>
      </FormSection>
      <FormSection>
        <FormSectionHeader>
          <Title level={4}>Cargo</Title>
        </FormSectionHeader>
        <FormSectionBody>
          {isExpanded ? (
            <ExpandButton size="small" onClick={() => setIsExpanded(false)}>
              Search cargo
            </ExpandButton>
          ) : (
            <FormSectionItem label="Search for My Cargoes cargo">
              <CargoPicker
                size="middle"
                defaultValue={cargoFormValues?.commodityType?.toString()}
                indexNames={['cargoes_portfolio']}
                onSelect={onSelectCargo}
              />
              <ExpandButton size="small" onClick={() => setIsExpanded(true)}>
                ...or enter cargo details manually
              </ExpandButton>
            </FormSectionItem>
          )}
          <ExpandSection $hidden={!isExpanded}>
            <HorizontalLine topSpace="large" bottomSpace="extraLarge" />
            <FormSectionItem name={['cargo', 'id']} hidden>
              <Input hidden />
            </FormSectionItem>
            <FormSectionItem $span={12} name={['cargo', 'commodityCategory']} label="Commodity category">
              <Select
                placeholder="Commodity category"
                options={commodityCategories.map(({slug, name}) => ({label: name, value: slug}))}
              />
            </FormSectionItem>
            <FormSectionItem $span={12} name={['cargo', 'commodityType']} label="Commodity type">
              <Input placeholder="Commodity type" />
            </FormSectionItem>
            <FormSectionItem $span={12} label="Cargo size">
              <InputGroup>
                <Form.Item name={['cargo', 'quantityMin']} noStyle>
                  <InputNumber type="number" placeholder="min" step={1000} style={{width: '35%'}} />
                </Form.Item>
                <Form.Item name={['cargo', 'quantityMax']} noStyle>
                  <InputNumber type="number" placeholder="max" step={1000} style={{width: '35%'}} />
                </Form.Item>
                <Form.Item name={['cargo', 'quantityUnit']} noStyle>
                  <Select
                    options={[
                      {label: 'MT', value: 'MT'},
                      {label: 'CBM', value: 'CBM'},
                      {label: 'TEU', value: 'TEU'},
                    ]}
                    style={{width: '30%'}}
                  />
                </Form.Item>
              </InputGroup>
            </FormSectionItem>
            <FormSectionItem $span={12} name={['cargo', 'laycan']} label="Laycan">
              <StyledRangePicker onChange={onSelectLaycan} allowEmpty={[true, true]} />
            </FormSectionItem>
            <FormSectionItem $span={12} label="Loading / delivery location" shouldUpdate>
              <LocationPickerOrControlledInput
                locationLabel={cargoFormValues?.loadingLabel}
                locationCountryCode={cargoFormValues?.loadingCountryCode}
                formNameFactory={name => ['cargo', `loading${name}`]}
                onSelectLocation={location => onSelectLocation(location, 'loading')}
              />
            </FormSectionItem>
            <FormSectionItem $span={12} label="Discharge / redelivery location" shouldUpdate>
              <LocationPickerOrControlledInput
                locationLabel={cargoFormValues?.dischargeLabel}
                locationCountryCode={cargoFormValues?.dischargeCountryCode}
                formNameFactory={name => ['cargo', `discharge${name}`]}
                onSelectLocation={location => onSelectLocation(location, 'discharge')}
              />
            </FormSectionItem>
          </ExpandSection>
        </FormSectionBody>
      </FormSection>
    </>
  );
};
