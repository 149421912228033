import {Card, Col, Row} from 'antd';
import {useState, FC, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {LoadingModal} from '../../../components/LoadingModal/LoadingModal';
import {MapCardMap} from '../MapCard/MapCardMap';
import {FinishedVisitsPerDate} from './BarStats';
import {FinishedVisitsTotalTimePerDate} from './BoxPlotStats';
import {Grid} from './Grid/Grid';
import {
  CongestionStatus,
  CurrentVessels,
  FinishedVisits,
  MedianPassageTime,
  MedianWaitingTime,
  VesselsPassaged,
} from './NumberStats';
import {
  FinishedVisitsByDirection,
  FinishedVisitsFromBulkersPerVesselSize,
  FinishedVisitsPerVesselType,
} from './PieStats';
import {TimePerVesselSubType, TimePerVesselType} from './ScatterStats';
import {TiedUp} from './TiedUp';
import {chokepointMeta, GREEN, RED, useChokepointPolygonQuery} from './utils/chokepointMeta';
import {ChokepointKey} from './utils/useChokepointCongestionQuery';
import {
  MergedVisits,
  useCongestionVisitsWithVesselInformationsQuery,
} from './utils/useCongestionVisitsWithVesselInformationsQuery';
import {VisitTimeGraph} from './VisitTimeGraph';
import {makePartialMapSwitches} from '../../../components/SeaboMap/utils/makePartialMapSwitches';
import {MapControlButton} from '../../../components/SeaboMap/MapContext/Types';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';

export const ChokepointCongestionScreen: FC = () => {
  const params = useParams<'chokepointKey'>();
  const chokepointKey = params.chokepointKey as ChokepointKey;
  const [selectedVisits, setSelectedVisits] = useState<MergedVisits[] | undefined>(undefined);
  const [appliedFilter, setAppliedFilter] = useState('');

  const polygonQuery = useChokepointPolygonQuery();
  const congestionQuery = useCongestionVisitsWithVesselInformationsQuery(chokepointKey);

  useEffect(() => {
    if (congestionQuery.isSuccess && congestionQuery.data) {
      setSelectedVisits(congestionQuery.data.currentVisits);
    }
  }, [congestionQuery.data, congestionQuery.isSuccess]);

  if (!congestionQuery.isSuccess) {
    return <LoadingModal isLoading={true} />;
  }
  const {title, centerPoint, zoom} = chokepointMeta[chokepointKey];
  const polygon = polygonQuery.isSuccess ? polygonQuery.data[chokepointKey] : undefined;
  const {report, queryTimestamp, currentVisits, finishedVisits} = congestionQuery.data ?? {};
  const visits = selectedVisits ?? finishedVisits;

  const applyFilter = (type: string, value: string) => {
    setAppliedFilter(`${type}: ${value}`);
  };
  const resetFilter = () => {
    setSelectedVisits(finishedVisits);
    setAppliedFilter('');
  };

  return (
    <div className={'congestion-reports'}>
      <ScreenHeader
        title={title + ' Congestion last 60 days'}
        breadcrumbs={[{title: 'Lab'}, {title: 'Congestion reports', href: '/lab/congestion'}, {title}]}
        features={
          appliedFilter && (
            <>
              <AppliedFilterLabel>Filtered by {appliedFilter}</AppliedFilterLabel>
              <ScreenHeader.Features.Button onClick={resetFilter} style={{marginLeft: 10}}>
                Reset filter
              </ScreenHeader.Features.Button>
            </>
          )
        }
      />
      <Card>
        <Row gutter={[20, 20]}>
          <Col span={24} xxl={12} style={{minHeight: 376}}>
            <MapCardMap
              staticMapBoxLayer={false}
              vessels={currentVisits}
              centerPoint={centerPoint}
              zoom={zoom}
              polygon={polygon}
              polygonColor={report.isCongested ? RED : GREEN}
              mapControlsVisible
              promoLayerEnabled
              mapControlButtonsToHide={[MapControlButton.CARGOES, MapControlButton.VESSELS]}
              initialMapSettings={{
                mapStyle: 'SAT',
                switches: {
                  ...makePartialMapSwitches({
                    ports: true,
                    highRiskAreas: true,
                    emissionControlAreas: true,
                  }),
                },
              }}
            />
          </Col>
          <Col span={24} xxl={12}>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <CongestionStatus chokepointKey={chokepointKey} report={report} />
              </Col>
              <Col span={12}>
                <CurrentVessels currentVisits={currentVisits} />
              </Col>
              <TiedUp currentVisits={currentVisits} />
              <Col span={12}>
                <FinishedVisits finishedVisits={finishedVisits} onSetVisits={setSelectedVisits} />
              </Col>
              <Col span={12}>
                <VesselsPassaged report={report} />
              </Col>
              <Col span={12}>
                <MedianWaitingTime visits={visits} />
              </Col>
              <Col span={12}>
                <MedianPassageTime visits={visits} />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <FinishedVisitsPerDate
              visits={finishedVisits}
              maxDate={queryTimestamp}
              onSetVisits={setSelectedVisits}
              onSetAppliedFilter={applyFilter}
            />
          </Col>
          <Col span={12}>
            <FinishedVisitsTotalTimePerDate
              visits={finishedVisits}
              onSetVisits={setSelectedVisits}
              onSetAppliedFilter={applyFilter}
            />
          </Col>
          <Col span={12}>
            <FinishedVisitsPerVesselType
              visits={finishedVisits}
              onSetVisits={setSelectedVisits}
              onSetAppliedFilter={applyFilter}
            />
          </Col>
          <Col span={12}>
            <TimePerVesselType
              visits={finishedVisits}
              onSetVisits={setSelectedVisits}
              onSetAppliedFilter={applyFilter}
            />
          </Col>
          <Col span={12}>
            <FinishedVisitsFromBulkersPerVesselSize
              visits={finishedVisits}
              onSetVisits={setSelectedVisits}
              onSetAppliedFilter={applyFilter}
            />
            <FinishedVisitsByDirection visits={finishedVisits} />
          </Col>
          <Col span={12}>
            <TimePerVesselSubType
              visits={finishedVisits}
              onSetVisits={setSelectedVisits}
              onSetAppliedFilter={applyFilter}
            />
          </Col>
          <Col span={24}>
            <VisitTimeGraph finishedVisits={finishedVisits} polygon={polygon} />
          </Col>
        </Row>
        <Grid vessels={selectedVisits ?? []} />
      </Card>
    </div>
  );
};

const AppliedFilterLabel = styled.div`
  line-height: 1;
  font-weight: bold;
`;
