import Icon, {IconType} from '../../atoms/Icon';
import {FC} from 'react';
import styled from 'styled-components';

const getType = (indexName: string): [IconType | undefined, string] => {
  switch (indexName) {
    case 'ports':
    case 'location_port':
      return ['port', 'Ports'];
    case 'vessels_market':
      return ['markets', 'Market vessels'];
    case 'cargoes_market':
      return ['markets', 'Market cargoes'];
    case 'vessels_portfolio':
      return ['portfolio', 'My Fleet'];
    case 'cargoes_portfolio':
      return ['portfolio', 'My Cargoes'];
    case 'projects':
      return ['work-tray', 'Projects'];
    case 'contacts':
      return ['person', 'Contacts'];
    case 'users':
      return ['person', 'Seabo users'];
    case 'negotiations':
      return ['negotiations', 'Negotiations'];
    case 'ais_vessels':
      return ['vessel', 'AIS vessels'];
    case 'exact-match':
      return ['location', 'Exact match'];
    case 'overviewPage':
      return ['search', 'See all results'];
    case 'empty-query':
      return [undefined, 'Type a query to search'];
    case 'loading':
      return [undefined, 'Loading results...'];
    default:
      return ['help', 'Help'];
  }
};

export const AutoCompleteHeader: FC<{indexName: string}> = ({indexName}) => {
  const [iconType, title] = getType(indexName ?? '');
  if (['overviewPage', 'empty-query', 'loading', 'help'].includes(indexName)) {
    return (
      <FlexBox>
        {iconType && <HeaderIcon type={iconType} />}
        <div>{title}</div>
      </FlexBox>
    );
  }

  return (
    <AutoCompleteHeaderStyled>
      {iconType && <HeaderIcon type={iconType} />}
      <div>{title}</div>
    </AutoCompleteHeaderStyled>
  );
};

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
`;

const AutoCompleteHeaderStyled = styled(FlexBox)`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 8px -16px 0;
  padding: 8px 16px;
  color: var(--color-black);
  background: var(--color-gray-5);
`;

const HeaderIcon = styled(Icon)`
  font-size: 1.25em;
  vertical-align: middle;
  color: inherit;
`;

export default AutoCompleteHeader;
