/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CompanyAddress,
  CompanyAddressFromJSON,
  CompanyAddressFromJSONTyped,
  CompanyAddressToJSON,
  CompanyContact,
  CompanyContactFromJSON,
  CompanyContactFromJSONTyped,
  CompanyContactToJSON,
} from './';

/**
 *
 * @export
 * @interface CompanyEdit
 */
export interface CompanyEdit {
  /**
   *
   * @type {string}
   * @memberof CompanyEdit
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof CompanyEdit
   */
  foundYear?: number | null;
  /**
   *
   * @type {string}
   * @memberof CompanyEdit
   */
  taxId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyEdit
   */
  employees?: string | null;
  /**
   *
   * @type {number}
   * @memberof CompanyEdit
   */
  vesselsManaged?: number | null;
  /**
   *
   * @type {string}
   * @memberof CompanyEdit
   */
  business?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyEdit
   */
  companyType?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyEdit
   */
  companyMailDomain?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyEdit
   */
  companyLogo?: string | null;
  /**
   *
   * @type {CompanyAddress}
   * @memberof CompanyEdit
   */
  address?: CompanyAddress;
  /**
   *
   * @type {CompanyContact}
   * @memberof CompanyEdit
   */
  contact?: CompanyContact;
  /**
   *
   * @type {string}
   * @memberof CompanyEdit
   */
  subscriptionPlan?: CompanyEditSubscriptionPlanEnum;
  /**
   *
   * @type {boolean}
   * @memberof CompanyEdit
   */
  isPayingCustomer?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CompanyEdit
   */
  isVerified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CompanyEdit
   */
  isOnboarded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CompanyEdit
   */
  showConnectMarketplaceBanner?: boolean;
  /**
   *
   * @type {Date}
   * @memberof CompanyEdit
   */
  promotionUntil?: Date | null;
}

/**
 * @export
 * @enum {string}
 */
export enum CompanyEditSubscriptionPlanEnum {
  Free = 'free',
  Standard = 'standard',
  Pro = 'pro',
}

export function CompanyEditFromJSON(json: any): CompanyEdit {
  return CompanyEditFromJSONTyped(json, false);
}

export function CompanyEditFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyEdit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    description: !exists(json, 'description') ? undefined : json['description'],
    foundYear: !exists(json, 'foundYear') ? undefined : json['foundYear'],
    taxId: !exists(json, 'taxId') ? undefined : json['taxId'],
    employees: !exists(json, 'employees') ? undefined : json['employees'],
    vesselsManaged: !exists(json, 'vesselsManaged') ? undefined : json['vesselsManaged'],
    business: !exists(json, 'business') ? undefined : json['business'],
    companyType: !exists(json, 'companyType') ? undefined : json['companyType'],
    companyMailDomain: !exists(json, 'companyMailDomain') ? undefined : json['companyMailDomain'],
    companyLogo: !exists(json, 'companyLogo') ? undefined : json['companyLogo'],
    address: !exists(json, 'address') ? undefined : CompanyAddressFromJSON(json['address']),
    contact: !exists(json, 'contact') ? undefined : CompanyContactFromJSON(json['contact']),
    subscriptionPlan: !exists(json, 'subscriptionPlan') ? undefined : json['subscriptionPlan'],
    isPayingCustomer: !exists(json, 'isPayingCustomer') ? undefined : json['isPayingCustomer'],
    isVerified: !exists(json, 'isVerified') ? undefined : json['isVerified'],
    isOnboarded: !exists(json, 'isOnboarded') ? undefined : json['isOnboarded'],
    showConnectMarketplaceBanner: !exists(json, 'showConnectMarketplaceBanner')
      ? undefined
      : json['showConnectMarketplaceBanner'],
    promotionUntil: !exists(json, 'promotionUntil')
      ? undefined
      : json['promotionUntil'] === null
      ? null
      : new Date(json['promotionUntil']),
  };
}

export function CompanyEditToJSON(value?: CompanyEdit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    foundYear: value.foundYear,
    taxId: value.taxId,
    employees: value.employees,
    vesselsManaged: value.vesselsManaged,
    business: value.business,
    companyType: value.companyType,
    companyMailDomain: value.companyMailDomain,
    companyLogo: value.companyLogo,
    address: CompanyAddressToJSON(value.address),
    contact: CompanyContactToJSON(value.contact),
    subscriptionPlan: value.subscriptionPlan,
    isPayingCustomer: value.isPayingCustomer,
    isVerified: value.isVerified,
    isOnboarded: value.isOnboarded,
    showConnectMarketplaceBanner: value.showConnectMarketplaceBanner,
    promotionUntil:
      value.promotionUntil === undefined
        ? undefined
        : value.promotionUntil === null
        ? null
        : value.promotionUntil.toISOString(),
  };
}
