import {ButtonProps} from 'antd';
import styled from 'styled-components';
import {WorkspaceIcon} from './WorkspaceIcon';
import {Workspace} from './WorkspaceSelectTypes';
import {SidebarButton, SidebarButtonLabelSize} from '../Layout/Sidebar/SidebarButton';

export const WorkspaceSelectButton = ({
  size,
  workspace,
  ...buttonProps
}: {
  size?: SidebarButtonLabelSize;
  workspace: Workspace;
} & Omit<ButtonProps, 'size'>) => {
  return (
    <SidebarButton
      icon={
        <WorkspaceIconWrapper>
          <WorkspaceIcon />
        </WorkspaceIconWrapper>
      }
      text={workspace.name}
      size={size}
      showArrow
      {...buttonProps}
    />
  );
};

const WorkspaceIconWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: var(--color-blue);
`;
