import {ViewState} from './ViewState';
import {OpenweathermapLayer} from './layers/mapbox/OpenweathermapLayerType';
import {GeoJsonLayerProps} from '@deck.gl/layers/typed';
import {RGBAColor, RGBColor} from '../../utils/deck.gl/deck.gl-types';
import {MapStyle} from './MapContext/Types';
import {getVariableFromWindow} from '../../utils/getVariableFromWindow';

export const initialViewState: ViewState = {
  altitude: 1.5,
  latitude: 52,
  longitude: 15,
  zoom: 3.3,
  minZoom: 1.4,
  maxZoom: 24,
  pitch: 0,
  bearing: 0,
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

export const geoJsonLayerPropsDefaults: Partial<GeoJsonLayerProps> = {
  extruded: false,
  filled: false,
  getLineWidth: 1,
  lineWidthUnits: 'pixels',
  stroked: true,
};

export const ecaAreaGeoJsonProps: Partial<GeoJsonLayerProps> = {
  ...geoJsonLayerPropsDefaults,
  filled: true,
  getFillColor: [0, 255, 199, 102],
  getLineColor: [0, 255, 199, 255],
  opacity: 0.1,
};

export const LAYERS = {
  AIS: 'AIS',
  ECA: 'ECA',
  PIRACY: 'PIRACY',
  PORT: 'PORT',
  PORTLESS: 'PORTLESS',
  TRADING_AREA: 'TRADING_AREA',
  PORTFOLIO_VESSEL: 'PORTFOLIO_VESSEL',
  VESSEL_ROUTE: 'VESSEL_ROUTE',
  CharterCalculationVoyageLayer: 'CharterCalculationVoyageLayer',
};

export const VESSEL_ICON_STYLES: {
  value: string;
  label: string;
}[] = [
  {value: 'detailed', label: 'Detailed'},
  {value: 'filled', label: 'Filled'},
  {value: 'empty', label: 'Empty'},
  {value: 'simple', label: 'Simple'},
];

export const SAVE_FORMAT_VERSION = 4;

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiaW50cmFkZXN5cyIsImEiOiJjajNvOW16cDMwMDBrMzNxdGRiZW9mZzdrIn0.fpaqgROMPIOhppefyVBUIg';

export const OPENWEATHERMAP_APP_ID = getVariableFromWindow('REACT_APP_OPENWEATHERMAP_APP_ID');

export const OPENWEATHERMAP_LAYERS: {
  label: string;
  value: OpenweathermapLayer;
}[] = [
  {label: 'Clouds', value: 'clouds_new'},
  {label: 'Precipitation', value: 'precipitation_new'},
  {label: 'Temperature', value: 'temp_new'},
  {label: 'Wind', value: 'wind_new'},
  {label: 'Pressure', value: 'pressure_new'},
];

export const DEFAULT_MAP_STYLE: MapStyle = 'SAT';

export const MAPBOX_STYLES: Record<
  MapStyle,
  {
    styleURL: string;
    styleAccount: string;
    styleId: string;
    label: string;
    fontColorOnLayer?: [number, number, number];
    // Before the mapbox layer is loaded, the layer is transparent, so this backgroundColor is set as the background color behind the layer
    // to prevent flickering because of a color change.
    backgroundColor: string;
  }
> = {
  DARK_MODE: {
    styleURL: 'mapbox://styles/intradesys/cjpnxatqi0ny12spof8bxkc1i',
    label: 'Dark mode',
    styleAccount: 'intradesys',
    styleId: 'cjpnxatqi0ny12spof8bxkc1i',
    backgroundColor: '#001e36',
  },
  FRANK: {
    styleURL: 'mapbox://styles/intradesys/cke2ihyt40y3319n2p1d8c6ff',
    styleAccount: 'intradesys',
    styleId: 'cke2ihyt40y3319n2p1d8c6ff',
    label: 'Frank',
    fontColorOnLayer: [0, 0, 0],
    backgroundColor: '#eef1f4',
  },

  SAT: {
    styleURL: 'mapbox://styles/intradesys/cjqm98ho34sk32rqchj82eeov',
    styleAccount: 'intradesys',
    styleId: 'cjqm98ho34sk32rqchj82eeov',
    label: 'Satellite',
    backgroundColor: '#001e36',
  },
};

export const COLORS: {
  [key: string]: RGBAColor | RGBColor;
} = {
  POI_POINT_LINE: [255, 142, 35],
  POI_POLYGON_FILL: [255, 142, 35, 127],
  POI_POLYGON_LINE: [255, 142, 35],
  PORT_POINT_FILL: [255, 255, 255],
  PORT_POINT_LINE: [255, 0, 0],
  PORT_POLYGON_FILL: [255, 255, 255, 127],
  PORT_POLYGON_LINE: [255, 255, 255],
  TA_LINE: [128, 128, 128],
  TA_LINE_SELECTED: [64, 162, 255, 255],
  TA_POLYGON_FILL: [255, 255, 255, 0],
  TA_POLYGON_FILL_SELECTED: [64, 162, 255, 31],
};

export const PORT_LAYERS: {
  [key: string]: string;
} = {
  PORT: 'PORT',
  SUB_PORT: 'SUB_PORT',
  ANCHORAGE: 'ANCHORAGE',
  TERMINAL: 'TERMINAL',
  DRY_DOCK: 'DRY_DOCK',
  PILOT_STATION: 'PILOT_STATION',
  OTHER: 'OTHER',
};

export const PORT_LAYER_NAMES: {
  [key: string]: string;
} = {
  [PORT_LAYERS.PORT]: 'Main port',
  [PORT_LAYERS.SUB_PORT]: 'Sub port',
  [PORT_LAYERS.ANCHORAGE]: 'Anchorage',
  [PORT_LAYERS.TERMINAL]: 'Terminal',
  [PORT_LAYERS.DRY_DOCK]: 'Dry dock',
  [PORT_LAYERS.PILOT_STATION]: 'Pilot station',
  [PORT_LAYERS.OTHER]: 'Other',
};

export const PORT_LAYER_CATEGORIES: {
  [key: string]: string;
} = {
  CONTAINER: 'CONTAINER',
  DRY_BULK: 'DRY_BULK',
  COAL: 'COAL',
  SCRAP: 'SCRAP',
  GRAIN: 'GRAIN',
  IRON_ORE: 'IRON_ORE',
  GENERAL_CARGO: 'GENERAL_CARGO',
  TANKER: 'TANKER',
  OIL_TANKER: 'OIL_TANKER',
  CHEMICAL_TANKER: 'CHEMICAL_TANKER',
};

export const PORT_CATEGORY_NAMES: {
  [key: string]: string;
} = {
  [PORT_LAYER_CATEGORIES.CONTAINER]: 'Container',
  [PORT_LAYER_CATEGORIES.DRY_BULK]: 'Dry bulk',
  [PORT_LAYER_CATEGORIES.COAL]: 'Coal',
  [PORT_LAYER_CATEGORIES.SCRAP]: 'Scrap',
  [PORT_LAYER_CATEGORIES.GRAIN]: 'Grain',
  [PORT_LAYER_CATEGORIES.IRON_ORE]: 'Iron ore',
  [PORT_LAYER_CATEGORIES.GENERAL_CARGO]: 'General cargo',
  [PORT_LAYER_CATEGORIES.TANKER]: 'Tanker',
  [PORT_LAYER_CATEGORIES.OIL_TANKER]: 'Oil tanker',
  [PORT_LAYER_CATEGORIES.CHEMICAL_TANKER]: 'Chemical tanker',
};
