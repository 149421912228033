import {FC} from 'react';
import {Form, Input, Checkbox, Alert} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {LoginRequest} from '../../../api/symfony/generated';
import {CtaButton, InputLabel, Instruction, Layout} from '../utils/shared-components';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Store} from 'antd/es/form/interface';

export type LoginFormValues = LoginRequest;

export const LoginForm: FC<{
  submitting: boolean;
  errorMessage?: string;
  onChange: () => void;
  onSubmit: (formValues: LoginFormValues) => void;
}> = ({submitting, errorMessage, onChange, onSubmit}) => {
  const onFinish = (values: Store) => {
    onSubmit(values as LoginFormValues);
  };

  return (
    <Layout>
      <Form
        name="login"
        data-testid="LoginForm"
        initialValues={{
          email: '',
          password: '',
          rememberMe: true,
        }}
        onValuesChange={onChange}
        onFinish={onFinish}>
        <Instruction>
          The Essentials <br />
          of Digital Chartering
        </Instruction>

        <InputLabel>Corporate email</InputLabel>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your Email.',
              type: 'email',
            },
          ]}>
          <Input prefix={<UserOutlined />} autoComplete="email" placeholder="Email" data-testid="email" />
        </Form.Item>

        <InputLabel>Password</InputLabel>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password.',
            },
          ]}>
          <Input
            prefix={<LockOutlined />}
            autoComplete="current-password"
            type="password"
            placeholder="Password"
            data-testid="password"
          />
        </Form.Item>

        {errorMessage && (
          <>
            <Alert message={errorMessage} type="error" showIcon data-testid="ErrorMessage" />
            <br />
          </>
        )}

        <Form.Item>
          <Form.Item name="rememberMe" valuePropName="checked" noStyle>
            <Checkbox>Stay logged in</Checkbox>
          </Form.Item>

          <ForgotLink to="/authentication/lost-password">Forgot password?</ForgotLink>
        </Form.Item>

        <Form.Item>
          <CtaButton
            type="primary"
            htmlType="submit"
            size="large"
            disabled={submitting}
            loading={submitting}
            data-testid="Submit">
            Log in
          </CtaButton>
        </Form.Item>
      </Form>
    </Layout>
  );
};

const ForgotLink = styled(Link)`
  float: right;
  font-weight: 400;
  color: var(--color-gray-2);
  &:hover {
    color: #69bbff;
  }
`;
