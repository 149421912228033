import {ComponentPropsWithoutRef} from 'react';

export const WorkspaceIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" fill="none" viewBox="0 0 22 21" {...props}>
      <path
        fill="currenColor"
        fillRule="evenodd"
        d="M12.414 3.034a1.034 1.034 0 11-2.069 0 1.034 1.034 0 012.069 0zm2 0a3.034 3.034 0 11-6.069 0 3.034 3.034 0 016.069 0zm-7.951-1.24a10.103 10.103 0 00-5.165 9.489l1.996-.131a8.103 8.103 0 014.142-7.61l-.973-1.748zm13.96 4.323a10.103 10.103 0 00-3.807-4.137l-1.037 1.71a8.104 8.104 0 013.861 7.755l1.99.203a10.103 10.103 0 00-1.007-5.531zm-9.397 14.6a10.103 10.103 0 01-5.343-1.752l1.128-1.651a8.103 8.103 0 008.657.303l1.01 1.727a10.103 10.103 0 01-5.452 1.374zm-7.992-4.51a1.035 1.035 0 100-2.07 1.035 1.035 0 000 2.07zm0 2a3.034 3.034 0 100-6.07 3.034 3.034 0 000 6.07zM20 15.172a1.034 1.034 0 11-2.069 0 1.034 1.034 0 012.069 0zm2 0a3.035 3.035 0 11-6.069 0 3.035 3.035 0 016.069 0z"
        clipRule="evenodd"></path>
    </svg>
  );
};
