import {FC} from 'react';
import {Menu as AntDMenu} from 'antd';
import classNames from 'classnames';
import styled, {css} from 'styled-components';
import {SidebarMode} from '../../../redux/Sidebar';
import {MenuItemWithPermission} from '../../MenuItemWithPermission/MenuItemWithPermission';

/**
 * This file contains the components that make up a sidebar menu  - Menu, MenuItem, SubMenu, Label, Icon.
 */

export const Menu = styled(AntDMenu)<{$sidebarMode: SidebarMode}>`
  width: 100%;
  background: none;
  border-right: none;
  transition: width var(--sidebar-transition-duration) padding-left var(--sidebar-transition-duration);
  font-size: var(--font-size-md);

  &.ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none !important;
  }

  ${({$sidebarMode}) =>
    $sidebarMode === 'narrow' &&
    css`
      padding-left: 10px;
      color: var(--color-gray-5);

      .ant-menu-submenu-arrow {
        display: none;
      }

      .ant-menu-item > .ant-menu-title-content {
        overflow: visible !important;
        color: var(--color-gray-5);
      }
    `}

  &::after {
    content: none !important;
  }

  .ant-menu-item {
    margin: 2px 0;
  }

  &.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    overflow: visible;
  }

  .ant-menu-item:hover,
  .ant-menu-submenu:hover {
    color: var(--color-white);
    background-color: var(--color-azure-new);
  }

  .ant-menu-submenu {
    padding-bottom: 0;
    margin-bottom: 4px;

    .ant-menu-submenu-title {
      margin-left: 0;
    }

    .ant-menu-item {
      &:last-child {
        &:hover {
          border-bottom-left-radius: 20px;
        }
      }
      &.ant-menu-item-active {
        color: var(--color-white) !important;
      }
    }
  }

  .ant-menu-item.ant-menu-item-active {
    color: var(--color-white) !important;
  }

  .ant-menu-submenu-selected {
    color: var(--color-white);
    font-weight: bold;
    background-color: var(--color-azure-new-dark);
  }

  .ant-menu-item-selected {
    background-color: var(--color-azure-new-dark) !important;
  }
  .ant-menu-item-selected div {
    font-weight: bold;
  }

  .ant-menu-submenu-selected .ant-menu-item {
    font-weight: normal;
  }

  .ant-menu-item::after {
    content: none !important;
  }

  .ant-menu-submenu-title {
    background-color: transparent !important;

    &:hover {
      color: var(--color-red);
    }

    &:active {
      color: var(--color-white);
    }
  }

  &.ant-menu-submenu-selected {
    background-color: var(--color-azure-new-dark) !important;

    .ant-menu-submenu-title {
      color: var(--color-white);
      font-weight: bold;

      &:active {
        background-color: var(--color-azure-new-dark);
      }
    }
  }

  ul.ant-menu {
    background-color: transparent !important;
  }

  .ant-menu-submenu-arrow::before {
    background: var(--color-gray-5-fixed) !important;
  }

  .ant-menu-submenu-arrow::after {
    background: var(--color-gray-5-fixed) !important;
  }

  &.ant-menu-inline .ant-menu-submenu-title {
    padding-inline-end: 24px;
  }
  &.ant-menu-inline .ant-menu-item {
    padding-inline-end: 8px;
  }
  &.ant-menu .ant-menu-submenu-arrow {
    inset-inline-end: 16px;
  }
`;

export const MenuItem = styled(MenuItemWithPermission)`
  background: none;
  color: var(--color-gray-5);
  font-size: var(--font-size-md);
`;

export const SidebarIcon: FC<{name: string; dataTestId?: string}> = ({name, dataTestId}) => (
  <IconStyled className={classNames('icon', 'icon--' + name)} data-testid={dataTestId} />
);

const IconStyled = styled.div`
  position: relative;
  top: 3px;
  display: inline-block;
  line-height: 24px;
  font-size: 1.25em !important;
  color: inherit !important;

  &::before {
    display: inline-block;
    height: 24px;
    line-height: 24px;
  }
`;
