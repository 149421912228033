import {useState, FC} from 'react';
import {produce} from 'immer';
import {Company} from '../../../../redux/ApiService/CompanyService/Company';
import {SettingsCardBody} from '../../../../components/SettingsCard/SettingsCardBody';
import {SettingsCardHeader} from '../../../../components/SettingsCard/SettingsCardHeader';
import {SettingsCard} from '../../../../components/SettingsCard/SettingsCard';
import {SettingsButtons} from '../../../../components/SettingsCard/SettingsButtons';
import {
  SettingsSection,
  SettingsSectionBody,
  SettingsSectionTitle,
} from '../../../../components/SettingsCard/SettingsSection';
import {Button} from 'antd';
import {useQueryClient} from '@tanstack/react-query';
import {invalidateCompaniesQuery} from '../../../../queries/company/useCompanyQuery';
import {showNotification} from '../../../../utils/notification';
import {useUpdateCompanySettingsMutation} from '../CompanySettingsGeneral/useUpdateCompanySettingsMutation';
import {ProfileInput} from '../../../../components/ProfileInput';
import {PhoneInputFormItem} from '../../../../atoms/PhoneInputFormItem';

interface Props {
  company: Company;
}

export const CompanySettingsContact: FC<Props> = ({company}) => {
  const [updatedCompany, setUpdatedCompany] = useState<Company>(company);
  const queryClient = useQueryClient();

  const updateCompanyMutation = useUpdateCompanySettingsMutation({
    onSuccess: async () => {
      await invalidateCompaniesQuery(queryClient);
      await showNotification('Company has been saved.');
    },
  });

  const onChange = (changeState: string, changedValue: string) => {
    if (changeState.includes('address')) {
      const field = changeState.replace('address.', '') as keyof Company['address'];
      setUpdatedCompany(
        produce(companyDraft => {
          companyDraft.address[field] = changedValue;
        })
      );
    } else if (changeState.includes('contact')) {
      const field = changeState.replace('contact.', '') as keyof Company['contact'];
      setUpdatedCompany(
        produce(companyDraft => {
          companyDraft.contact[field] = changedValue;
        })
      );
    }
  };

  const saveCompany = () => {
    updateCompanyMutation.mutate({
      company: updatedCompany,
      hasLogo: false,
      logoFile: undefined,
    });
  };

  return (
    <SettingsCard data-testid="CompanySettingsContact">
      <SettingsCardHeader title={'Contact'} />
      <SettingsCardBody>
        <SettingsSection>
          <SettingsSectionTitle>Contact</SettingsSectionTitle>
          <SettingsSectionBody>
            <ProfileInput
              label={'First name'}
              selectedName={'contact.firstName'}
              selectedValue={updatedCompany.contact.firstName}
              onChange={onChange}
            />
            <ProfileInput
              label={'Last name'}
              selectedName={'contact.lastName'}
              selectedValue={updatedCompany.contact.lastName}
              onChange={onChange}
            />
            <ProfileInput
              label={'General company email'}
              selectedName={'contact.email'}
              selectedValue={updatedCompany.contact.email}
              onChange={onChange}
            />
            <ProfileInput
              label={'Website'}
              selectedName={'contact.website'}
              selectedValue={updatedCompany.contact.website}
              onChange={onChange}
            />
            <ProfileInput label={'Fax'} selectedName={'contact.fax'}>
              <PhoneInputFormItem
                value={updatedCompany.contact.fax ?? undefined}
                onChange={newValue => {
                  onChange('contact.fax', newValue ?? '');
                }}
              />
            </ProfileInput>
            <ProfileInput label={'Phone'} selectedName={'contact.phone'}>
              <PhoneInputFormItem
                value={updatedCompany.contact.phone ?? undefined}
                onChange={newValue => {
                  onChange('contact.phone', newValue ?? '');
                }}
              />
            </ProfileInput>
          </SettingsSectionBody>
        </SettingsSection>

        <SettingsSection>
          <SettingsSectionTitle>Postal address</SettingsSectionTitle>
          <SettingsSectionBody>
            <ProfileInput
              label={'Address name'}
              selectedName={'address.name'}
              selectedValue={updatedCompany.address.name}
              onChange={onChange}
            />
            <div className={'row'}>
              <div className={'scol-8'}>
                <ProfileInput
                  label={'Street'}
                  selectedName={'address.street'}
                  selectedValue={updatedCompany.address.street}
                  onChange={onChange}
                />
              </div>
              <div className={'scol-4'}>
                <ProfileInput
                  label={'No'}
                  selectedName={'address.streetNumber'}
                  selectedValue={updatedCompany.address.streetNumber}
                  onChange={onChange}
                />
              </div>
            </div>
            <ProfileInput
              label={'Street 2'}
              selectedName={'address.street2'}
              selectedValue={updatedCompany.address.street2}
              onChange={onChange}
            />
            <div className={'row'}>
              <div className={'scol-4'}>
                <ProfileInput
                  label={'Postal code'}
                  selectedName={'address.zip'}
                  selectedValue={updatedCompany.address.zip}
                  onChange={onChange}
                />
              </div>
              <div className={'scol-8'}>
                <ProfileInput
                  label={'City'}
                  selectedName={'address.city'}
                  selectedValue={updatedCompany.address.city}
                  onChange={onChange}
                />
              </div>
            </div>
            <ProfileInput
              label={'Country'}
              selectedName={'address.country'}
              selectedValue={updatedCompany.address.country}
              onChange={onChange}
            />
          </SettingsSectionBody>
        </SettingsSection>
      </SettingsCardBody>

      <SettingsButtons>
        <Button disabled={updateCompanyMutation.isPending} type="primary" onClick={saveCompany}>
          SAVE
        </Button>
      </SettingsButtons>
    </SettingsCard>
  );
};
