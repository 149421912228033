import {connect} from 'react-redux';
import {actions as companyImageReducer} from '../../../redux/ApiService/companyImageService';
import BaseModal from '../../../atoms/BaseModal/index';
import {CompanyServiceActions} from '../../../redux/ApiService/CompanyService/CompanyService';
import ImageUpload from '../../ImageUpload';
import './style.scss';
import {RootState} from '../../../redux/store';

const UploadCompanyLogo = ({id, callback, updateCompany, uploadAvatar}: $TSFixMe) => (
  <BaseModal noHeader noPaddingSideBody>
    {({close}: $TSFixMe) => {
      const submit = (binaryFile: $TSFixMe) => {
        if (binaryFile) {
          const formData = new FormData();
          formData.append('fileName', binaryFile);
          uploadAvatar(formData).then((response: $TSFixMe) => {
            updateCompany(id, {
              companyLogo: response.data.items[0].uuid,
            }).then(() => {
              callback();
              close();
            });
          });
        } else {
          close();
        }
      };

      return (
        <ImageUpload
          headline={'Upload company logo'}
          subline={'Make your company stand out with your logo'}
          width={128}
          height={128}
          maxSize={5 * 1000 * 1000}
          callback={submit}
        />
      );
    }}
  </BaseModal>
);

const mapStateToProps = (state: RootState) => ({
  company: state.company.data,
});

const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  uploadAvatar: (data: $TSFixMe) => dispatch(companyImageReducer.companyUploadLogo({body: data})),
  updateCompany: (id: $TSFixMe, data: $TSFixMe) => dispatch(CompanyServiceActions.updateCompany(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadCompanyLogo);
