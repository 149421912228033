import {Dropdown, DropdownProps} from 'antd';
import {CSSProperties, FC, useState} from 'react';
import {createGlobalStyle} from 'styled-components';
import {assert} from '../../utils/assert';
import {WorkspaceList} from './WorkspaceList';
import {WorkspaceSelectButton} from './WorkspaceSelectButton';
import {User, Workspace} from './WorkspaceSelectTypes';
import {SidebarButtonLabelSize} from '../Layout/Sidebar/SidebarButton';

export type Placement = DropdownProps['placement'];

export type WorkspaceSelectSize = 'wide' | 'narrow';

/**
 * The 'dumb' component for WorkspaceSelect.
 * Its responsibility is rendering and also emitting events.
 * Handling the events is up to the 'smart' component (= WorkspaceSelect).
 */
export const WorkspaceSelectDumb: FC<{
  size?: SidebarButtonLabelSize;
  companyWorkspaces: Workspace[];
  user: User;
  value: number;
  style?: CSSProperties;
  dataTestId?: string;
  onSelect?: (id: number) => void;
}> = ({size = 'wide', companyWorkspaces, user, value, style, dataTestId, onSelect}) => {
  assert(value !== 0, 'Workspace 0 is not supported anymore');
  const [open, setOpen] = useState(false);
  const selectedWorkspace = companyWorkspaces.find(workspace => workspace.id === value);
  if (!selectedWorkspace) {
    throw new Error(
      `Selected workspace ${value} not available for user. Available: ${companyWorkspaces.map(
        workspace => workspace.id
      )}`
    );
  }

  const handleSelect = ({id}: {id: number}) => {
    setOpen(false);
    onSelect?.(id);
  };

  return (
    <>
      <Dropdown
        open={open}
        arrow={false}
        placement="top"
        trigger={['click']}
        overlayClassName={'workspacePopover fixed'}
        overlayStyle={{marginBottom: 8}}
        menu={{
          items: [
            {
              key: 'workspaces',
              label: <WorkspaceList workspaces={companyWorkspaces} user={user} value={value} onSelect={handleSelect} />,
              style: {width: '224px'},
            },
          ],
        }}
        onOpenChange={visible => {
          setOpen(visible);
        }}>
        <WorkspaceSelectButton workspace={selectedWorkspace} size={size} style={style} data-testid={dataTestId} />
      </Dropdown>
      <PopupGlobalStyles />
    </>
  );
};

const PopupGlobalStyles = createGlobalStyle`
  .workspaceSelectPopup {
    position: fixed;

    .ant-popover-title {
      margin-bottom: 0;
      padding: 5px 16px 4px;
    }

    .ant-popover-inner {
      padding: 0;
    }
  }
`;
