import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ApiError} from '../../api/utils/ApiError';
import {companyApi} from '../../api/symfony/symfonyApi';
import {Company, UpdateCompanyRequest} from '../../api/symfony/generated';

export type CompanyVerifyRequestBody = {isVerified?: boolean; isOnboarded?: boolean};

export const useUpdateCompanyMutation = (options?: UseMutationOptions<Company, ApiError, UpdateCompanyRequest>) =>
  useMutation({
    mutationFn: async ({id, companyEdit}) => {
      const response = await companyApi.updateCompany({id, companyEdit});
      return response;
    },
    ...options,
  });
