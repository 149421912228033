import {CSSProperties, FC, useState} from 'react';
import {Dropdown, Tooltip} from 'antd';
import styled from 'styled-components';
import {NotificationDropdownContent} from './NotificationDropdownContent';
import {Notification} from '../../../../api/symfony/generated/models/Notification';
import {BellIcon} from './BellIcon';
import {SidebarButton, SidebarButtonLabelSize} from '../SidebarButton';
import {useSelector} from '../../../../redux/react-redux';
import {getSidebarMode} from '../../../../redux/selectors';

/**
 * The notifications button displayed in the header, in "dumb" form.
 * It is supposed to display data and generate events. It is not supposed to
 * handle events or talk to the API.
 */
export const NotificationMenuDumb: FC<{
  loading?: boolean;
  ringingBellAnimation?: boolean;
  notifications: Notification[];
  style?: CSSProperties;
  onClick?: (notification: Notification) => void;
  onMarkAsRead?: (notification: Notification) => void;
  onMarkAllAsRead?: (notifications: Notification[]) => void;
  onSettings?: () => void;
  onSeeAll?: () => void;
  unreadCount: number;
}> = ({
  loading = false,
  ringingBellAnimation = false,
  notifications,
  style,
  onClick,
  onMarkAsRead,
  onMarkAllAsRead,
  onSettings,
  onSeeAll,
  unreadCount,
}) => {
  const sidebarMode = useSelector(getSidebarMode) as SidebarButtonLabelSize;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <ButtonWrapper
      style={style}
      data-tour-id="NotificationButton"
      data-testid="HeaderNotificationButton"
      data-loading={loading}>
      <Dropdown
        open={isDropdownOpen}
        arrow={false}
        placement="topLeft"
        trigger={['click']}
        overlayClassName={'fixed'}
        overlayStyle={{marginBottom: -8}}
        menu={{
          items: [
            {
              key: 'notifications',
              label: (
                <NotificationDropdownContent
                  notifications={notifications}
                  onClick={notification => {
                    setIsDropdownOpen(false);
                    onClick?.(notification);
                  }}
                  onMarkAsRead={notification => {
                    setIsDropdownOpen(false);
                    onMarkAsRead?.(notification);
                  }}
                  onMarkAllAsRead={notifications => {
                    setIsDropdownOpen(false);
                    onMarkAllAsRead?.(notifications);
                  }}
                  onSettings={() => {
                    setIsDropdownOpen(false);
                    onSettings?.();
                  }}
                  onSeeAll={() => {
                    setIsDropdownOpen(false);
                    onSeeAll?.();
                  }}
                />
              ),
            },
          ],
        }}
        onOpenChange={open => {
          setIsDropdownOpen(open);
        }}>
        <LinkStyled data-testid="NotificationButtonLink" onClick={e => e.preventDefault()}>
          <Tooltip
            title={`${unreadCount} unread notifications`}
            placement="top"
            open={!isDropdownOpen && isTooltipOpen}>
            <SidebarButton
              icon={<BellIcon ringingBellAnimation={ringingBellAnimation} />}
              text={<>Notifications {unreadCount > 0 && <NotificationDot />}</>}
              size={sidebarMode}
              onMouseEnter={() => setIsTooltipOpen(true)}
              onMouseLeave={() => setIsTooltipOpen(false)}
            />
          </Tooltip>
        </LinkStyled>
      </Dropdown>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  width: 100%;
`;

const LinkStyled = styled.a`
  display: block;
  width: 100%;
  padding: 4px 0;
  color: var(--color-white);

  &:hover {
    color: var(--color-white);
  }
`;

const NotificationDot = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--color-red);
`;
