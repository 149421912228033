import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {SeaboLogoWithText} from '../SeaboLogo/SeaboLogo';
import {getEnvironment, isLocalDevEnvironment} from '../../utils/environment';

export const HeaderLogo = () => {
  return (
    <LogoLink to="/dashboard">
      <SeaboLogoStyled />
      <EnvironmentBadge />
    </LogoLink>
  );
};

const EnvironmentBadge = () => {
  const environment = getEnvironment();
  const isDev = isLocalDevEnvironment();
  if (environment === 'Prod' && !isDev) {
    return null;
  }
  return <Badge>{environment}</Badge>;
};

const LogoLink = styled(Link)`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: var(--header-height);
`;

const SeaboLogoStyled = styled(SeaboLogoWithText)`
  display: block;
  max-height: 28px;
  color: var(--color-white);
  fill: var(--color-white);
`;

const Badge = styled.div`
  position: absolute;
  top: 4px;
  right: -8px;
  background-color: var(--color-azure-fixed);
  color: var(--color-white);
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  padding: 2px 4px;
  border-radius: 4px;
  text-transform: uppercase;
`;
