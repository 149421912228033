import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {helpCenterContent} from './helpCenterContent';
import {Button, Collapse} from 'antd';
import Icon from '../../atoms/Icon';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const HelpCenterScreen = () => {
  return (
    <div>
      <ScreenHeader title="Help center" breadcrumbs={[{href: '/help-center', title: 'Help center'}]} />
      <Collapse
        items={helpCenterContent.map(section => ({
          label: (
            <>
              {section.icon && <Icon type={section.icon} />} {section.title}
            </>
          ),
          children: (
            <>
              <Collapse
                items={section.content.map(piece => ({
                  label: piece.title,
                  children: (
                    <>
                      {piece.description}
                      {piece.imageUrl && <Image src={piece.imageUrl} />}
                      {piece.videoUrl && <Video src={piece.videoUrl} controls />}
                      {piece.pdfUrl && (
                        <Link to={piece.pdfUrl}>
                          <Button type="primary">Download PDF</Button>
                        </Link>
                      )}
                    </>
                  ),
                }))}
              />
            </>
          ),
        }))}
      />
    </div>
  );
};

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const Video = styled.video`
  max-width: 100%;
  height: auto;
`;
