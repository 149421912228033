import {Alert, Card} from 'antd';
import {FC, useState} from 'react';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {PlanFeatureComparison} from './components/PlanFeatureComparison/PlanFeatureComparison';
import styled from 'styled-components';
import {useSelector} from '../../redux/react-redux';
import {getCompanyState} from '../../redux/selectors';
import {ContractDuration} from './components/ContractDurationPicker';
import {SubTitle, Title} from './components/shared';
import {SeaboUvps} from './components/SeaboUvps';

export const CompanySubscriptionNotPermittedScreen: FC = () => {
  const company = useSelector(getCompanyState);
  const [contractDuration, setContractDuration] = useState<ContractDuration>('monthly');

  return (
    <Card data-testid="SubscriptionNotPermittedScreen">
      <ScreenHeader
        title="Subscription"
        breadcrumbs={[{title: 'Company', href: `/company/${company.data.id}`}, {title: 'Subscription'}]}
      />
      <StyledAlert type="error" message="We are sorry, only company managers can upgrade your account." />
      <Container>
        <Title>Available plans</Title>
        <SubTitle>Plans for every company size and need.</SubTitle>
        <PlanFeatureComparison
          showActionButtons={false}
          contractDuration={contractDuration}
          onChangeContractDuration={setContractDuration}
        />
        <SeaboUvps />
      </Container>
    </Card>
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: 20px;
`;

const Container = styled.div`
  padding: 10px 20px;
`;
